import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import apiUrls from "../../../api";
import { IAppLoaderAction } from "../../../common/state/loaderHandleMiddleware";
import { RootState } from "../../../common/state/store";

export interface IProfilings {
  fullListOfProfilings: any[];
  smListOfProfilings: any[];
  specificProfiling: any;
}

export const getSmListOfProfilings = createAsyncThunk(
  "profilings/getSmListOfProfilings",
  async (componentId: IAppLoaderAction) => {
    let response;

    await axios
      .get(`${apiUrls.profilings.get}?_sort=id:DESC&_limit=100`, {})
      .then((res) => {
        response = res.data;
      })
      .catch((e) => {
        console.error(e);
        return e;
      });

    return response;
  },
);

export const getFullListOfProfilings = createAsyncThunk(
  "profilings/getFullListOfProfilings",
  async (componentId: IAppLoaderAction) => {
    let response;
    await axios
      .get(apiUrls.profilings.get)
      .then((res) => {
        response = res.data;
      })
      .catch((e) => {
        console.error(e);
        return e;
      });

    return response;
  },
);

export const getSpecificProfiling = createAsyncThunk(
  "profilings/getSpecificProfiling",
  async ({ idOfProfiling }: { idOfProfiling: string } & IAppLoaderAction) => {
    let response;

    await axios
      .get(`${apiUrls.profilings.get}/${idOfProfiling}`)
      .then((res) => {
        response = res.data;
      })
      .catch((e) => {
        console.error(e);
      });

    return response;
  },
);

export const deactivateForUser = createAsyncThunk(
  "profilings/deactivateForUser",
  async ({ idOfUser }: { idOfUser: number } & IAppLoaderAction) => {
    let response;

    await axios
      .post(`${apiUrls.profilings.deactivateForUser}/${idOfUser}`)
      .then((res) => {
        response = res.data;
      })
      .catch((e) => {
        console.error(e);
      });

    return response;
  },
);

const initialState: IProfilings = {
  fullListOfProfilings: [],
  smListOfProfilings: [],
  specificProfiling: {},
};

export const profilingsSlice = createSlice({
  name: "profilings",
  initialState,
  reducers: {
    clearAllProfilings: (state: IProfilings) => {
      state.fullListOfProfilings = [];
    },
    updateFullListOfProfilings: (state: IProfilings, { payload }) => {
      if (state.fullListOfProfilings?.findIndex((x) => x.id == payload.id) !== undefined) {
        state.fullListOfProfilings[
          state.fullListOfProfilings?.findIndex((x) => x.id == payload.id) as any
        ] = payload;
      }
    },
    updateSmListOfProfilings: (state: IProfilings, { payload }) => {
      if (state.smListOfProfilings?.findIndex((x) => x.id == payload.id) !== undefined) {
        state.smListOfProfilings[state.smListOfProfilings?.findIndex((x) => x.id == payload.id) as any] =
          payload;
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getSmListOfProfilings.fulfilled, (state: IProfilings, { payload }) => {
      state.smListOfProfilings = payload ?? [];
    });
    builder.addCase(getFullListOfProfilings.fulfilled, (state: IProfilings, { payload }) => {
      state.fullListOfProfilings = payload ?? [];
    });
    builder.addCase(getSpecificProfiling.fulfilled, (state: IProfilings, { payload }: any) => {
      state.specificProfiling = payload;
    });
    builder.addCase(deactivateForUser.fulfilled, (state: IProfilings, { payload }: any) => {
      //change all profilings that match the id with the new one from payload
      state.fullListOfProfilings = state.fullListOfProfilings.map((profiling) => {
        const newProfiling = payload.find((p: any) => p.id === profiling.id);
        if (newProfiling) {
          return newProfiling;
        }
        return profiling;
      });
    
      state.smListOfProfilings = state.smListOfProfilings.map((profiling) => {
        const newProfiling = payload.find((p: any) => p.id === profiling.id);
        if (newProfiling) {
          return newProfiling;
        }
        return profiling;
      });
    });
  },
});

export const profilingsReducer = profilingsSlice.reducer;

export const getCurrentFullListOfProfilings = (state: RootState) => state.profilings.fullListOfProfilings;
export const getCurrentSmListOfProfilings = (state: RootState) => state.profilings.smListOfProfilings;
export const getCurrentSpecificProfiling = (state: RootState) => state.profilings.specificProfiling;

export const { clearAllProfilings } = profilingsSlice.actions;
