import { useSelector } from "react-redux";
import { Navigate, useLocation } from "react-router-dom";
import { RootState } from "../state/store";
import { jwtSelector } from "../state/selectors/authSelector";
import { useEffect, useState } from "react";
import { isTokenExpired } from "../util/auth";

export const PrivatePage = ({ children }: { children: React.ReactNode }) => {
  const [isExpired, setIsTokenExpired] = useState(false);
  const { jwt } = useSelector((state: RootState) => {
    return {
      jwt: jwtSelector(state),
    };
  });

  const location = useLocation();

  const [temp, setTemp] = useState(true);

  useEffect(() => {
    if (jwt && isTokenExpired(jwt)) {
      setIsTokenExpired(true);
    }

    setTemp(false);
  }, [jwt]);

  useEffect(() => {
    if (temp === true) {
      setTemp(false);
    }
  }, [temp]);

  return jwt === "" || isExpired === true ? (
    <Navigate to="/login" />
  ) : location.pathname === "/" ? (
    <>{children}</>
  ) : (
    <>
      {children}
      {temp ? <Navigate to={`${location.pathname}`} /> : <></>}
    </>
  );
  // return jwt === "" || isExpired === true ? <Navigate to="/login" /> : <>{children}</>;
};
