import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ButtonIcon, ButtonText } from "ui";

import { IsLoading } from "../../common/localComponents/AppState/IsLoading";

import { getCurrentUser } from "../../features/auth/state/authSlice";
import { AppDispatch, RootState } from "../../common/state/store";
import SmZoomMeetingsDataTable, {
  DASHBOARD_MEETINGS_COMPONENT_ID,
} from "./sm-zoom-meetings-data-table";
import { getSmListOfZoomMeetings } from "../../features/zoomMeetings/state/zoomMeetingsSlice";

const Dashboard = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const dispatch: AppDispatch = useDispatch();

  const { user } = useSelector((state: RootState) => {
    return {
      user: getCurrentUser(state.auth),
    };
  });

  return (
    <>
      <header className="flex w-full bg-white shadow  sticky top-0 z-10">
        <div className="max-w-screen-2xl py-6 px-8 overflow-y-auto">
          <h1 className="text-3xl font-bold text-black">
            {`${t("pages.dashboard.welcome")}, ${user?.firstname}!`}
          </h1>
        </div>
        <div className="flex justify-end align-middle ml-auto my-auto mr-8">
          <ButtonIcon
            icon={"ArrowPathIcon"}
            classNameBtn={"w-14 h-14 bg-white rounded-full shadow-md border"}
            classNameIcon={"w-10 h-10"}
            onClick={() => {
              dispatch(getSmListOfZoomMeetings({ componentId: DASHBOARD_MEETINGS_COMPONENT_ID }));
            }}
          />
        </div>
      </header>

      <div className="w-full max-w-9xl mx-auto pt-6 px-6">
        <div className="flex space-x-6 items-end">
          <h2 className="text-2xl text-black font-medium">
            {t("pages.zoomMeetings.title") as string}
          </h2>
          <ButtonText text={t("general.viewAll")} onClick={() => navigate("meetings")} />
        </div>
        <IsLoading componentId={DASHBOARD_MEETINGS_COMPONENT_ID} showSuccess={false}>
          <SmZoomMeetingsDataTable />
        </IsLoading>
      </div>

      {/* <div className="w-full max-w-9xl mx-auto pt-6 px-6">
        <div className="flex space-x-6 items-end">
          <h2 className="text-2xl text-black font-medium">{t("pages.pools.title")}</h2>
          <ButtonText text={t("general.viewAll")} onClick={() => navigate("meetings")} />
        </div>
        <StatsOverview />
      </div> */}
    </>
  );
};

export default Dashboard;
