import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Input, InputIcon } from "ui";
import { useForm } from "react-hook-form";
import { AppDispatch, RootState } from "../../../common/state/store";
import { useDispatch, useSelector } from "react-redux";
import { IAuthFormData, signIn } from "../state/authSlice";
import { useNavigate } from "react-router-dom";
import { jwtSelector } from "../../../common/state/selectors/authSelector";
import React from "react";
import { errorSelector } from "../../../common/state/slice/appStateSlice";
import { IsLoading } from "../../../common/localComponents/AppState/IsLoading";
import { Error } from "../../../common/localComponents/AppState/Error";
import logo from "../../../assets/logo.png";

export const LOGIN_COMPONENT_ID = "loginComponent";

interface ILoginForm {
  labels: {
    title: string;
    subtitle: string;
    email: string;
    password: string;
    lostpwd: string;
    keepSession: string;
    login: string;
  };
  error: string;
  onSubmit: (data: any) => void;
}

export const LoginFormContainer = () => {
  const { t } = useTranslation();
  const dispatch: AppDispatch = useDispatch();
  const navigate = useNavigate();

  const { error, jwt } = useSelector((state: RootState) => {
    return {
      error: errorSelector(state, LOGIN_COMPONENT_ID),
      jwt: jwtSelector(state),
    };
  });

  const labels = {
    title: t("pages.login.title"),
    subtitle: t("pages.login.subtitle"),
    email: t("pages.login.email"),
    password: t("pages.login.password"),
    lostpwd: t("pages.login.lostpwd"),
    keepSession: t("pages.login.keepSession"),
    login: t("pages.login.login"),
  };

  const onSubmit = (data: IAuthFormData) => {
    dispatch(signIn({ componentId: LOGIN_COMPONENT_ID, ...data }));
  };

  React.useEffect(() => {
    if (jwt && jwt !== "") {
      navigate("/");
    }
  }, [jwt]);

  return <LoginForm labels={labels} onSubmit={onSubmit} error={error} />;
};

const LoginForm = ({ labels, onSubmit }: ILoginForm) => {
  const [passwordVisible, setPasswordVisible] = useState(false);
  const { register, handleSubmit } = useForm<IAuthFormData>();

  return (
    <div
      className="flex h-screen bg-contain"
      style={{
        backgroundImage: `url('https://ucf47ed248c015dfb7bdf6cede00.previews.dropboxusercontent.com/p/thumb/AB39-8QWbK9MX0zCeT6Uzip_3qDUt7uy3afL01zR35DZBrws8lkmHUfibb4lIGD-OwXelCM2vSK4_kpASHByXkCan8RxQPwFAcNZ_42LFLhpmakxzeBtSHhbNOCd7IK1rPl4XkBIq4kjj3J9LyuRvTVyQrw1PZE7dbK7GDLToIXFh9YrVJIxEcUowEId9SsmzOfRhX3GeA5FLtAc-PhYsaozwYx5Okh9sQRIOKRzma2qQzFaBII4EBN3vTkLonrZAh-GXuD-4X41eHUSPnJII64VNqq8X-HOnCSkpBl0q0JX_muR-kYGjiHqPlFmLwmOt0iPoeAvy2XKQbltnp5bEf_6Rs-7MzwtHofQYtPh8DEmU4uytQduXCWAY-uepibAduQ/p.jpeg')`,
      }}
    >
      <div className="flex flex-1 max-w-xs md:max-w-lg mx-auto items-center justify-center ">
        <form className="w-full sm:w-3/4" onSubmit={handleSubmit(onSubmit)}>
          <div className="bg-white rounded-xl shadow-md border border-gray-200 py-3 px-4">
            <div className="my-2">
              <div className="w-64 m-auto mb-3">
                <img src={logo} alt="myctofriend-logo" />
              </div>
              <h2 className="text-md text-black mb-5 text-center font-normal">{labels.subtitle}</h2>
            </div>
            <Error componentId={LOGIN_COMPONENT_ID} />
            <IsLoading componentId={LOGIN_COMPONENT_ID} showSuccess={false} />

            <Input
              registerFct={() => register("email", { required: true, pattern: /^\S+@\S+$/i })}
              type="email"
              label={labels.email}
              placeholder="example@myctofriend.co"
              className="mb-3"
            />
            <InputIcon
              registerFct={() => register("password", { required: true })}
              type={passwordVisible ? "text" : "password"}
              position="right"
              label={labels.password}
              placeholder={labels.password}
              handleClick={() => setPasswordVisible(!passwordVisible)}
              icon={passwordVisible === true ? "EyeIcon" : "EyeSlashIcon"}
            />
            <div className="grid pt-3 justify-items-end">
              <button className="btn-secondary-fill text-black" type="submit">
                {labels.login}
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};
