import { useAppDispatch } from "../../state/hooks";
import { closeStandardModal } from "../../state/slice/modal/modalSlice";
import ModalStandard from "./ModalStandard";
import { IsLoading } from "../AppState/IsLoading";
import { t } from "i18next";

type ModalStandardProps = {
  title: string;
  text: string;
  onClick: any;
  id: string;
  componentId?: string;
};

const ModalConfirmation = ({ id, title, text, componentId, onClick }: ModalStandardProps) => {
  const dispatch = useAppDispatch();

  return (
    <ModalStandard id={id} fullScreen={false}>
      {componentId && (
        <div className="z-20">
          <IsLoading componentId={componentId} showSuccess={false} />
        </div>
      )}
      <div>
        <p className="font-medium text-lg my-4">{title}</p>
        <p className="text-gray-600 mb-4">{text}</p>
      </div>
      <div className="flex justify-end">
        <IsLoading componentId={componentId ? componentId : ""} showSuccess={false}>
          <button
            type="button"
            className="btn-primary-outline mr-4"
            onClick={() => {
              dispatch(closeStandardModal(id));
            }}
          >
            {String(t("general.no"))}
          </button>
          <button className="btn-primary-fill" type="button" onClick={onClick}>
            {String(t("general.yes"))}
          </button>
        </IsLoading>
      </div>
    </ModalStandard>
  );
};

export default ModalConfirmation;
