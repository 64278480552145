import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../common/state/store";
import { DataGrid, GridRowsProp, GridColDef } from "@mui/x-data-grid";
import { IsLoading } from "../../common/localComponents/AppState/IsLoading";
import { Link } from "react-router-dom";
import {
  getCurrentFullListOfProjects,
  getFullListOfProjects,
} from "../../features/projects/state/projectsSlice";
import { projectsColumns } from "./projects-columns";
import { ButtonIcon, DataGridComponent } from "ui";
import { deactivateForUser } from "../../features/profilings/state/profilingsSlice";
import { closeStandardModal, openStandardModal } from "../../common/state/slice/modal/modalSlice";
import { ModalConfirmation } from "../../common/localComponents/Modal";

export const PROJECTS_COMPONENT_ID = "profileComponent";
export const DEACTIVATE_PROFILINGS_COMPONENT_ID = "deactivateProfilingsComponent";

const Projects = () => {
  const { t } = useTranslation();

  const dispatch: AppDispatch = useDispatch();

  const [pageSize, setPageSize] = useState(20);
  const [idOfUserToDeactivate, setIdOfUserToDeactivate] = useState(-1);

  const { fullListOfProjects } = useSelector((state: RootState) => {
    return {
      fullListOfProjects: getCurrentFullListOfProjects(state),
    };
  });

  useEffect(() => {
    if (!fullListOfProjects || fullListOfProjects.length === 0) {
      dispatch(getFullListOfProjects({ componentId: PROJECTS_COMPONENT_ID }));
    }
  }, []);

  const actionColumn = [
    {
      field: "action",
      headerName: "Action",
      flex: 1,
      sortable: false,
      filterable: false,
      renderCell: (params: any) => {
        return (
          <>
            <Link to={`/projects/${params.id}`}>
              <button className="btn-primary-fill bg-blue mr-2">{String(t("general.open"))}</button>
            </Link>
            <button
              className="btn-secondary-fill bg-orange mr-2"
              onClick={() => {
                console.log("params", params);
                setIdOfUserToDeactivate(params.row.owner.id);
                dispatch(openStandardModal("deactivateProfilingsInProjects"));
              }}
            >
              Deactivate Profilings
            </button>
          </>
        );
      },
    },
  ];

  return (
    <>
      <ModalConfirmation
        id="deactivateProfilingsInProjects"
        onClick={() => {
          dispatch(deactivateForUser({ idOfUser: idOfUserToDeactivate, componentId: DEACTIVATE_PROFILINGS_COMPONENT_ID })).then(() => {
            dispatch(closeStandardModal("deactivateProfilingsInProjects"));
          });
        }}
        title="Are you sure you want to deactivate all profilings of the owner of this project?"
        text="This action will not be possible to undo."
        componentId={DEACTIVATE_PROFILINGS_COMPONENT_ID}
      />
      <header className="flex w-full bg-white shadow  sticky top-0 z-10">
        <div className="max-w-screen-2xl py-6 px-8 overflow-y-auto">
          <h1 className="text-3xl font-bold text-black">{t("pages.projects.title")}</h1>
        </div>
        <div className="flex justify-end align-middle ml-auto my-auto mr-8">
          <ButtonIcon
            icon={"ArrowPathIcon"}
            classNameBtn={"w-14 h-14 bg-white rounded-full shadow-md border"}
            classNameIcon={"w-10 h-10"}
            onClick={() => {
              dispatch(getFullListOfProjects({ componentId: PROJECTS_COMPONENT_ID }));
            }}
          />
        </div>
      </header>
      <div className="w-full h-full pb-6 max-w-9xl mx-auto pt-6 px-6">
        <IsLoading componentId={PROJECTS_COMPONENT_ID} showSuccess={false}>
          <DataGridComponent
            rows={fullListOfProjects as GridRowsProp}
            columns={projectsColumns.concat(actionColumn as any) as GridColDef[]}
          />
        </IsLoading>
      </div>
    </>
  );
};

export default Projects;
