import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { SidebarItemSmall, SidebarItem } from "ui";
import { IconName } from "ui/components/Icon/Icon";
import { logoutAuth } from "../../../features/auth/state/authSlice";
import logo from "../../../assets/logo.png";
import { clearAllZoomMeetings } from "../../../features/zoomMeetings/state/zoomMeetingsSlice";

type SideBarLinkProps = {
  title: string;
  iconName: IconName;
  routePath: string;
};

const Sidebar = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const sidebarLinks: SideBarLinkProps[] = [
    { title: t("sidebar.dashboard"), iconName: "HomeIcon", routePath: "./" },
    { title: t("sidebar.groups"), iconName: "FolderIcon", routePath: "/groups" },
    { title: t("sidebar.zoomMeetings"), iconName: "ChartBarIcon", routePath: "/meetings" },
    { title: t("sidebar.tracks"), iconName: "UserIcon", routePath: "/tracks" },
    { title: t("sidebar.pools"), iconName: "UserIcon", routePath: "/pools" },
    { title: t("sidebar.users"), iconName: "UserIcon", routePath: "/users" },
    { title: t("sidebar.projects"), iconName: "UserIcon", routePath: "/projects" },
    { title: t("sidebar.questions"), iconName: "FolderIcon", routePath: "/questions" },
    { title: t("sidebar.profilings"), iconName: "FolderIcon", routePath: "/profilings" },
  ];

  return (
    <div className="flex flex-col justify-between w-[10.5rem] pt-3 pb-6 shadow bg-white z-50 flex-shrink-0">
      <div>
        <div className="mb-10 px-1 h-20 mt-6">
          <div className="flex justify-center ">
            <img className="object-scale-down w-36" src={logo} alt="myctofriend-logo" />
          </div>
        </div>
        <div>
          {sidebarLinks.map((link, i) => {
            return (
              <SidebarItem
                routeTo={link.routePath}
                key={i}
                title={link.title}
                icon={link.iconName}
              />
            );
          })}
        </div>
      </div>
      <div className=" flex flex-col justify-between divide-y">
        <div className="py-4">
          <SidebarItem
            icon="ArrowLeftOnRectangleIcon"
            title={t("sidebar.logout")}
            onClick={() => {
              dispatch(logoutAuth());
              dispatch(clearAllZoomMeetings());
            }}
            routeTo={"/login"}
          />
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
