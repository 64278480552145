import React, { useState } from "react";
import { IsLoading } from "../../../common/localComponents/AppState/IsLoading";
import { Input, DropdownWithNew, ButtonIcon } from "ui";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { AppDispatch } from "../../../common/state/store";
import { useDispatch, useSelector } from "react-redux";
import { getCurrentOptionsOfTagsForAnswer } from "../../../features/questions/state/questionsSlice";
import { RootState } from "../../../common/state/store";
import { closeStandardModal } from "../../../common/state/slice/modal/modalSlice";
import {
  createEverythingAtOnce,
  addNewOption,
} from "../../../features/questions/state/questionsSlice";
import { CREATE_QUESTION_COMPONENT_ID } from "../questions";

// Type for AnswerSet
type AnswerSetType = { id: number };

const NewQuestionForm = () => {
  const { t } = useTranslation();
  const dispatch: AppDispatch = useDispatch();
  const [answerSets, setAnswerSets] = useState<AnswerSetType[]>([{ id: 0 }]);

  const { allOptionsOfTagForAnswer } = useSelector((state: RootState) => {
    return {
      allOptionsOfTagForAnswer: getCurrentOptionsOfTagsForAnswer(state),
    };
  });

  const {
    control,
    register,
    handleSubmit,
    unregister,
    setValue,
    formState: { errors },
  } = useForm();

  const onSubmit = (data: any) => {
    console.log("submitted data", data);
    dispatch(
      createEverythingAtOnce({ componentId: CREATE_QUESTION_COMPONENT_ID, data: data }),
    ).then(() => {
      dispatch(closeStandardModal("addNewQuestion"));
    });
  };

  // Update both the local state and the Redux state when a new option is created
  const handleNewOptionCreated = (newOption: any) => {
    dispatch(addNewOption(newOption));
  };

  return (
    <div className="overflow-visible flex flex-col flex-grow">
      <IsLoading componentId={CREATE_QUESTION_COMPONENT_ID} showSuccess={true} />
      <header className="flex w-full bg-white shadow sticky top-0 z-10">
        <div className="max-w-screen-2xl py-6 px-8 overflow-y-auto">
          <h1 className="text-3xl font-bold text-black">
            {t("pages.questions.addNewQuestion.title")}
          </h1>
        </div>
      </header>
      <div className="h-full w-full pb-6 mx-auto pt-6 px-6 overflow-visible flex items-center justify-center">
        <form
          className="flex justify-center items-center align-middle h-full w-full"
          onSubmit={handleSubmit(onSubmit)}
        >
          <div className="bg-white w-full rounded-xl shadow-md border border-gray-200 py-6 px-4 ">
            <Input
              registerFct={() => register("name", { required: true })}
              type="text"
              label={t("pages.questions.addNewQuestion.question")}
              className={`${errors[`name`] ? "border-red-500" : ""}`}
              placeholder="Your question"
            />
            <div className="mt-1">
              <DropdownWithNew
                key={`validatedtagtriggerlist`} // Added the key here
                className=""
                label="Validated Tag Trigger List:"
                name={`validatedtagtriggerlist`}
                control={control}
                // errors={errors}
                options={allOptionsOfTagForAnswer as any[]}
                style={{
                  border: errors.validatedtagtriggerlist ? "1.5px solid rgb(239 68 68)" : "",
                }}
                onNewOptionCreated={handleNewOptionCreated}
              />
            </div>
            <div className="mt-1">
              <DropdownWithNew
                key={`unvalidatedtagtriggerlist`} // Added the key here
                className=""
                label="Unvalidated Tag Trigger List:"
                name={`unvalidatedtagtriggerlist`}
                control={control}
                // errors={errors}
                options={allOptionsOfTagForAnswer as any[]}
                style={{
                  border: errors.unvalidatedtagtriggerlist ? "1.5px solid rgb(239 68 68)" : "",
                }}
                onNewOptionCreated={handleNewOptionCreated}
              />
            </div>
            {answerSets.map((answerSet) => (
              <div key={answerSet.id} className="border-[1.5px] rounded-xl border-gray-300 mt-4">
                <div className={`pt-4 mx-6 ` + (answerSets.length > 1 ? "pb-4" : "pb-7 xl:pb-4")}>
                  <div className="grid grid-cols-1 xl:grid-cols-12 items-center justify-center xl:justify-start">
                    <div className="grid w-full col-span-11 justify-center items-center grid-cols-1 xl:grid-cols-3 xl:flex-row space-x-0 xl:space-x-5 space-y-2 xl:space-y-0">
                      <div className="">
                        <Input
                          registerFct={() => register(`answer_${answerSet.id}`, { required: true })}
                          type="text"
                          className={`${errors[`answer_${answerSet.id}`] ? "border-red-500" : ""}`}
                          label={t("pages.questions.addNewQuestion.answerLabel")}
                          placeholder={`Potential answer for question`}
                        />
                      </div>
                      <div className="">
                        <DropdownWithNew
                          key={`tagstovalidate_${answerSet.id}`} // Added the key here
                          className=""
                          label="Tags to validate:"
                          name={`tagstovalidate_${answerSet.id}`}
                          control={control}
                          // errors={errors}
                          options={allOptionsOfTagForAnswer as any[]}
                          style={{
                            border: errors.tags ? "1.5px solid rgb(239 68 68)" : "",
                          }}
                          onNewOptionCreated={handleNewOptionCreated}
                        />
                      </div>
                      <div className="">
                        <DropdownWithNew
                          key={`tagstounvalidate_${answerSet.id}`} // Added the key here
                          className=""
                          label="Tags to unvalidate:"
                          name={`tagstounvalidate_${answerSet.id}`}
                          control={control}
                          // errors={errors}
                          options={allOptionsOfTagForAnswer as any[]}
                          style={{
                            border: errors.tags ? "1.5px solid rgb(239 68 68)" : "",
                          }}
                          onNewOptionCreated={handleNewOptionCreated}
                        />
                      </div>
                    </div>
                    {answerSets.length > 1 && (
                      <div className="col-span-1 justify-self-center mt-4 xl:ml-4 ml-0 xl:mb-2 mb-0">
                        <ButtonIcon
                          icon={"MinusSmallIcon"}
                          classNameBtn={"w-10 h-10 bg-white rounded-full shadow-md border"}
                          classNameIcon={"w-6 h-6"}
                          onClick={() => {
                            unregister(`answer_${answerSet.id}`);
                            unregister(`tagstovalidate_${answerSet.id}`);
                            unregister(`tagstounvalidate_${answerSet.id}`);
                            setAnswerSets((prevAnswerSets) =>
                              prevAnswerSets.filter((set) => set.id !== answerSet.id),
                            );
                          }}
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            ))}
            <div className="flex border-[1.5px] rounded-xl border-gray-300 lg:flex-row flex-col place-content-center mt-4">
              <div className="py-4 mx-auto">
                <div className="flex items-center justify-center">
                  <ButtonIcon
                    icon={"PlusSmallIcon"}
                    classNameBtn={"w-10 h-10 bg-white rounded-full shadow-md border"}
                    classNameIcon={"w-6 h-6"}
                    onClick={() => {
                      const maxId =
                        answerSets.length > 0 ? Math.max(...answerSets.map((set) => set.id)) : -1;
                      setAnswerSets((prevAnswerSets) => [...prevAnswerSets, { id: maxId + 1 }]);
                      setValue(`tagstovalidate_${maxId + 1}`, []);
                      setValue(`tagstounvalidate_${maxId + 1}`, []);
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="grid pt-3 justify-items-end">
              <button className="btn-secondary-fill text-black" type="submit">
                Add
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default NewQuestionForm;
