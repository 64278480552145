import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import apiUrls from "../../../api";
import { IAppLoaderAction } from "../../../common/state/loaderHandleMiddleware";
import { RootState } from "../../../common/state/store";

export interface IQuestions {
  fullListOfQuestions: any[];
  smListOfQuestions: any[];
  specificQuestion: any;
  allOptionsOfTagsForAnswer: any[];
}

export const getSmListOfQuestions = createAsyncThunk(
  "questions/getSmListOfQuestions",
  async (componentId: IAppLoaderAction) => {
    let response;

    await axios
      .get(`${apiUrls.questions.get}?_sort=id:DESC&_limit=100`, {})
      .then((res) => {
        response = res.data;
        console.log("response from getSmListOfQuestions: ", response);
      })
      .catch((e) => {
        console.error(e);
        return e;
      });

    return response;
  },
);

export const getFullListOfQuestions = createAsyncThunk(
  "questions/getFullListOfQuestions",
  async (componentId: IAppLoaderAction) => {
    let response;
    await axios
      .get(`${apiUrls.questions.get}?_sort=id:DESC&_limit=-1`)
      .then((res) => {
        response = res.data;
        console.log("response from getFullListOfQuestions: ", response);
      })
      .catch((e) => {
        console.error(e);
        return e;
      });

    return response;
  },
);

export const getSpecificQuestion = createAsyncThunk(
  "questions/getSpecificQuestion",
  async ({ idOfQuestion }: { idOfQuestion: string } & IAppLoaderAction) => {
    let response;

    await axios
      .get(`${apiUrls.questions.get}/${idOfQuestion}`)
      .then((res) => {
        response = res.data;
      })
      .catch((e) => {
        console.error(e);
      });

    return response;
  },
);

export const getAllOptionsOfTagsForAnswer = createAsyncThunk(
  "questions/getAllOptionsOfTagsForAnswer",
  async (componentId: IAppLoaderAction) => {
    let response: any;

    await axios
      .get(apiUrls.tags.get)
      .then((res) => {
        response = res.data;
      })
      .catch((e) => {
        console.error(e);
      });

      const optionsNeeded = response.map((x: any) => ({
        value: x.id,
        label: x.hashtag != null ? x.hashtag : `ID: ${x.id}`,
      }));

      return optionsNeeded;
  },
);

export const createEverythingAtOnce = createAsyncThunk(
  "questions/createEverythingAtOnce",
  async ({ data }: { data: any } & IAppLoaderAction) => {
    let response: any;

    console.log("data from createEverythingAtOnce: ", data);

    await axios
      .post(apiUrls.questions.createEverythingAtOnce, data)
      .then((res) => {
        response = res.data;
      })
      .catch((e) => {
        console.error(e);
      });

      return response;
  },
);

export const createLotsOfQuestionAtOnce = createAsyncThunk(
  "questions/createLotsOfQuestionAtOnce",
  async ({ data }: { data: any } & IAppLoaderAction) => {
    let response: any;

    console.log("data from createLotsOfQuestionAtOnce: ", data);

    await axios
      .post(apiUrls.questions.createLotsOfQuestionAtOnce, data)
      .then((res) => {
        response = res.data;
      })
      .catch((e) => {
        console.error(e);
      });

    return response;

  },
);


export const generateAiQuestions = createAsyncThunk(
  "questions/generateAiQuestions",
  async ({ data }: { data: any } & IAppLoaderAction) => {
    let response: any;

    console.log("data from generateAiQuestions: ", data);

    if(data.typeOfQuestions === false) {
      data.typeOfQuestions = "closed";
    }
    else {
      data.typeOfQuestions = "open";
    }

    if(data.language === false) {
      data.language = "english";
    } 
    else {
      data.language = "french";
    }

    console.log("edited data form generateAiQuestions: ", data);

    await axios
      .post(apiUrls.questions.generateAiQuestions, data)
      .then((res) => {
        response = res.data;
      })
      .catch((e) => {
        console.error(e);
      });

      console.log("response from generateAiQuestions: ", response);
      return response;
  },
);

export const generateAiAnswers = createAsyncThunk(
  "questions/generateAiAnswers",
  async ({ data }: { data: any } & IAppLoaderAction) => {
    let response: any;

    console.log("data from generateAiAnswers: ", data);

    if(data.language === false) {
      data.language = "english";
    } 
    else {
      data.language = "french";
    }

    console.log("edited data form generateAiAnswers: ", data);

    await axios
      .post(apiUrls.questions.generateAiAnswers, data)
      .then((res) => {
        response = res.data;
      })
      .catch((e) => {
        console.error(e);
      });

      console.log("response from generateAiQuestions: ", response);
      return response;
  },
);

//deleteQNA
export const deleteQNA = createAsyncThunk(
  "questions/deleteQNA",
  async ({ idOfQuestion }: { idOfQuestion: number } & IAppLoaderAction) => {

    await axios
      .delete(`${apiUrls.questions.deleteQNA}/${idOfQuestion}`)
      .catch((e) => {
        console.error(e);
      });

      return idOfQuestion;
  },
);

const initialState: IQuestions = {
  fullListOfQuestions: [],
  smListOfQuestions: [],
  specificQuestion: {},
  allOptionsOfTagsForAnswer: [],
};

export const questionsSlice = createSlice({
  name: "questions",
  initialState,
  reducers: {
    clearAllQuestions: (state: IQuestions) => {
      state.fullListOfQuestions = [];
    },
    updateFullListOfQuestions: (state: IQuestions, { payload }) => {
      if (state.fullListOfQuestions?.findIndex((x) => x.id == payload.id) !== undefined) {
        state.fullListOfQuestions[
          state.fullListOfQuestions?.findIndex((x) => x.id == payload.id) as any
        ] = payload;
      }
    },
    updateSmListOfQuestions: (state: IQuestions, { payload }) => {
      if (state.smListOfQuestions?.findIndex((x) => x.id == payload.id) !== undefined) {
        state.smListOfQuestions[state.smListOfQuestions?.findIndex((x) => x.id == payload.id) as any] =
          payload;
      }
    },
    addNewOption: (state: IQuestions, { payload }) => {
      state.allOptionsOfTagsForAnswer = [...state.allOptionsOfTagsForAnswer, payload];
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getSmListOfQuestions.fulfilled, (state: IQuestions, { payload }) => {
      state.smListOfQuestions = payload ?? [];
    });
    builder.addCase(getFullListOfQuestions.fulfilled, (state: IQuestions, { payload }) => {
      state.fullListOfQuestions = payload ?? [];
    });
    builder.addCase(getSpecificQuestion.fulfilled, (state: IQuestions, { payload }: any) => {
      state.specificQuestion = payload;
    });
    builder.addCase(getAllOptionsOfTagsForAnswer.fulfilled, (state: IQuestions, { payload }: any) => {
      state.allOptionsOfTagsForAnswer = payload;
    });
    builder.addCase(createEverythingAtOnce.fulfilled, (state: IQuestions, { payload }: any) => {
      // Add the new question to the beginning of fullListOfQuestions and smListOfQuestions
      state.fullListOfQuestions = [...state.fullListOfQuestions, payload];
      state.smListOfQuestions = [...state.smListOfQuestions, payload];
    });
    builder.addCase(createLotsOfQuestionAtOnce.fulfilled, (state: IQuestions, { payload }: any) => {
      // Add the new question to the beginning of fullListOfQuestions and smListOfQuestions
      state.fullListOfQuestions = [...state.fullListOfQuestions, ...payload];
      state.smListOfQuestions = [...state.smListOfQuestions, ...payload];
    });
    builder.addCase(deleteQNA.fulfilled, (state: IQuestions, { payload }: any) => {
      const deletedQuestionId = payload;
      // Filter out the question with the deleted ID from fullListOfQuestions
      state.fullListOfQuestions = state.fullListOfQuestions.filter(question => question.id !== deletedQuestionId);
      // Filter out the question with the deleted ID from smListOfQuestions
      state.smListOfQuestions = state.smListOfQuestions.filter(question => question.id !== deletedQuestionId);
    });

  },
});

export const questionsReducer = questionsSlice.reducer;

export const getCurrentFullListOfQuestions = (state: RootState) => state.questions.fullListOfQuestions;
export const getCurrentSmListOfQuestions = (state: RootState) => state.questions.smListOfQuestions;
export const getCurrentSpecificQuestion = (state: RootState) => state.questions.specificQuestion;
export const getCurrentOptionsOfTagsForAnswer = (state: RootState) => state.questions.allOptionsOfTagsForAnswer;

export const { clearAllQuestions, addNewOption } = questionsSlice.actions;
