import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../common/state/store";
import { DataGrid, GridRowsProp, GridColDef } from "@mui/x-data-grid";
import { IsLoading } from "../../common/localComponents/AppState/IsLoading";
import { usersColumns } from "./users-columns";
import { Link } from "react-router-dom";
import {
  getCurrentFullListOfUsers,
  getFullListOfUsers,
} from "../../features/users/state/usersSlice";
import { ButtonIcon, DataGridComponent } from "ui";
import { closeStandardModal, openStandardModal } from "../../common/state/slice/modal/modalSlice";
import { ModalConfirmation } from "../../common/localComponents/Modal";
import { deactivateForUser } from "../../features/profilings/state/profilingsSlice";

export const USERS_COMPONENT_ID = "usersComponent";
export const DEACTIVATE_PROFILINGS_COMPONENT_ID = "deactivateProfilingsComponent";

const Users = () => {
  const { t } = useTranslation();

  const dispatch: AppDispatch = useDispatch();

  const [pageSize, setPageSize] = useState(20);
  const [idOfUserToDeactivate, setIdOfUserToDeactivate] = useState(-1);

  const [columnVisibilityModel, setColumnVisibilityModel] = useState({
    firstname: false,
    lastname: false,
  });

  const { fullListOfUsers } = useSelector((state: RootState) => {
    return {
      fullListOfUsers: getCurrentFullListOfUsers(state),
    };
  });

  useEffect(() => {
    if (!fullListOfUsers || fullListOfUsers.length === 0) {
      dispatch(getFullListOfUsers({ componentId: USERS_COMPONENT_ID }));
    }
  }, []);

  const actionColumn = [
    {
      field: "action",
      headerName: "Action",
      flex: 1,
      sortable: false,
      filterable: false,
      renderCell: (params: any) => {
        return (
          <>
            <Link to={`/users/${params.id}`}>
              <button className="btn-primary-fill bg-blue mr-2">{String(t("general.open"))}</button>
            </Link>
            <button
              className="btn-secondary-fill bg-orange mr-2"
              onClick={() => {
                setIdOfUserToDeactivate(params.id);
                dispatch(openStandardModal("deactivateProfilingsInUsers"));
              }}
            >
              Deactivate Profilings
            </button>
          </>
        );
      },
    },
  ];

  return (
    <>
      <ModalConfirmation
        id="deactivateProfilingsInUsers"
        onClick={() => {
          dispatch(deactivateForUser({ idOfUser: idOfUserToDeactivate, componentId: DEACTIVATE_PROFILINGS_COMPONENT_ID })).then(() => {
            dispatch(closeStandardModal("deactivateProfilingsInUsers"));
          });
        }}
        title="Are you sure you want to deactivate all profilings of this user?"
        text="This action will not be possible to undo."
        componentId={DEACTIVATE_PROFILINGS_COMPONENT_ID}
      />
      <header className="flex w-full bg-white shadow  sticky top-0 z-10">
        <div className="max-w-screen-2xl py-6 px-8 overflow-y-auto">
          <h1 className="text-3xl font-bold text-black">{t("pages.users.title")}</h1>
        </div>
        <div className="flex justify-end align-middle ml-auto my-auto mr-8">
          <ButtonIcon
            icon={"ArrowPathIcon"}
            classNameBtn={"w-14 h-14 bg-white rounded-full shadow-md border"}
            classNameIcon={"w-10 h-10"}
            onClick={() => {
              dispatch(getFullListOfUsers({ componentId: USERS_COMPONENT_ID }));
            }}
          />
        </div>
      </header>
      <div className="w-full h-full pb-6 max-w-9xl mx-auto pt-6 px-6">
        <IsLoading componentId={USERS_COMPONENT_ID} showSuccess={false}>
          <DataGridComponent
            className="bg-white rounded-xl shadow-md"
            rows={fullListOfUsers as GridRowsProp}
            columns={usersColumns.concat(actionColumn as any) as GridColDef[]}
            columnVisibilityModel={columnVisibilityModel}
          />
        </IsLoading>
      </div>
    </>
  );
};

export default Users;
