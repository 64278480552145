import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../common/state/store";
import { DataGrid, GridRowsProp, GridColDef } from "@mui/x-data-grid";
import {
  getCurrentFullListOfZoomMeetings,
  getCurrentTotalNumberOfMeeting,
  getFullListOfZoomMeetings,
} from "../../features/zoomMeetings/state/zoomMeetingsSlice";
import { IsLoading } from "../../common/localComponents/AppState/IsLoading";
import { meetingsColumns } from "./meetings-columns";
import { ButtonIcon, DataGridComponent, Dropdown, InputIcon } from "ui";
import { set } from "lodash";
export const MEETINGS_COMPONENT_ID = "profileComponent";
import { Select, MenuItem, FormControl, InputLabel, TextField } from "@mui/material";

const ZoomMeetings = () => {
  const { t } = useTranslation();

  const dispatch: AppDispatch = useDispatch();

  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(20);
  const [sortModel, setSortModel] = useState([]);
  const [filter, setFilter] = useState("id");
  const [searchTerm, setSearchTerm] = useState("");

  const handleChange = (event: any) => {
    console.log("event.target.value:", event.target.value);

    setFilter(event.target.value as string);
  };

  const handleSearchChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    console.log("event.target.value:", event.target.value);

    setSearchTerm(event.target.value as string);
  };

  const { fullListOfMeetings, totalNumberOfMeeting } = useSelector((state: RootState) => {
    return {
      fullListOfMeetings: getCurrentFullListOfZoomMeetings(state),
      totalNumberOfMeeting: getCurrentTotalNumberOfMeeting(state),
    };
  });

  useEffect(() => {
    dispatch(
      getFullListOfZoomMeetings({
        componentId: MEETINGS_COMPONENT_ID,
        page: page,
        pageSize: pageSize,
        sortModel: sortModel,
        filter: filter,
        searchTerm: searchTerm,
      }),
    );
  }, [page, pageSize, sortModel, searchTerm]);

  return (
    <>
      <header className="flex w-full bg-white shadow  sticky top-0 z-10">
        <div className="max-w-screen-2xl py-6 px-8 overflow-y-auto">
          <h1 className="text-3xl font-bold text-black">{t("pages.zoomMeetings.title")}</h1>
        </div>
        <div className="flex justify-end align-middle ml-auto my-auto mr-8">
          <ButtonIcon
            icon={"ArrowPathIcon"}
            classNameBtn={"w-14 h-14 bg-white rounded-full shadow-md border"}
            classNameIcon={"w-10 h-10"}
            onClick={() => {
              setPage(0);
              dispatch(
                getFullListOfZoomMeetings({
                  componentId: MEETINGS_COMPONENT_ID,
                  page: page,
                  pageSize: pageSize,
                  sortModel: sortModel,
                  filter: filter,
                  searchTerm: searchTerm,
                }),
              );
            }}
          />
        </div>
      </header>
      <div className="flex flex-col h-full w-full min-h-[200px] pt-5 pb-6 px-6">
        <div className="flex mb-2">
          <div>
            <Dropdown
              options={
                meetingsColumns
                  ?.filter(
                    (column: any) =>
                      column.field !== "groups" &&
                      column.field !== "action"
                  )
                  .map((column: any) => ({
                    value: column.field,
                    optionText: column.headerName || column.field,
                  })) as any[]
              }
              className={
                "font-medium disabled:hide-arrow-select disabled:bg-white disabled:border-white disabled:shadow-none disabled:focus:ring-0 disabled:text-gray-900 text-gray-600  text-center sm:text-left ${ ? }"
              }
              asDefaultValue={true}
              value={filter}
              onChange={handleChange}
            />
          </div>
          <div className="flex-1 mt-1 ml-2">
            <InputIcon
              type={"text"}
              position="left"
              placeholder={"Search"}
              value={searchTerm}
              onChange={handleSearchChange}
              icon={"MagnifyingGlassIcon"}
            />
          </div>
        </div>
        <div className="w-full h-full max-w-9xl">
          <IsLoading componentId={MEETINGS_COMPONENT_ID} showSuccess={false}>
            <DataGridComponent
              rows={fullListOfMeetings as GridRowsProp[]}
              columns={meetingsColumns as GridColDef[]}
              page={page}
              pageSize={pageSize}
              paginationMode={"server"}
              filterMode={"server"}
              rowCount={totalNumberOfMeeting} // The total number of rows in all pages
              onPageChange={(newPage: number) => {
                setPage(newPage);
              }}
              onPageSizeChange={(newPageSize: number) => {
                setPageSize(newPageSize);
                setPage(0); // reset the page to 0 when page size changes
              }}
              onSortModelChange={(newModel: any) => {
                // Add this
                console.log("newModel", newModel);
                setSortModel(newModel);

                //setFilter by taking out the .field
                setFilter(newModel[0].field);

                setPage(0); // reset the page to 0 when page size changes
              }}
              sortModel={sortModel}
            />
          </IsLoading>
        </div>
      </div>
    </>
  );
};

export default ZoomMeetings;
