import axios from "axios";

export const setAuthToken = (token: string) => {
  axios.defaults.headers.common["Authorization"] = "";
  delete axios.defaults.headers.common["Authorization"];

  if (token) {
    axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  }
};

export const isTokenExpired = (jwt: string): boolean => {
  const decodedToken: any = JSON.parse(window.atob(jwt.split(".")[1]));
  const currentDate = new Date();

  // JWT exp is in seconds

  console.log(decodedToken.exp * 1000);

  if (decodedToken.exp * 1000 < currentDate.getTime()) {
    return true;
  }
  return false;
};
