import { DataGrid, GridColDef, GridRowsProp } from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { IsLoading } from "../../common/localComponents/AppState/IsLoading";
import { DropdownManyChoices } from "ui";
import ModalConfirmation from "../../common/localComponents/Modal/ModalConfirmation";
import { closeStandardModal, openStandardModal } from "../../common/state/slice/modal/modalSlice";
import { AppDispatch, RootState } from "../../common/state/store";
import {
  getCurrentFullListOfGroups,
  getFullListOfGroups,
} from "../../features/groups/state/groupsSlice";
import { groupsColumns } from "../Groups/groups-columns";
import {
  getCurrentUsersInTheRightFormat,
  getCurrentProjectsInTheRightFormat,
  getCurrentSpecificPool,
  getUsersInTheRightFormat,
  getProjectsInTheRightFormat,
  getSpecificPool,
  updateSpecificPool,
} from "../../features/pools/state/poolsSlice";
import { Input, Dropdown, CustomInputStyle, ButtonIcon, DataGridComponent } from "ui";
import { ErrorMessage } from "@hookform/error-message";
import { projectsColumns } from "../Projects/projects-columns";
import {
  getCurrentFullListOfProjects,
  getFullListOfProjects,
} from "../../features/projects/state/projectsSlice";
import {
  getCurrentFullListOfUsers,
  getFullListOfUsers,
} from "../../features/users/state/usersSlice";

export const USERS_COMPONENT_ID = "usersComponent";
export const ZOOMMEETINGS_COMPONENT_ID = "ZoomMeetingsComponent";
export const GROUPS_NOT_IN_USER_COMPONENT_ID = "groupsNotInPoolComponent";
export const UPDATE_PROJECTS_IN_USER_COMPONENT_ID = "UpdateProjectsInPoolComponent";
export const UPDATE_GROUPS_IN_USER_COMPONENT_ID = "UpdateGroupsInPoolComponent";
export const REMOVE_THIS_USER_FROM_PROJECTS_COMPONENT_ID = "removeThisProjectFromThePoolComponent";
export const REMOVE_THIS_USER_FROM_GROUP_COMPONENT_ID = "removeThisGroupFromThePoolComponent";
export const USER_UPDATE_COMPONENT_ID = "poolUpdateComponent";
export const CHANGEPASSWORD_COMPONENT_ID = "changePasswordComponent";

type IPoolInfo = {
  track: string;
  status: any;
  project: any;
  user: any;
};

const SpecificPool = () => {
  const { t } = useTranslation();

  const dispatch: AppDispatch = useDispatch();

  const {
    specificPool,
    fullListOfProjects,
    fullListOfUsers,
    projectsInTheRightFormat,
    usersInTheRightFormat,
  } = useSelector((state: RootState) => {
    return {
      specificPool: getCurrentSpecificPool(state),
      fullListOfProjects: getCurrentFullListOfProjects(state),
      fullListOfUsers: getCurrentFullListOfUsers(state),
      projectsInTheRightFormat: getCurrentProjectsInTheRightFormat(state),
      usersInTheRightFormat: getCurrentUsersInTheRightFormat(state),
    };
  });

  const {
    register,
    control,
    handleSubmit,
    reset,
    watch,
    formState: { errors },
  } = useForm<IPoolInfo>({
    defaultValues: {
      track: specificPool?.track?.name,
      status: specificPool?.status?.id,
      project: specificPool?.project?.id,
      user: specificPool?.user?.id,
    },
  });

  const [idOfChosenGroups, setIdOfChosenGroup] = useState<number>();
  const [idOfChosenProjects, setIdOfChosenProject] = useState<number>();

  const [notEditable, setNotEditable] = useState(true);

  const [groupsColumnVisibilityModel, setGroupsColumnVisibilityModel] = useState({
    pools: false,
  });

  const [projectsColumnVisibilityModel, setProjectsColumnVisibilityModel] = useState({
    groups: false,
  });

  const location = useLocation();

  const temp = location.pathname.split("/");
  const idOfPool = temp[2];

  useEffect(() => {
    dispatch(getFullListOfUsers({ componentId: USERS_COMPONENT_ID }));

    dispatch(getFullListOfProjects({ componentId: USERS_COMPONENT_ID }));

    dispatch(
      getSpecificPool({
        componentId: ZOOMMEETINGS_COMPONENT_ID,
        idOfPool: idOfPool,
      }),
    ).then((res: any) => {
      console.log(res.payload);
      reset({
        track: res.payload?.track?.name,
        status: res.payload?.status?.id,
        project: res.payload?.project?.id,
        user: res.payload?.user?.id,
      });
    });
  }, []);

  useEffect(() => {
    console.log("fullListOfProjects: ", fullListOfProjects);

    dispatch(
      getProjectsInTheRightFormat({
        componentId: USERS_COMPONENT_ID,
        fullListOfProjects: fullListOfProjects as any,
      }),
    );
  }, [fullListOfProjects]);

  useEffect(() => {
    console.log("fullListOfUsers: ", fullListOfUsers);

    dispatch(
      getUsersInTheRightFormat({
        componentId: USERS_COMPONENT_ID,
        fullListOfUsers: fullListOfUsers as any,
      }),
    );
  }, [fullListOfUsers]);

  const actionProjectsColumn = [
    {
      field: "action",
      headerName: "Action",
      width: 200,
      sortable: false,
      filterable: false,
      renderCell: (params: any) => {
        return (
          <>
            <Link to={`/projects/${params.id}`}>
              <button className="btn-primary-fill bg-blue mr-2">{String(t("general.open"))}</button>
            </Link>
            <button
              type="button"
              className="btn-red-outline mr-4"
              onClick={() => {
                dispatch(openStandardModal("removeTheseProjectFromThePool"));
                setIdOfChosenProject(params.id);
              }}
            >
              {String(t("general.remove"))}
            </button>
          </>
        );
      },
    },
  ];

  const actionGroupsColumn = [
    {
      field: "action",
      headerName: "Action",
      width: 200,
      sortable: false,
      filterable: false,
      renderCell: (params: any) => {
        return (
          <>
            <Link to={`/groups/${params.id}`}>
              <button className="btn-primary-fill bg-blue mr-2">{String(t("general.open"))}</button>
            </Link>
            <button
              type="button"
              className="btn-red-outline mr-4"
              onClick={() => {
                dispatch(openStandardModal("removeTheseGroupFromThePool"));
                setIdOfChosenGroup(params.id);
              }}
            >
              {String(t("general.remove"))}
            </button>
          </>
        );
      },
    },
  ];

  const [pageSize, setPageSize] = useState(10);

  const onSubmitPoolInfo = (data: any) => {
    const updatedPool = {
      status: data.status,
      project: data.project === "0" ? null : data.project,
      user: data.user === "0" ? null : data.user,
    };

    dispatch(
      updateSpecificPool({
        componentId: USER_UPDATE_COMPONENT_ID,
        idOfPool: specificPool.id,
        data: updatedPool,
      }),
    )
      .then(() => {
        setNotEditable(true);
      })
      .catch((e: any) => {
        console.error("catch: ", e);
      });
  };

  const cancel = () => {
    reset({
      track: specificPool?.track?.name,
      status: specificPool?.status?.id,
      project: specificPool?.project?.id,
      user: specificPool?.user?.id,
    });

    setNotEditable(true);
  };

  const handleDeleteGroup = (id: number) => {
    const temp = specificPool.groups.filter((item: any) => item.id !== id);

    const arrayOfGroupsId = [];

    for (const [key, value] of Object.entries(temp) as any) {
      arrayOfGroupsId.push(value.id);
    }

    const data = { groups: arrayOfGroupsId };

    dispatch(
      updateSpecificPool({
        componentId: REMOVE_THIS_USER_FROM_GROUP_COMPONENT_ID,
        idOfPool: idOfPool,
        data: data,
      }),
    ).then(() => {
      dispatch(closeStandardModal("removeTheseGroupFromThePool"));
    });
  };

  const handleDeleteProject = (id: number) => {
    const temp = specificPool.projectsowner.filter((item: any) => item.id !== id);

    const arrayOfProjectsId = [];

    for (const [key, value] of Object.entries(temp) as any) {
      arrayOfProjectsId.push(value.id);
    }

    const data = { projectsowner: arrayOfProjectsId };

    dispatch(
      updateSpecificPool({
        componentId: REMOVE_THIS_USER_FROM_GROUP_COMPONENT_ID,
        idOfPool: idOfPool,
        data: data,
      }),
    ).then(() => {
      dispatch(closeStandardModal("removeTheseProjectsFromThePool"));
    });
  };

  const openInNewTab = (url: string) => {
    window.open(url, "_blank", "noopener,noreferrer");
  };

  return (
    <>
      <ModalConfirmation
        id="removeTheseProjectFromThePool"
        onClick={() => {
          if (idOfChosenProjects !== undefined) {
            handleDeleteProject(idOfChosenProjects);
          }
        }}
        title={t("pages.pools.specific.topicOfDeleteProjectsPopup")}
        text={t("pages.pools.specific.textOfDeleteProjectsPopup")}
        componentId={REMOVE_THIS_USER_FROM_PROJECTS_COMPONENT_ID}
      />
      <ModalConfirmation
        id="removeTheseGroupFromThePool"
        onClick={() => {
          if (idOfChosenGroups !== undefined) {
            handleDeleteGroup(idOfChosenGroups);
          }
        }}
        title={t("pages.pools.specific.topicOfDeleteGroupsPopup")}
        text={t("pages.pools.specific.textOfDeleteGroupsPopup")}
        componentId={REMOVE_THIS_USER_FROM_GROUP_COMPONENT_ID}
      />
      <header className="flex w-full bg-white shadow  sticky top-0 z-10">
        <div className="max-w-screen-2xl py-6 px-8 overflow-y-auto">
          <h1 className="text-3xl font-bold text-black">{`${t("pages.pools.specific.pageTitle")}${
            specificPool?.id ? specificPool?.id : ""
          }`}</h1>
        </div>
        <div className="flex justify-end align-middle ml-auto my-auto mr-8">
          <ButtonIcon
            icon={"ArrowPathIcon"}
            classNameBtn={"w-14 h-14 bg-white rounded-full shadow-md border"}
            classNameIcon={"w-10 h-10"}
            onClick={() => {
              dispatch(getFullListOfGroups({ componentId: USERS_COMPONENT_ID }));

              dispatch(
                getSpecificPool({
                  componentId: ZOOMMEETINGS_COMPONENT_ID,
                  idOfPool: idOfPool,
                }),
              ).then((res: any) => {
                console.log(res.payload);
                reset({
                  track: res.payload?.track?.name,
                  status: res.payload?.status?.id,
                  project: res.payload?.project?.id,
                  user: res.payload?.user?.id,
                });
              });
            }}
          />
        </div>
      </header>
      <IsLoading componentId={USERS_COMPONENT_ID} showSuccess={false}>
        <IsLoading componentId={ZOOMMEETINGS_COMPONENT_ID} showSuccess={false}>
          <IsLoading componentId={GROUPS_NOT_IN_USER_COMPONENT_ID} showSuccess={false}>
            <div className="w-full h-full pb-6 max-w-9xl mx-auto pt-6 px-6">
              <form onSubmit={handleSubmit(onSubmitPoolInfo)}>
                <div className="p-5 h-auto min-h-[200px] w-full bg-white rounded-xl shadow-md">
                  <div className="w-full h-full max-w-9xl">
                    <div className="relative grow w-full">
                      <div className="border-gray-300">
                        <div className="w-full flex flex-row mb-2">
                          <h2 className="pl-2 py-2 text-xl font-bold">
                            {t("pages.pools.specific.info")}
                          </h2>
                          <div className="flex grow justify-end h-[38px] my-auto">
                            {notEditable && (
                              <button
                                className="btn-primary-fill"
                                type="button"
                                onClick={() => {
                                  setNotEditable(false);
                                }}
                              >
                                {t("general.edit")}
                              </button>
                            )}
                          </div>
                        </div>
                        <div className="grid grid-cols-1 xl:grid-cols-2 xl:gap-4">
                          <div className="relative flex flex-col w-full h-full max-w-9xl">
                            <div className="w-full h-full max-w-9xl">
                              <div className="relative grow w-full">
                                <div className="border-gray-300">
                                  <div className="h-full">
                                    <CustomInputStyle title={t("pages.pools.specific.track")}>
                                      <Input
                                        type="text"
                                        readOnly={true}
                                        registerFct={() => register("track")}
                                        className="font-medium read-only:bg-white read-only:border-white read-only:shadow-none read-only:focus:ring-0 text-gray-600  max-w-[250px] text-center sm:text-left ${ ? }"
                                        placeholder={t("pages.pools.specific.firstNamePlaceholder")}
                                      />
                                    </CustomInputStyle>
                                    <CustomInputStyle title={t("pages.pools.specific.status")}>
                                      <div className="w-full max-w-[250px]">
                                        <Dropdown
                                          options={[
                                            { value: "1", optionText: "Published" },
                                            { value: "2", optionText: "Planned" },
                                            { value: "3", optionText: "Upnext" },
                                            { value: "4", optionText: "Queue" },
                                            { value: "5", optionText: "Typeform" },
                                            { value: "6", optionText: "To review" },
                                            { value: "7", optionText: "Reviewed" },
                                            { value: "8", optionText: "To correct" },
                                          ]}
                                          disabled={notEditable}
                                          className={
                                            "font-medium disabled:hide-arrow-select disabled:bg-white disabled:border-white disabled:shadow-none disabled:focus:ring-0 disabled:text-gray-900 text-gray-600  max-w-[250px] text-center sm:text-left ${ ? }"
                                          }
                                          style={{
                                            border: errors.status
                                              ? "1.5px solid rgb(239 68 68)"
                                              : "",
                                          }}
                                          registerFct={() => register("status")}
                                          asDefaultValue={true}
                                        />
                                      </div>
                                    </CustomInputStyle>
                                    <CustomInputStyle title={t("pages.pools.specific.project")}>
                                      <div className="w-full max-w-[250px]">
                                        <Dropdown
                                          options={projectsInTheRightFormat}
                                          disabled={notEditable}
                                          className={
                                            "font-medium disabled:hide-arrow-select disabled:bg-white disabled:border-white disabled:shadow-none disabled:focus:ring-0 disabled:text-gray-900 text-gray-600  max-w-[250px] text-center sm:text-left ${ ? }"
                                          }
                                          style={{
                                            border: errors.project
                                              ? "1.5px solid rgb(239 68 68)"
                                              : "",
                                          }}
                                          registerFct={() => register("project")}
                                          asDefaultValue={false}
                                        />
                                      </div>
                                    </CustomInputStyle>
                                    <CustomInputStyle title={t("pages.pools.specific.user")}>
                                      <div className="w-full max-w-[250px]">
                                        <Dropdown
                                          options={usersInTheRightFormat}
                                          disabled={notEditable}
                                          className={
                                            "font-medium disabled:hide-arrow-select disabled:bg-white disabled:border-white disabled:shadow-none disabled:focus:ring-0 disabled:text-gray-900 text-gray-600  max-w-[250px] text-center sm:text-left ${ ? }"
                                          }
                                          style={{
                                            border: errors.user ? "1.5px solid rgb(239 68 68)" : "",
                                          }}
                                          registerFct={() => register("user")}
                                          asDefaultValue={false}
                                        />
                                      </div>
                                    </CustomInputStyle>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="relative flex flex-col w-full h-full max-w-9xl">
                            <div className="w-full h-full max-w-9xl">
                              <div className="relative grow w-full">
                                <div className="border-gray-300">
                                  <div className="h-full">
                                    <CustomInputStyle title="Googledirouid">
                                      <button
                                        className="btn-primary-fill h-[38px] ml-3 mt-1 sm:mr-auto md:my-auto"
                                        onClick={() =>
                                          openInNewTab(
                                            `https://drive.google.com/drive/folders/${specificPool.googledirouid}?usp=sharing`,
                                          )
                                        }
                                      >
                                        {String(t("general.open"))}
                                      </button>
                                    </CustomInputStyle>
                                    <CustomInputStyle title="Googledirouidtracks">
                                      <button
                                        className="btn-primary-fill h-[38px] ml-3 mt-1 sm:mr-auto md:my-auto"
                                        onClick={() =>
                                          openInNewTab(
                                            `https://drive.google.com/drive/folders/${specificPool.googledirouidtracks}?usp=sharing`,
                                          )
                                        }
                                      >
                                        {String(t("general.open"))}
                                      </button>
                                    </CustomInputStyle>
                                    <CustomInputStyle title="Googledirouidshared">
                                      <button
                                        className="btn-primary-fill h-[38px] ml-3 mt-1 sm:mr-auto md:my-auto"
                                        onClick={() =>
                                          openInNewTab(
                                            `https://drive.google.com/drive/folders/${specificPool.googledirouidshared}?usp=sharing`,
                                          )
                                        }
                                      >
                                        {String(t("general.open"))}
                                      </button>
                                    </CustomInputStyle>
                                    <CustomInputStyle
                                      title={t("pages.pools.specific.googledocouidshared")}
                                    >
                                      <button
                                        className="btn-primary-fill h-[38px] ml-3 mt-1 sm:mr-auto md:my-auto"
                                        onClick={() =>
                                          openInNewTab(
                                            `https://docs.google.com/document/d/${specificPool.googledocouidshared}`,
                                          )
                                        }
                                      >
                                        {String(t("general.open"))}
                                      </button>
                                    </CustomInputStyle>
                                    <CustomInputStyle
                                      title={t("pages.pools.specific.googledocouid")}
                                    >
                                      <button
                                        className="btn-primary-fill h-[38px] ml-3 mt-1 sm:mr-auto md:my-auto"
                                        onClick={() =>
                                          openInNewTab(
                                            `https://docs.google.com/document/d/${specificPool.googledocouid}`,
                                          )
                                        }
                                      >
                                        {String(t("general.open"))}
                                      </button>
                                    </CustomInputStyle>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="flex justify-end h-[38px]">
                          <IsLoading componentId={USER_UPDATE_COMPONENT_ID} showSuccess={false}>
                            <>
                              {notEditable === false && (
                                <>
                                  <button
                                    type="button"
                                    className="btn-primary-outline mr-4"
                                    onClick={() => cancel()}
                                  >
                                    {t("general.cancel")}
                                  </button>
                                  <button className="btn-primary-fill" type="submit">
                                    {t("general.save")}
                                  </button>
                                </>
                              )}
                            </>
                          </IsLoading>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </IsLoading>
        </IsLoading>
      </IsLoading>
    </>
  );
};

export default SpecificPool;
