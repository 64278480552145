import { getGridStringOperators } from "@mui/x-data-grid";
import { Link } from "react-router-dom";

export const profilingsColumns = [
  { field: "id", headerName: "ID", width: 75 },
  {
    field: "tag",
    headerName: "Tag",
    flex: 1,
    minWidth: 200,
    renderCell: (params: any) => {
      const onClickEdit = (e: any) => {
        e.stopPropagation(); // don't select this row after clicking
      };
      return (
        <Link to={`/profilings/${params.id}`}>
          <p>{params.row.tag?.hashtag}</p>
        </Link>
      );
    },
    valueGetter: (params: any) => {
      return params.row.tag?.hashtag;
    },
  },
  {
    field: "isactive",
    headerName: "Is Active",
    type: "string",
    renderCell: (params: any) => {
      const onClickEdit = (e: any) => {
        e.stopPropagation(); // don't select this row after clicking
      };

      const isActive = params.row.isactive;
      const isActiveString = isActive ? "true" : "false";

      return isActiveString;
    },
    valueGetter: (params: any) => {
      const isActive = params.row.isactive;
      const isActiveString = isActive ? "true" : "false";

      return isActiveString;
    },
  },
  {
    field: "isvalidated",
    headerName: "Is Validated",
    type: "string",
    renderCell: (params: any) => {
      const onClickEdit = (e: any) => {
        e.stopPropagation(); // don't select this row after clicking
      };

      const isValidated = params.row.isvalidaated;
      const isValidatedString = isValidated ? "true" : "false";

      return isValidatedString;
    },
    valueGetter: (params: any) => {
      const isValidated = params.row.isvalidaated;
      const isValidatedString = isValidated ? "true" : "false";

      return isValidatedString;
    },
  },
  {
    field: "project",
    headerName: "Project",
    type: "string",
    flex: 1,
    minWidth: 150,
    renderCell: (params: any) => {
      const onClickEdit = (e: any) => {
        e.stopPropagation(); // don't select this row after clicking
      };

      return params.row.project?.name;
    },
    valueGetter: (params: any) => {
      return params.row.project?.name;
    },
  },
  {
    field: "user",
    headerName: "User",
    type: "string",
    flex: 1,
    minWidth: 230,
    renderCell: (params: any) => {
      const onClickEdit = (e: any) => {
        e.stopPropagation(); // don't select this row after clicking
      };

      return params.row.user?.email;
    },
    valueGetter: (params: any) => {
      return params.row.user?.email;
    },
  },
];
