import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../common/state/store";
import { DataGrid, GridRowsProp, GridColDef, GridToolbar } from "@mui/x-data-grid";
import { IsLoading } from "../../common/localComponents/AppState/IsLoading";
import { groupsColumns } from "./groups-columns";
import {
  getCurrentFullListOfGroups,
  getFullListOfGroups,
} from "../../features/groups/state/groupsSlice";
import { Link } from "react-router-dom";
import { ButtonIcon } from "ui";
import { DataGridComponent } from "ui";
export const GROUPS_COMPONENT_ID = "profileComponent";

const Groups = () => {
  const { t } = useTranslation();

  const dispatch: AppDispatch = useDispatch();

  const [pageSize, setPageSize] = useState(20);

  const { fullListOfGroups } = useSelector((state: RootState) => {
    return {
      fullListOfGroups: getCurrentFullListOfGroups(state),
    };
  });

  useEffect(() => {
    if (!fullListOfGroups || fullListOfGroups.length === 0) {
      dispatch(getFullListOfGroups({ componentId: GROUPS_COMPONENT_ID }));
    }
  }, []);

  const actionColumn = [
    {
      field: "action",
      headerName: "Action",
      flex: 1,
      sortable: false,
      filterable: false,
      renderCell: (params: any) => {
        return (
          <>
            <Link to={`/groups/${params.id}`}>
              <button className="btn-primary-fill bg-blue mr-2">{String(t("general.open"))}</button>
            </Link>
          </>
        );
      },
    },
  ];

  return (
    <>
      <header className="flex w-full bg-white shadow  sticky top-0 z-10">
        <div className="max-w-screen-2xl py-6 px-8 overflow-y-auto">
          <h1 className="text-3xl font-bold text-black">{t("pages.groups.title")}</h1>
        </div>
        <div className="flex justify-end align-middle ml-auto my-auto mr-8">
          <ButtonIcon
            icon={"ArrowPathIcon"}
            classNameBtn={"w-14 h-14 bg-white rounded-full shadow-md border"}
            classNameIcon={"w-10 h-10"}
            onClick={() => {
              dispatch(getFullListOfGroups({ componentId: GROUPS_COMPONENT_ID }));
            }}
          />
        </div>
      </header>
      <div className="w-full h-full pb-6 max-w-9xl mx-auto pt-6 px-6">
        <IsLoading componentId={GROUPS_COMPONENT_ID} showSuccess={false}>
          <DataGridComponent
            rows={fullListOfGroups as GridRowsProp}
            columns={groupsColumns.concat(actionColumn as any) as GridColDef[]}
          />
        </IsLoading>
      </div>
    </>
  );
};

export default Groups;
