import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../common/state/store";
import { DataGrid, GridRowsProp, GridColDef, GridToolbar } from "@mui/x-data-grid";
import { IsLoading } from "../../common/localComponents/AppState/IsLoading";
import { questionsColumns } from "./questions-columns";
import {
  getCurrentFullListOfQuestions,
  getCurrentOptionsOfTagsForAnswer,
  getAllOptionsOfTagsForAnswer,
  getFullListOfQuestions,
  addNewOption,
  createEverythingAtOnce,
  deleteQNA,
} from "../../features/questions/state/questionsSlice";
import { Link } from "react-router-dom";
import { ButtonIcon, DropdownWithNew, Input } from "ui";
import { DataGridComponent, DynamicTextarea } from "ui";
import ModalStandard from "../../common/localComponents/Modal/ModalStandard";
import { openStandardModal, closeStandardModal } from "../../common/state/slice/modal/modalSlice";
import { useForm } from "react-hook-form";
import { ModalConfirmation } from "../../common/localComponents/Modal";
import NewQuestionForm from "./pageComponents/new-question-form";
import AiGeneratedQuestions from "./pageComponents/ai-generated-questions";

export const QUESTION_COMPONENT_ID = "questionComponent";
export const CREATE_QUESTION_COMPONENT_ID = "createQuestionComponent";
export const REMOVE_QUESTION_COMPONENT_ID = "removeQuestionComponent";
export const TAG_COMPONENT_ID = "tagComponent";

const Questions = () => {
  const { t } = useTranslation();

  const dispatch: AppDispatch = useDispatch();

  const [idOfQuestionToDelete, setIdOfQuestionToDelete] = useState(-1);
  const [answerSets, setAnswerSets] = useState([{ id: 0 }]);

  const { fullListOfQuestions, allOptionsOfTagForAnswer } = useSelector((state: RootState) => {
    return {
      fullListOfQuestions: getCurrentFullListOfQuestions(state),
      allOptionsOfTagForAnswer: getCurrentOptionsOfTagsForAnswer(state),
    };
  });

  const {
    control,
    register,
    handleSubmit,
    reset,
    unregister,
    watch,
    formState: { errors },
    setValue, // This is the setValue function from react-hook-form
  } = useForm();

  useEffect(() => {
    if (!fullListOfQuestions || fullListOfQuestions.length === 0) {
      dispatch(getFullListOfQuestions({ componentId: QUESTION_COMPONENT_ID }));
    }
    dispatch(getAllOptionsOfTagsForAnswer({ componentId: TAG_COMPONENT_ID }));
  }, []);

  const onSubmit = (data: any) => {
    console.log("submitted data", data);
    dispatch(
      createEverythingAtOnce({ componentId: CREATE_QUESTION_COMPONENT_ID, data: data }),
    ).then(() => {
      dispatch(closeStandardModal("addNewQuestion"));
    });
  };

  useEffect(() => {
    setValue("validatedtagtriggerlist", []);
    setValue("unvalidatedtagtriggerlist", []);
    answerSets.forEach((answerSet) => {
      setValue(`tagstovalidate_${answerSet.id}`, []);
      setValue(`tagstounvalidate_${answerSet.id}`, []);
    });
  }, []);

  // Update both the local state and the Redux state when a new option is created
  const handleNewOptionCreated = (newOption: any) => {
    dispatch(addNewOption(newOption));
  };

  const actionColumn = [
    {
      field: "action",
      headerName: "Action",
      minWidth: 200,
      flex: 1,
      sortable: false,
      filterable: false,
      renderCell: (params: any) => {
        return (
          <>
            <Link to={`/questions/${params.id}`}>
              <button className="btn-primary-fill bg-blue mr-2">{String(t("general.open"))}</button>
            </Link>
            <button
              className="btn-primary-fill bg-red-400 mr-2 hover:bg-red-500"
              onClick={() => {
                setIdOfQuestionToDelete(params.id);
                dispatch(openStandardModal("deleteQuestion"));
              }}
            >
              {String(t("general.delete"))}
            </button>
          </>
        );
      },
    },
  ];

  return (
    <>
      <ModalConfirmation
        id="deleteQuestion"
        onClick={() => {
          dispatch(
            deleteQNA({
              idOfQuestion: idOfQuestionToDelete,
              componentId: REMOVE_QUESTION_COMPONENT_ID,
            }),
          ).then(() => {
            dispatch(closeStandardModal("deleteQuestion"));
          });
        }}
        title="Are you sure ou want to delete this question?"
        text="This action will not be possible to undo."
        componentId={REMOVE_QUESTION_COMPONENT_ID}
      />
      <header className="flex w-full bg-white shadow  sticky top-0 z-10">
        <div className="max-w-screen-2xl py-6 px-8 overflow-y-auto">
          <h1 className="text-3xl font-bold text-black">{t("pages.questions.title")}</h1>
        </div>
        <div className="flex justify-end align-middle ml-auto my-auto mr-8">
          <div className="mr-4">
            <ButtonIcon
              icon={"ChatBubbleOvalLeftEllipsisIcon"}
              classNameBtn={"w-14 h-14 bg-white rounded-full shadow-md border"}
              classNameIcon={"w-10 h-10"}
              onClick={() => {
                dispatch(openStandardModal("aiGeneratedQuestions"));
              }}
            />
          </div>
          <div className="mr-4">
            <ButtonIcon
              icon={"PlusSmallIcon"}
              classNameBtn={"w-14 h-14 bg-white rounded-full shadow-md border"}
              classNameIcon={"w-10 h-10"}
              onClick={() => {
                dispatch(openStandardModal("addNewQuestion"));
              }}
            />
          </div>
          <div>
            <ButtonIcon
              icon={"ArrowPathIcon"}
              classNameBtn={"w-14 h-14 bg-white rounded-full shadow-md border"}
              classNameIcon={"w-10 h-10"}
              onClick={() => {
                dispatch(getFullListOfQuestions({ componentId: QUESTION_COMPONENT_ID }));
              }}
            />
          </div>
        </div>
      </header>
      <ModalStandard id="aiGeneratedQuestions" fullScreen={true}>
        <AiGeneratedQuestions />
      </ModalStandard>
      <ModalStandard id="addNewQuestion" fullScreen={true}>
        <NewQuestionForm />
      </ModalStandard>
      <div className="w-full h-full pb-6 max-w-9xl mx-auto pt-6 px-6">
        <IsLoading componentId={QUESTION_COMPONENT_ID} showSuccess={false}>
          <DataGridComponent
            rows={fullListOfQuestions as GridRowsProp}
            columns={questionsColumns.concat(actionColumn as any) as GridColDef[]}
          />
        </IsLoading>
      </div>
    </>
  );
};

export default Questions;
