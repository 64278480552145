import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IUser } from "../../../model/model";
import axios from "axios";
import apiUrls from "../../../api";
import {
  getAuthFromLocalStorage,
  storeAuthInLocalStorage,
} from "../../../common/util/localStorage";
import { IAppLoaderAction } from "../../../common/state/loaderHandleMiddleware";
import { isTokenExpired } from "../../../common/util/auth";

export interface IAuthFormData {
  email: string;
  password: string;
  rememberme: boolean;
}

export interface IForgottenPasswordFormData {
  email: string;
}

export interface IResetPasswordFormData {
  password: string;
  passwordConfirmation: string;
}

export interface IAuth {
  jwt: string;
  user: any;
  userId?: number;
}


export const signIn = createAsyncThunk(
  "auth/login",
  async ({ email, password }: IAuthFormData & IAppLoaderAction) => {
    let response: any;

    console.log("apiUrls.auth.signIn", apiUrls.auth.signIn);

    await axios
      .post<{ jwt: string; user: IUser }>(apiUrls.auth.signIn, {
        identifier: email,
        password,
      })
      .then((res) => {
        console.log("apiUrls.auth.signIn", apiUrls.auth.signIn);
        console.log("res", res);

        if (res.data.user.role.name === "Admin") {
          response = res.data;
        } else {
          throw "You are not an Admin!";
        }
      });

    const { jwt, user } = response;
    // Storing jwt and user in local storage
    storeAuthInLocalStorage(user, jwt, true);

    return { jwt, user };
  },
);

export const setCurrentUserAndJwt = createAsyncThunk(
  "profile/setCurrentUserAndJwt",
  // eslint-disable-next-line no-empty-pattern
  async () => {
    let jwt: string | undefined;
    let user: any | undefined;
    const authFromLocalStorage = getAuthFromLocalStorage();
    if (
      authFromLocalStorage.user &&
      authFromLocalStorage.jwt &&
      authFromLocalStorage.isRemebered &&
      authFromLocalStorage.jwt !== null &&
      !isTokenExpired(authFromLocalStorage.jwt)
    ) {
      console.log("2");
      jwt = authFromLocalStorage.jwt;
      user = authFromLocalStorage.user;
    } else {
      // await axios
      //   .get(`${apiUrls.register.users}/me`)
      //   .then((res) => {
      //     currentUser = res.data;
      //     organization = res.data.organization;
      //   })
      //   .catch((e) => {
      //     console.error(e);
      //     return e;
      //   });
      console.log("3");
      jwt = "";
      user = undefined;
    }

    return { user, jwt };
  },
);

const initialState: IAuth = { jwt: "", user: undefined };

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setAuth: (state: IAuth, action: PayloadAction<{ jwt: string; user: any }>) => {
      state.jwt = action.payload.jwt;
      state.user = action.payload.user;
    },
    logoutAuth: (state: IAuth) => {
      state.jwt = "";
      state.user = undefined;
      delete axios.defaults.headers.common["Authorization"];
      window.localStorage.clear();
    },
  },
  extraReducers: (builder) => {
    builder.addCase(signIn.fulfilled, (state, { payload }) => {
      state.jwt = payload.jwt;
      state.user = payload.user;
    });
    builder.addCase(setCurrentUserAndJwt.fulfilled, (state, { payload }) => {
      state.jwt = payload.jwt;
      state.user = payload.user;
    });
  },
});

export const authReducer = authSlice.reducer;

export const getCurrentJwt = (state: IAuth) => state.jwt;
export const getCurrentUser = (state: IAuth) => state.user;

export const { logoutAuth, setAuth } = authSlice.actions;
