import { IUser } from "../../model/model";

const AUTH_USER_KEY = "myctofriend.auth.user";
const AUTH_JWT_KEY = "myctofriend.auth.jwt";
const AUTH_JWT_ISREMEMBERED = "myctofriend.auth.isRemebered";

export const storeInLocalStorage = (key: string, value: any) => {
  localStorage.setItem(key, value);
};

export const getFromLocalStorage = (key: string) => {
  return localStorage.getItem(key) ? localStorage.getItem(key) : "";
};

export const storeAuthInLocalStorage = (user: IUser, jwt: string, isRemebered: boolean) => {
  storeInLocalStorage(AUTH_USER_KEY, JSON.stringify(user));
  storeInLocalStorage(AUTH_JWT_KEY, jwt);
  storeInLocalStorage(AUTH_JWT_ISREMEMBERED, isRemebered);
};

export const getAuthFromLocalStorage = (): {
  user: any | null;
  jwt: string | null;
  isRemebered: boolean | null;
} => {
  const userStr = getFromLocalStorage(AUTH_USER_KEY);
  const user = userStr && userStr !== "" ? (JSON.parse(userStr) as any) : null;
  const jwt = getFromLocalStorage(AUTH_JWT_KEY);
  const isRemeberedStr = getFromLocalStorage(AUTH_JWT_ISREMEMBERED);
  const isRemebered =
    isRemeberedStr && isRemeberedStr !== "" ? (JSON.parse(isRemeberedStr) as boolean) : null;
  return { user, jwt, isRemebered };
};
