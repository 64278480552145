import { createSlice } from "@reduxjs/toolkit";

export interface ModalState {
  showStandardModal: { show: boolean; id: string };
  showFormModal: boolean;
  showPopupModal: boolean;
  showSlider: { show: boolean; id: string };
}

const initialState: ModalState = {
  showStandardModal: { show: false, id: "" },
  showFormModal: false,
  showPopupModal: false,
  showSlider: { show: false, id: "" },
};

export const modalSlice = createSlice({
  name: "modal",
  initialState,
  reducers: {
    openFormModal: (state: ModalState) => {
      state.showFormModal = true;
    },
    closeFormModal: (state: ModalState) => {
      state.showFormModal = false;
    },
    openPopupModal: (state: ModalState) => {
      state.showPopupModal = true;
    },
    closePopupModal: (state: ModalState) => {
      state.showPopupModal = false;
    },
    openSlider: (state: ModalState, actions) => {
      state.showSlider = {
        show: true,
        id: actions.payload,
      };
    },
    closeSlider: (state: ModalState, actions) => {
      state.showSlider = {
        show: false,
        id: actions.payload,
      };
    },
    openStandardModal: (state: ModalState, actions) => {
      state.showStandardModal = {
        show: true,
        id: actions.payload,
      };
    },
    closeStandardModal: (state: ModalState, actions) => {
      state.showStandardModal = {
        show: false,
        id: actions.payload,
      };
    },
  },
});

export const {
  openFormModal,
  closeFormModal,
  openPopupModal,
  closePopupModal,
  openSlider,
  closeSlider,
  openStandardModal,
  closeStandardModal,
} = modalSlice.actions;

export default modalSlice.reducer;
