import { DataGrid, GridColDef, GridRowsProp } from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { IsLoading } from "../../common/localComponents/AppState/IsLoading";
import { DropdownManyChoices } from "ui";
import ModalConfirmation from "../../common/localComponents/Modal/ModalConfirmation";
import { closeStandardModal, openStandardModal } from "../../common/state/slice/modal/modalSlice";
import { AppDispatch, RootState } from "../../common/state/store";
import {
  getCurrentFullListOfGroups,
  getFullListOfGroups,
} from "../../features/groups/state/groupsSlice";
import { groupsColumns } from "../Groups/groups-columns";
import {
  getCurrentSpecificTrack,
  getSpecificTrack,
  updateSpecificTrack,
} from "../../features/tracks/state/tracksSlice";
import { Input, Dropdown, CustomInputStyle, ButtonIcon, DataGridComponent } from "ui";
import { ErrorMessage } from "@hookform/error-message";
import { projectsColumns } from "../Projects/projects-columns";

export const GROUPS_COMPONENT_ID = "groupsComponent";
export const ZOOMMEETINGS_COMPONENT_ID = "ZoomMeetingsComponent";
export const GROUPS_NOT_IN_USER_COMPONENT_ID = "groupsNotInTrackComponent";
export const UPDATE_PROJECTS_IN_USER_COMPONENT_ID = "UpdateProjectsInTrackComponent";
export const UPDATE_GROUPS_IN_USER_COMPONENT_ID = "UpdateGroupsInTrackComponent";
export const REMOVE_THIS_USER_FROM_PROJECTS_COMPONENT_ID = "removeThisProjectFromTheTrackComponent";
export const REMOVE_THIS_USER_FROM_GROUP_COMPONENT_ID = "removeThisGroupFromTheTrackComponent";
export const USER_UPDATE_COMPONENT_ID = "trackUpdateComponent";
export const CHANGEPASSWORD_COMPONENT_ID = "changePasswordComponent";

type ITrackInfo = {
  name: string;
};

const SpecificTrack = () => {
  const { t } = useTranslation();

  const dispatch: AppDispatch = useDispatch();

  const { specificTrack, fullListOfGroups } = useSelector((state: RootState) => {
    return {
      specificTrack: getCurrentSpecificTrack(state),
      fullListOfGroups: getCurrentFullListOfGroups(state),
    };
  });

  const {
    register,
    control,
    handleSubmit,
    reset,
    watch,
    formState: { errors },
  } = useForm<ITrackInfo>({
    defaultValues: {
      name: specificTrack?.name,
    },
  });

  const [idOfChosenGroups, setIdOfChosenGroup] = useState<number>();
  const [idOfChosenPools, setIdOfChosenPools] = useState<number>();

  const [notEditable, setNotEditable] = useState(true);

  const [poolsColumnVisibilityModel, setPoolsColumnVisibilityModel] = useState({
    groups: false,
  });

  const location = useLocation();

  const temp = location.pathname.split("/");
  const idOfTrack = temp[2];

  useEffect(() => {
    dispatch(getFullListOfGroups({ componentId: GROUPS_COMPONENT_ID }));

    dispatch(
      getSpecificTrack({
        componentId: ZOOMMEETINGS_COMPONENT_ID,
        idOfTrack: idOfTrack,
      }),
    ).then((res: any) => {
      console.log(res.payload);
      reset({
        name: res.payload?.name,
      });
    });
  }, []);

  const actionPoolsColumn = [
    {
      field: "action",
      headerName: "Action",
      width: 200,
      sortable: false,
      filterable: false,
      renderCell: (params: any) => {
        return (
          <>
            <Link to={`/projects/${params.id}`}>
              <button className="btn-primary-fill bg-blue mr-2">{String(t("general.open"))}</button>
            </Link>
            <button
              type="button"
              className="btn-red-outline mr-4"
              onClick={() => {
                dispatch(openStandardModal("removeTheseProjectFromTheTrack"));
                setIdOfChosenPools(params.id);
              }}
            >
              {String(t("general.remove"))}
            </button>
          </>
        );
      },
    },
  ];

  const onSubmitTrackInfo = (data: any) => {
    const updatedTrack = {
      track: data.track?.name,
      lastname: data.lastname,
      email: data.email?.toLocaleLowerCase(),
      trackname: data.trackname,
      status: data.status,
      blocked: data.blocked,
      confirmed: data.confirmed,
    };

    dispatch(
      updateSpecificTrack({
        componentId: USER_UPDATE_COMPONENT_ID,
        idOfTrack: specificTrack.id,
        data: updatedTrack,
      }),
    )
      .then(() => {
        setNotEditable(true);
      })
      .catch((e: any) => {
        console.error("catch: ", e);
      });
  };

  const cancel = () => {
    reset({
      name: specificTrack?.name,
    });

    setNotEditable(true);
  };

  const handleDeleteProject = (id: number) => {
    const temp = specificTrack.projectsowner.filter((item: any) => item.id !== id);

    const arrayOfProjectsId = [];

    for (const [key, value] of Object.entries(temp) as any) {
      arrayOfProjectsId.push(value.id);
    }

    const data = { projectsowner: arrayOfProjectsId };

    dispatch(
      updateSpecificTrack({
        componentId: REMOVE_THIS_USER_FROM_GROUP_COMPONENT_ID,
        idOfTrack: idOfTrack,
        data: data,
      }),
    ).then(() => {
      dispatch(closeStandardModal("removeTheseProjectsFromTheTrack"));
    });
  };

  const openInNewTab = (url: string) => {
    window.open(url, "_blank", "noopener,noreferrer");
  };

  return (
    <>
      <ModalConfirmation
        id="removeTheseProjectFromTheTrack"
        onClick={() => {
          if (idOfChosenPools !== undefined) {
            handleDeleteProject(idOfChosenPools);
          }
        }}
        title={t("pages.tracks.specific.topicOfDeleteProjectsPopup")}
        text={t("pages.tracks.specific.textOfDeleteProjectsPopup")}
        componentId={REMOVE_THIS_USER_FROM_PROJECTS_COMPONENT_ID}
      />
      <header className="flex w-full bg-white shadow  sticky top-0 z-10">
        <div className="max-w-screen-2xl py-6 px-8 overflow-y-auto">
          <h1 className="text-3xl font-bold text-black">{`${t("pages.tracks.specific.pageTitle")}${
            specificTrack?.id ? specificTrack?.id : ""
          }`}</h1>
        </div>
        <div className="flex justify-end align-middle ml-auto my-auto mr-8">
          <ButtonIcon
            icon={"ArrowPathIcon"}
            classNameBtn={"w-14 h-14 bg-white rounded-full shadow-md border"}
            classNameIcon={"w-10 h-10"}
            onClick={() => {
              dispatch(getFullListOfGroups({ componentId: GROUPS_COMPONENT_ID }));

              dispatch(
                getSpecificTrack({
                  componentId: ZOOMMEETINGS_COMPONENT_ID,
                  idOfTrack: idOfTrack,
                }),
              ).then((res: any) => {
                console.log(res.payload);
                reset({
                  name: res.payload?.name,
                });
              });
            }}
          />
        </div>
      </header>
      <IsLoading componentId={GROUPS_COMPONENT_ID} showSuccess={false}>
        <IsLoading componentId={ZOOMMEETINGS_COMPONENT_ID} showSuccess={false}>
          <IsLoading componentId={GROUPS_NOT_IN_USER_COMPONENT_ID} showSuccess={false}>
            <div className="w-full h-full pb-6 max-w-9xl mx-auto pt-6 px-6">
              <form onSubmit={handleSubmit(onSubmitTrackInfo)}>
                <div className="p-5 h-auto min-h-[200px] w-full bg-white rounded-xl shadow-md">
                  <div className="w-full h-full max-w-9xl">
                    <div className="relative grow w-full">
                      <div className="border-gray-300">
                        <div className="w-full flex flex-row mb-2">
                          <h2 className="pl-2 py-2 text-xl font-bold">
                            {t("pages.tracks.specific.info")}
                          </h2>
                          <div className="flex grow justify-end h-[38px] my-auto">
                            {notEditable && (
                              <button
                                className="btn-primary-fill"
                                type="button"
                                onClick={() => {
                                  setNotEditable(false);
                                }}
                              >
                                {t("general.edit")}
                              </button>
                            )}
                          </div>
                        </div>
                        <div className="grid grid-cols-1 xl:grid-cols-2 xl:gap-4">
                          <div className="relative flex flex-col w-full h-full max-w-9xl">
                            <div className="w-full h-full max-w-9xl">
                              <div className="relative grow w-full">
                                <div className="border-gray-300">
                                  <div className="h-full">
                                    <CustomInputStyle title={t("pages.tracks.specific.track")}>
                                      <Input
                                        type="text"
                                        readOnly={notEditable}
                                        registerFct={() => register("name")}
                                        className="font-medium read-only:bg-white read-only:border-white read-only:shadow-none read-only:focus:ring-0 text-gray-600  max-w-[250px] text-center sm:text-left ${ ? }"
                                        placeholder={t(
                                          "pages.tracks.specific.firstNamePlaceholder",
                                        )}
                                      />
                                    </CustomInputStyle>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="relative flex flex-col w-full h-full max-w-9xl">
                            <div className="w-full h-full max-w-9xl">
                              <div className="relative grow w-full">
                                <div className="border-gray-300">
                                  <div className="h-full">
                                    <CustomInputStyle title="Processed WorkBook">
                                      <button
                                        className="btn-primary-fill h-[38px] ml-3 mt-1 sm:mr-auto md:my-auto"
                                        onClick={() =>
                                          openInNewTab(
                                            `https://drive.google.com/drive/folders/${specificTrack.googledocouid}?usp=sharing`,
                                          )
                                        }
                                      >
                                        {String(t("general.open"))}
                                      </button>
                                    </CustomInputStyle>
                                    <CustomInputStyle title="WorkBook">
                                      <button
                                        className="btn-primary-fill h-[38px] ml-3 mt-1 sm:mr-auto md:my-auto"
                                        onClick={() =>
                                          openInNewTab(
                                            `https://drive.google.com/drive/folders/${specificTrack.googledocouidorigin}?usp=sharing`,
                                          )
                                        }
                                      >
                                        {String(t("general.open"))}
                                      </button>
                                    </CustomInputStyle>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="flex justify-end h-[38px]">
                          <IsLoading componentId={USER_UPDATE_COMPONENT_ID} showSuccess={false}>
                            <>
                              {notEditable === false && (
                                <>
                                  <button
                                    type="button"
                                    className="btn-primary-outline mr-4"
                                    onClick={() => cancel()}
                                  >
                                    {t("general.cancel")}
                                  </button>
                                  <button className="btn-primary-fill" type="submit">
                                    {t("general.save")}
                                  </button>
                                </>
                              )}
                            </>
                          </IsLoading>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </IsLoading>
        </IsLoading>
      </IsLoading>
    </>
  );
};

export default SpecificTrack;
