import { DataGrid, GridColDef, GridRowsProp } from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { IsLoading } from "../../common/localComponents/AppState/IsLoading";
import { ButtonIcon } from "ui";
import { DataGridComponent } from "ui";
import { DropdownManyChoices } from "ui";
import ModalConfirmation from "../../common/localComponents/Modal/ModalConfirmation";
import { closeStandardModal, openStandardModal } from "../../common/state/slice/modal/modalSlice";
import { AppDispatch, RootState } from "../../common/state/store";
import {
  getCurrentSpecificGroup,
  getCurrentUsersThatAreNotInTheGroup,
  getSpecificGroup,
  getUsersThatAreNotInTheGroup,
  updateUsersInTheGroup,
} from "../../features/groups/state/groupsSlice";
import {
  getCurrentFullListOfUsers,
  getFullListOfUsers,
} from "../../features/users/state/usersSlice";
import { usersColumns } from "../Users/users-columns";

export const USERS_COMPONENT_ID = "usersComponent";
export const GROUP_COMPONENT_ID = "groupComponent";
export const USERS_NOT_IN_GROUP_COMPONENT_ID = "usersNotInGroupComponent";
export const UPDATE_GROUP_COMPONENT_ID = "UpdateGroupComponent";
export const REMOVE_THIS_USER_FROM_GROUP_COMPONENT_ID = "removeThisUserFromTheGroupComponent";

interface IUsersInfo {
  users: string;
}

const SpecificGroup = () => {
  const { t } = useTranslation();

  const dispatch: AppDispatch = useDispatch();

  const [idOfChosenUser, setIdOfChosenUser] = useState<number>();

  const { fullListOfUsers, specificGroup, usersThatAreNotInTheGroup } = useSelector(
    (state: RootState) => {
      return {
        fullListOfUsers: getCurrentFullListOfUsers(state),
        specificGroup: getCurrentSpecificGroup(state),
        usersThatAreNotInTheGroup: getCurrentUsersThatAreNotInTheGroup(state),
      };
    },
  );

  const location = useLocation();

  const temp = location.pathname.split("/");
  const idOfGroup = temp[2];

  useEffect(() => {
    dispatch(getFullListOfUsers({ componentId: USERS_COMPONENT_ID }));

    dispatch(
      getSpecificGroup({
        componentId: GROUP_COMPONENT_ID,
        idOfGroup: idOfGroup,
      }),
    );
  }, []);

  useEffect(() => {
    dispatch(
      getUsersThatAreNotInTheGroup({
        componentId: USERS_NOT_IN_GROUP_COMPONENT_ID,
        fullListOfUsers: fullListOfUsers as any,
        specificGroup: specificGroup,
      }),
    );
  }, [fullListOfUsers, specificGroup]);

  const {
    control,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<IUsersInfo>();

  const actionColumn = [
    {
      field: "action",
      headerName: "Action",
      width: 200,
      sortable: false,
      filterable: false,
      renderCell: (params: any) => {
        return (
          <>
            <Link to={`/users/${params.id}`}>
              <button className="btn-primary-fill bg-blue mr-2">{String(t("general.open"))}</button>
            </Link>
            <button
              type="button"
              className="btn-red-outline mr-4"
              onClick={() => {
                dispatch(openStandardModal("removeThisGroupFromTheMeeting"));
                setIdOfChosenUser(params.id);
              }}
            >
              {String(t("general.remove"))}
            </button>
          </>
        );
      },
    },
  ];

  const [pageSize, setPageSize] = useState(10);
  const [columnVisibilityModel, setColumnVisibilityModel] = useState({
    firstname: false,
    lastname: false,
    projectsowner: false,
    groups: false,
  });

  const onSubmitUsers = (data: any) => {
    let allUsersThatWillBeInTheGroup: any = [];

    if (specificGroup.users.length !== 0) {
      specificGroup.users.forEach((users: any) => {
        allUsersThatWillBeInTheGroup.push(users?.id);
      });
    }

    allUsersThatWillBeInTheGroup = [...allUsersThatWillBeInTheGroup, ...selectedUsers];

    dispatch(
      updateUsersInTheGroup({
        componentId: UPDATE_GROUP_COMPONENT_ID,
        idOfGroup: idOfGroup,
        arrayOfUsersId: allUsersThatWillBeInTheGroup,
      }),
    );
  };

  const handleDelete = (id: number) => {
    const temp = specificGroup.users.filter((item: any) => item.id !== id);

    const arrayOfUsersId = [];

    for (const [value] of Object.entries(temp) as any) {
      arrayOfUsersId.push(value.id);
    }

    dispatch(
      updateUsersInTheGroup({
        componentId: REMOVE_THIS_USER_FROM_GROUP_COMPONENT_ID,
        idOfGroup: idOfGroup,
        arrayOfUsersId: arrayOfUsersId,
      }),
    ).then(() => {
      dispatch(closeStandardModal("removeThisGroupFromTheMeeting"));
    });
  };

  const openInNewTab = (url: string) => {
    window.open(url, "_blank", "noopener,noreferrer");
  };

  const selectedUsers: any = watch().users;

  return (
    <>
      <ModalConfirmation
        id="removeThisGroupFromTheMeeting"
        onClick={() => {
          if (idOfChosenUser !== undefined) {
            handleDelete(idOfChosenUser);
          }
        }}
        title={t("pages.groups.topicOfConfirmationPopup")}
        text={t("pages.groups.textOfConfirmationPopup")}
        componentId={REMOVE_THIS_USER_FROM_GROUP_COMPONENT_ID}
      />
      <header className="flex w-full bg-white shadow  sticky top-0 z-10">
        <div className="max-w-screen-2xl py-6 px-8 overflow-y-auto">
          <h1 className="text-3xl font-bold text-black">{`${t("pages.groups.specificPageTitle")}${
            specificGroup?.name ? specificGroup?.name : ""
          }`}</h1>
        </div>
        <div className="flex justify-end align-middle ml-auto my-auto mr-8">
          <ButtonIcon
            icon={"ArrowPathIcon"}
            classNameBtn={"w-14 h-14 bg-white rounded-full shadow-md border"}
            classNameIcon={"w-10 h-10"}
            onClick={() => {
              dispatch(getFullListOfUsers({ componentId: USERS_COMPONENT_ID }));

              dispatch(
                getSpecificGroup({
                  componentId: GROUP_COMPONENT_ID,
                  idOfGroup: idOfGroup,
                }),
              );
            }}
          />
        </div>
      </header>
      <IsLoading componentId={USERS_COMPONENT_ID} showSuccess={false}>
        <IsLoading componentId={GROUP_COMPONENT_ID} showSuccess={false}>
          <IsLoading componentId={USERS_NOT_IN_GROUP_COMPONENT_ID} showSuccess={false}>
            <div className="w-full h-full pb-6 max-w-9xl mx-auto pt-6 px-6">
              <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 md:pb-4">
                <div className="p-5 h-auto min-h-[200px] w-full bg-white rounded-xl shadow-md">
                  <div className="w-full h-full max-w-9xl">
                    <p className="text-gray-800 font-medium text-base text-center pb-2">
                      {t("pages.groups.info")}
                    </p>
                    <div className="pb-1">
                      <span className="text-gray-900 font-medium text-base">ID: </span>
                      <span className="text-gray-700 font-medium text-base">
                        {specificGroup?.id}
                      </span>
                    </div>
                    <div className="pb-1">
                      <span className="text-gray-900 font-medium text-base">
                        {t("pages.groups.groupName")}
                      </span>
                      <span className="text-gray-700 font-medium text-base">
                        {specificGroup?.name}
                      </span>
                    </div>
                    <div className="pb-1">
                      <span className="text-gray-900 font-medium text-base">
                        {t("pages.groups.projectName")}
                      </span>
                      <span className="text-gray-700 font-medium text-base">
                        {specificGroup?.project?.name}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="p-5 h-auto min-h-[200px] w-full bg-white rounded-xl shadow-md">
                  <div className="w-full h-full max-w-9xl flex md:flex-row flex-col justify-center align-middle">
                    <button
                      className="btn-primary-fill bg-blue mb-1 mx-auto md:my-auto md:mr-1 md:ml-auto"
                      onClick={() =>
                        openInNewTab(
                          `https://docs.google.com/document/d/${specificGroup.googledocouidshared}`,
                        )
                      }
                    >
                      {String(t("pages.groups.openNotesForClients"))}
                    </button>
                    <button
                      className="btn-secondary-fill mt-1 mx-auto md:my-auto md:ml-1 md:mr-auto"
                      onClick={() =>
                        openInNewTab(
                          `https://docs.google.com/document/d/${specificGroup.googledocouid}`,
                        )
                      }
                    >
                      {String(t("pages.groups.openNotesForCoaches"))}
                    </button>
                  </div>
                </div>
              </div>
              <div className="w-full">
                <h2 className="text-2xl font-medium pt-3">
                  {t("pages.groups.addUsersToTheGroup")}
                </h2>
                <div className="flex flex-col justify-center  w-full">
                  <form
                    className="flex border-[1.5px] rounded-xl border-gray-300 md:flex-row flex-col justify-evenly place-content-center mt-4"
                    onSubmit={handleSubmit(onSubmitUsers)}
                  >
                    <div className="pt-4 pb-8 mx-auto ">
                      <div className="w-auto md:w-[480px] lg:w-[660px]">
                        <DropdownManyChoices
                          label="Users:"
                          name="users"
                          control={control}
                          options={usersThatAreNotInTheGroup as any[]}
                          style={{
                            border: errors.users ? "1.5px solid rgb(239 68 68)" : "",
                          }}
                        />
                      </div>
                    </div>
                    <div className="max-w-xs m-auto md:pt-7 pb-4">
                      <button className="btn-primary-fill" type="submit">
                        {t("general.add")}
                      </button>
                    </div>
                  </form>

                  {specificGroup?.users && specificGroup?.users?.length !== 0 ? (
                    <div className="relative pt-6 pb-16">
                      {/* <TableComp rows={getColumnValuesOfPlatforms} colHeaders={colHeadersOfPlatforms} /> */}
                      <div className="my-6 p-5 h-auto min-h-[200px] w-full bg-white rounded-xl shadow-md">
                        <div className="w-full h-full max-w-9xl">
                          <DataGridComponent
                            autoHeight
                            className="bg-white rounded-xl"
                            rows={specificGroup.users as GridRowsProp}
                            columns={usersColumns.concat(actionColumn as any) as GridColDef[]}
                            rowsPerPageOptions={[5, 10, 20]}
                            columnVisibilityModel={columnVisibilityModel}
                          />
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className="flex w-auto">
                      <div className="relative pt-6 pb-16 mr-auto ml-auto">
                        <IsLoading componentId={UPDATE_GROUP_COMPONENT_ID} showSuccess={false}>
                          {t("pages.groups.emptyUsersTable")}
                        </IsLoading>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </IsLoading>
        </IsLoading>
      </IsLoading>
    </>
  );
};

export default SpecificGroup;
