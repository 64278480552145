import { DataGrid, GridColDef, GridRowsProp } from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { IsLoading } from "../../common/localComponents/AppState/IsLoading";
import { ButtonIcon } from "ui";
import { DataGridComponent } from "ui";
import { DropdownManyChoices } from "ui";
import ModalConfirmation from "../../common/localComponents/Modal/ModalConfirmation";
import { closeStandardModal, openStandardModal } from "../../common/state/slice/modal/modalSlice";
import { AppDispatch, RootState } from "../../common/state/store";
import {
  getCurrentSpecificQuestion,
  getSpecificQuestion,
} from "../../features/questions/state/questionsSlice";
import {
  getCurrentFullListOfUsers,
  getFullListOfUsers,
} from "../../features/users/state/usersSlice";
import { usersColumns } from "../Users/users-columns";

export const USERS_COMPONENT_ID = "usersComponent";
export const GROUP_COMPONENT_ID = "questionComponent";
export const USERS_NOT_IN_GROUP_COMPONENT_ID = "usersNotInQuestionComponent";
export const UPDATE_GROUP_COMPONENT_ID = "UpdateQuestionComponent";
export const REMOVE_THIS_USER_FROM_GROUP_COMPONENT_ID = "removeThisUserFromTheQuestionComponent";

interface IUsersInfo {
  users: string;
}

const SpecificQuestion = () => {
  const { t } = useTranslation();

  const dispatch: AppDispatch = useDispatch();

  const [idOfChosenUser, setIdOfChosenUser] = useState<number>();

  const { fullListOfUsers, specificQuestion } = useSelector(
    (state: RootState) => {
      return {
        fullListOfUsers: getCurrentFullListOfUsers(state),
        specificQuestion: getCurrentSpecificQuestion(state),
      };
    },
  );

  const location = useLocation();

  const temp = location.pathname.split("/");
  const idOfQuestion = temp[2];

  useEffect(() => {
    dispatch(getFullListOfUsers({ componentId: USERS_COMPONENT_ID }));

    dispatch(
      getSpecificQuestion({
        componentId: GROUP_COMPONENT_ID,
        idOfQuestion: idOfQuestion,
      }),
    );
  }, []);

  const {
    control,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<IUsersInfo>();

  const actionColumn = [
    {
      field: "action",
      headerName: "Action",
      width: 200,
      sortable: false,
      filterable: false,
      renderCell: (params: any) => {
        return (
          <>
            <Link to={`/users/${params.id}`}>
              <button className="btn-primary-fill bg-blue mr-2">{String(t("general.open"))}</button>
            </Link>
            <button
              type="button"
              className="btn-red-outline mr-4"
              onClick={() => {
                dispatch(openStandardModal("removeThisQuestionFromTheMeeting"));
                setIdOfChosenUser(params.id);
              }}
            >
              {String(t("general.remove"))}
            </button>
          </>
        );
      },
    },
  ];

  const [pageSize, setPageSize] = useState(10);
  const [columnVisibilityModel, setColumnVisibilityModel] = useState({
    firstname: false,
    lastname: false,
    projectsowner: false,
    questions: false,
  });

  const onSubmitUsers = (data: any) => {
    let allUsersThatWillBeInTheQuestion: any = [];

    if (specificQuestion.users.length !== 0) {
      specificQuestion.users.forEach((users: any) => {
        allUsersThatWillBeInTheQuestion.push(users?.id);
      });
    }

    allUsersThatWillBeInTheQuestion = [...allUsersThatWillBeInTheQuestion, ...selectedUsers];

    // dispatch(
    //   updateUsersInTheQuestion({
    //     componentId: UPDATE_GROUP_COMPONENT_ID,
    //     idOfQuestion: idOfQuestion,
    //     arrayOfUsersId: allUsersThatWillBeInTheQuestion,
    //   }),
    // );
  };

  const handleDelete = (id: number) => {
    const temp = specificQuestion.users.filter((item: any) => item.id !== id);

    const arrayOfUsersId = [];

    for (const [value] of Object.entries(temp) as any) {
      arrayOfUsersId.push(value.id);
    }

    // dispatch(
    //   updateUsersInTheQuestion({
    //     componentId: REMOVE_THIS_USER_FROM_GROUP_COMPONENT_ID,
    //     idOfQuestion: idOfQuestion,
    //     arrayOfUsersId: arrayOfUsersId,
    //   }),
    // ).then(() => {
    //   dispatch(closeStandardModal("removeThisQuestionFromTheMeeting"));
    // });
  };

  const openInNewTab = (url: string) => {
    window.open(url, "_blank", "noopener,noreferrer");
  };

  const selectedUsers: any = watch().users;

  return (
    <>
      <ModalConfirmation
        id="removeThisQuestionFromTheMeeting"
        onClick={() => {
          if (idOfChosenUser !== undefined) {
            handleDelete(idOfChosenUser);
          }
        }}
        title={t("pages.questions.topicOfConfirmationPopup")}
        text={t("pages.questions.textOfConfirmationPopup")}
        componentId={REMOVE_THIS_USER_FROM_GROUP_COMPONENT_ID}
      />
      <header className="flex w-full bg-white shadow  sticky top-0 z-10">
        <div className="max-w-screen-2xl py-6 px-8 overflow-y-auto">
          <h1 className="text-3xl font-bold text-black">{`${t("pages.questions.specificPageTitle")}${
            specificQuestion?.name ? specificQuestion?.name : ""
          }`}</h1>
        </div>
        <div className="flex justify-end align-middle ml-auto my-auto mr-8">
          <ButtonIcon
            icon={"ArrowPathIcon"}
            classNameBtn={"w-14 h-14 bg-white rounded-full shadow-md border"}
            classNameIcon={"w-10 h-10"}
            onClick={() => {
              dispatch(getFullListOfUsers({ componentId: USERS_COMPONENT_ID }));

              dispatch(
                getSpecificQuestion({
                  componentId: GROUP_COMPONENT_ID,
                  idOfQuestion: idOfQuestion,
                }),
              );
            }}
          />
        </div>
      </header>
      <IsLoading componentId={USERS_COMPONENT_ID} showSuccess={false}>
        <IsLoading componentId={GROUP_COMPONENT_ID} showSuccess={false}>
          <IsLoading componentId={USERS_NOT_IN_GROUP_COMPONENT_ID} showSuccess={false}>
            <div className="w-full h-full pb-6 max-w-9xl mx-auto pt-6 px-6">
              <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 md:pb-4">
                <div className="p-5 h-auto min-h-[200px] w-full bg-white rounded-xl shadow-md">
                  <div className="w-full h-full max-w-9xl">
                    <p className="text-gray-800 font-medium text-base text-center pb-2">
                      {t("pages.questions.info")}
                    </p>
                    <div className="pb-1">
                      <span className="text-gray-900 font-medium text-base">ID: </span>
                      <span className="text-gray-700 font-medium text-base">
                        {specificQuestion?.id}
                      </span>
                    </div>
                    <div className="pb-1">
                      <span className="text-gray-900 font-medium text-base">
                        {t("pages.questions.questionName")}
                      </span>
                      <span className="text-gray-700 font-medium text-base">
                        {specificQuestion?.name}
                      </span>
                    </div>
                    <div className="pb-1">
                      <span className="text-gray-900 font-medium text-base">
                        {t("pages.questions.projectName")}
                      </span>
                      <span className="text-gray-700 font-medium text-base">
                        {specificQuestion?.project?.name}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="p-5 h-auto min-h-[200px] w-full bg-white rounded-xl shadow-md">
                  <div className="w-full h-full max-w-9xl flex md:flex-row flex-col justify-center align-middle">
                    <button
                      className="btn-primary-fill bg-blue mb-1 mx-auto md:my-auto md:mr-1 md:ml-auto"
                      onClick={() =>
                        openInNewTab(
                          `https://docs.google.com/document/d/${specificQuestion.googledocouidshared}`,
                        )
                      }
                    >
                      {String(t("pages.questions.openNotesForClients"))}
                    </button>
                    <button
                      className="btn-secondary-fill mt-1 mx-auto md:my-auto md:ml-1 md:mr-auto"
                      onClick={() =>
                        openInNewTab(
                          `https://docs.google.com/document/d/${specificQuestion.googledocouid}`,
                        )
                      }
                    >
                      {String(t("pages.questions.openNotesForCoaches"))}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </IsLoading>
        </IsLoading>
      </IsLoading>
    </>
  );
};

export default SpecificQuestion;
