import { useSelector } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { RootState } from "../state/store";
import Router from "./Router";
import Translator from "./Translator";
import { useEffect } from "react";
import { setAuthToken } from "../util/axios";
import { isTokenExpired } from "../util/auth";
import { getCurrentJwt } from "../../features/auth/state/authSlice";

export default function App() {

  const { jwt } = useSelector((state: RootState) => {
    return {
      jwt: getCurrentJwt(state.auth),
    };
  });

  // Set JWT globally
  useEffect(() => {
    if (jwt && !isTokenExpired(jwt)) {
      setAuthToken(jwt);
      console.log("jwt", jwt);
    }
  }, [jwt]);

  return (
    <Translator>
      <BrowserRouter>
        <Router />
      </BrowserRouter>
    </Translator>
  );
}
