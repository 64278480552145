import { getGridStringOperators } from "@mui/x-data-grid";
import { Link } from "react-router-dom";

export const tracksColumns = [
  { field: "id", headerName: "ID", width: 95 },
  {
    field: "name",
    headerName: "Track name",
    minWidth: 200,
    flex: 1,
    renderCell: (params: any) => {
      if (!params.row.name || params.row.name === null) {
        return <Link to={`/tracks/${params.id}`}></Link>;
      } else {
        return (
          <Link to={`/tracks/${params.id}`}>
            <p>{params.row.name}</p>
          </Link>
        );
      }
    },
    valueGetter: (params: any) => {
      if (!params.row.name || params.row.name === null) {
        return null;
      } else {
        return params.row.name;
      }
    },
  },
];
