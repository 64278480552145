import React, { useEffect, useState } from "react";
import { IsLoading } from "../../../common/localComponents/AppState/IsLoading";
import { Input, DropdownWithNew, ButtonIcon, ToggleSwitch, Spinner } from "ui";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { AppDispatch } from "../../../common/state/store";
import { useDispatch, useSelector } from "react-redux";
import {
  generateAiQuestions,
  getCurrentOptionsOfTagsForAnswer,
  createEverythingAtOnce,
  addNewOption,
  generateAiAnswers,
  createLotsOfQuestionAtOnce,
} from "../../../features/questions/state/questionsSlice";
import { RootState } from "../../../common/state/store";
import { closeStandardModal } from "../../../common/state/slice/modal/modalSlice";
import { CREATE_QUESTION_COMPONENT_ID } from "../questions";

// Type for AnswerSet
type QuestionSetType = {
  question: string;
  validatedtagtriggerlist: string[];
  unvalidatedtagtriggerlist: string[];
  answers: [{ id: number; value: string; tagstounvalidate: string[]; tagstovalidate: string[] }];
  collapsed: boolean;
  loading: boolean;
};

type TFirstForm = {
  subject: string;
  behavior: string;
  target: string;
  moreDetails: string;
  number: string;
  typeOfQuestions: boolean;
  language: boolean;
};

export const SAVE_ALL_COMPONENT_ID = "saveAllComponent";

const AiGeneratedQuestions = () => {
  const { t } = useTranslation();
  const dispatch: AppDispatch = useDispatch();
  const [generatedQuestions, setGeneratedQuestions] = useState<QuestionSetType[]>([]);

  // const [answerSets, setAnswerSets] = useState<AnswerSetType[]>([{ index: 0, answerSer: [{ id: 0 }] }]);

  const { allOptionsOfTagForAnswer } = useSelector((state: RootState) => {
    return {
      allOptionsOfTagForAnswer: getCurrentOptionsOfTagsForAnswer(state),
    };
  });

  // Update both the local state and the Redux state when a new option is created
  const handleNewOptionCreated = (newOption: any) => {
    dispatch(addNewOption(newOption));
  };

  const {
    control,
    register,
    handleSubmit,
    unregister,
    setValue,
    watch,
    formState: { errors },
  } = useForm();

  // <TFirstForm>({
  //   defaultValues: {
  //     behavior: "senior Tech startup advisor",
  //     target: "first time CEO of a tech startup without any technical background",
  //   },
  // });

  const {
    control: controlGenerated,
    register: registerGenerated,
    handleSubmit: handleSubmitGenerated,
    unregister: unregisterGenerated,
    setValue: setValueGenerated,
    watch: watchGenerated,
    formState: { errors: errorsGenerated },
  } = useForm();

  const onSubmit = (data: any) => {
    console.log("data", data);

    dispatch(generateAiQuestions({ componentId: CREATE_QUESTION_COMPONENT_ID, data: data })).then(
      (response) => {
        console.log("response from generateAiQuestions", response.payload.questions);

        // map the response questions to required format
        const formattedQuestions = response.payload.questions.map((question: any) => {
          return {
            question: question,
            validatedtagtriggerlist: [],
            unvalidatedtagtriggerlist: [],
            answers: [],
            collapsed: true,
          };
        });

        // setting state with the new formatted questions
        setGeneratedQuestions(formattedQuestions);
      },
    );
  };

  const onSubmitGenerated = (data: any) => {
    console.log("Generated questions data", data);
  };

  const generateAnswers = (question: string, index: number) => {
    console.log("Question", question);
    console.log("Index", index);

    console.log("watch('subject')", watch("subject"));
    console.log("watch('target')", watch("target"));
    console.log("watch('behavior')", watch("behavior"));
    console.log("watch('language')", watch("language"));
    console.log("watch('number')", watch("number"));
    console.log("watch('typeOfQuestions')", watch("typeOfQuestions"));

    // Update the loading state here
    setGeneratedQuestions((prevQuestions) =>
      prevQuestions.map((q: any, qIndex: number) =>
        qIndex !== index
          ? q
          : {
              ...q,
              loading: true,
            },
      ),
    );

    const data = {
      question: question,
      subject: watch("subject"),
      target: watch("target"),
      behavior: watch("behavior"),
      language: watch("language"),
      number: watch("number"),
    };

    console.log("data", data);

    // dispatch async action to generate answers
    dispatch(generateAiAnswers({ data: data }))
      .then((response: any) => {
        // Use functional update here
        setGeneratedQuestions((prevQuestions) => {
          const questionsWithAnswers = [...prevQuestions];
          const mapedAnswers = response.payload.answers.map((answer: string, index: number) => {
            return {
              id: index,
              value: answer,
              tagstounvalidate: [],
              tagstovalidate: [],
            };
          });
          questionsWithAnswers[index].answers = mapedAnswers;
          questionsWithAnswers[index].loading = false;

          return questionsWithAnswers; // Return the updated state
        });
      })
      .catch((error: any) => {
        console.error("Error in generating answers:", error);
      });
  };

  const saveAllQuestions = () => {
    console.log("GeneratedQuestions", generatedQuestions);
    dispatch(
      createLotsOfQuestionAtOnce({
        data: generatedQuestions,
        componentId: SAVE_ALL_COMPONENT_ID,
      }),
    ).then(() => {
      dispatch(closeStandardModal("aiGeneratedQuestions"));
    });
  };

  return (
    <div className="h-full flex flex-col flex-grow">
      <IsLoading componentId={CREATE_QUESTION_COMPONENT_ID} showSuccess={true} />
      <IsLoading componentId={SAVE_ALL_COMPONENT_ID} showSuccess={true} />
      <header className="flex w-full bg-white shadow sticky top-0 z-10">
        <div className="max-w-screen-2xl py-6 px-8 overflow-y-auto">
          <h1 className="text-3xl font-bold text-black">
            {t("pages.questions.aiGeneratedQuestions.title")}
          </h1>
        </div>
      </header>
      <div className="h-full w-full py-6 px-6 flex flex-col gap-y-6 overflow-y-auto">
        <form
          className="flex justify-center items-center align-middle w-full"
          onSubmit={handleSubmit(onSubmit)}
        >
          <div className="bg-white w-full rounded-xl shadow-md border border-gray-200 py-6 px-4 ">
            <div className="grid w-full justify-center items-center grid-cols-1 lg:grid-cols-3 gap-x-0 gap-y-2 lg:gap-y-0 lg:gap-x-5">
              <Input
                registerFct={() => register("subject", { required: true })}
                type="text"
                label={t("pages.questions.aiGeneratedQuestions.subject")}
                style={{
                  border: errors.subject ? "1.5px solid rgb(239 68 68)" : "",
                }}
                placeholder={t("pages.questions.aiGeneratedQuestions.subjectPlaceholder")}
              />
              <Input
                registerFct={() =>
                  register("behavior", { required: true, value: "senior Tech startup advisor" })
                }
                type="text"
                label={t("pages.questions.aiGeneratedQuestions.behavior")}
                style={{
                  border: errors.behavior ? "1.5px solid rgb(239 68 68)" : "",
                }}
                placeholder={t("pages.questions.aiGeneratedQuestions.behaviorPlaceholder")}
              />
              <Input
                registerFct={() =>
                  register("target", {
                    required: true,
                    value: "first time CEO of a tech startup without any technical background",
                  })
                }
                type="text"
                label={t("pages.questions.aiGeneratedQuestions.target")}
                style={{
                  border: errors.target ? "1.5px solid rgb(239 68 68)" : "",
                }}
                placeholder={t("pages.questions.aiGeneratedQuestions.targetPlaceholder")}
              />
            </div>
            {generatedQuestions.length === 0 && (
              <div className="mt-1">
                <Input
                  registerFct={() => register("moreDetails")}
                  type="text"
                  label={t("pages.questions.aiGeneratedQuestions.moreDetails")}
                  placeholder={t("pages.questions.aiGeneratedQuestions.moreDetailsPlaceholder")}
                />
              </div>
            )}
            <div className="mt-1 grid w-full justify-center items-center grid-cols-1 lg:grid-cols-3 gap-x-0 gap-y-2 lg:gap-y-0 lg:gap-x-5">
              <Input
                registerFct={() => register("number", { required: true })}
                type="number"
                label={t("pages.questions.aiGeneratedQuestions.number")}
                style={{
                  border: errors.subject ? "1.5px solid rgb(239 68 68)" : "",
                }}
                placeholder={t("pages.questions.aiGeneratedQuestions.numberPlaceholder")}
              />
              {generatedQuestions.length === 0 && (
                <ToggleSwitch
                  id="typeOfQuestions"
                  label={t("pages.questions.aiGeneratedQuestions.typeOfQuestions")}
                  registerFct={() => register("typeOfQuestions")}
                  watchFct={() => watch("typeOfQuestions")}
                  dataYes="open"
                  dataNo="closed"
                />
              )}
              <ToggleSwitch
                id="language"
                label={t("pages.questions.aiGeneratedQuestions.language")}
                registerFct={() => register("language")}
                watchFct={() => watch("language")}
                dataYes="fr"
                dataNo="en"
              />
            </div>
            {generatedQuestions.length === 0 && (
              <div className="grid pt-3 justify-items-end">
                <button className="btn-secondary-fill text-black" type="submit">
                  Generate
                </button>
              </div>
            )}
          </div>
        </form>
        {generatedQuestions.length > 0 && (
          <form className="w-full flex-grow" onSubmit={handleSubmitGenerated(onSubmitGenerated)}>
            <div className="bg-white rounded-xl shadow-md border border-gray-200 py-6 px-4 ">
              <div className="flex w-full justify-center items-center gap-x-0 gap-y-2 lg:gap-y-0 lg:gap-x-5">
                <div className="flex flex-col justify-between items-center w-full">
                  {generatedQuestions.map(
                    (
                      field: {
                        question: string;
                        answers: any[];
                        collapsed: boolean;
                        loading: boolean;
                      },
                      index: any,
                    ) => (
                      <div className="w-full border-[1.5px] rounded-xl border-gray-300 p-4 mb-4">
                        <div
                          key={`question_${index}`}
                          className="grid grid-cols-1 lg:grid-cols-12 justify-between items-center w-full space-x-0 xl:space-x-5 space-y-2 xl:space-y-0"
                        >
                          <div className="col-span-1 lg:col-span-6 xl:col-span-8">
                            <Input
                              registerFct={() => registerGenerated(`question_${index}`)}
                              type="text"
                              label={`${index + 1} ${t(
                                "pages.questions.aiGeneratedQuestions.question",
                              )}`}
                              defaultValue={field.question}
                              placeholder={t(
                                "pages.questions.aiGeneratedQuestions.questionPlaceholder",
                              )}
                            />
                            <div className="grid grid-cols-2 space-x-4 mt-2">
                              <div className="">
                                <DropdownWithNew
                                  key={`validatedtagtriggerlist_q${index}`} // Added the key here
                                  className=""
                                  label="Validated Tag Trigger List:"
                                  name={`validatedtagtriggerlist_q${index}`}
                                  control={control}
                                  options={allOptionsOfTagForAnswer as any[]}
                                  onNewOptionCreated={handleNewOptionCreated}
                                  onSelectChange={(newSelectedOptions: any) => {
                                    console.log("newSelectedOptions", newSelectedOptions);
                                    const selectedIds = newSelectedOptions.map(
                                      (option: any) => option.value,
                                    ); // This will give you an array of the selected IDs only.
                                    setGeneratedQuestions((prevQuestions) =>
                                      prevQuestions.map((q: any, qIndex: number) =>
                                        qIndex !== index
                                          ? q
                                          : {
                                              ...q,
                                              validatedtagtriggerlist: selectedIds,
                                            },
                                      ),
                                    );
                                  }}
                                />
                              </div>
                              <div className="">
                                <DropdownWithNew
                                  key={`unvalidatedtagtriggerlist_q${index}`} // Added the key here
                                  className=""
                                  label="Unvalidated Tag Trigger List:"
                                  name={`unvalidatedtagtriggerlist_q${index}`}
                                  control={control}
                                  options={allOptionsOfTagForAnswer as any[]}
                                  onNewOptionCreated={handleNewOptionCreated}
                                  onSelectChange={(newSelectedOptions: any) => {
                                    console.log("newSelectedOptions", newSelectedOptions);
                                    const selectedIds = newSelectedOptions.map(
                                      (option: any) => option.value,
                                    ); // This will give you an array of the selected IDs only.
                                    setGeneratedQuestions((prevQuestions) =>
                                      prevQuestions.map((q: any, qIndex: number) =>
                                        qIndex !== index
                                          ? q
                                          : {
                                              ...q,
                                              unvalidatedtagtriggerlist: selectedIds,
                                            },
                                      ),
                                    );
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-span-1 lg:col-span-6 xl:col-span-4">
                            <div className="grid grid-cols-2 xl:grid-cols-12">
                              {field.answers.length === 0 && (
                                <div className="col-span-1 xl:col-span-4">
                                  <div className="flex justify-center items-center">
                                    <button
                                      type="button"
                                      className="btn-red-outline"
                                      onClick={() => {
                                        // remove question
                                        setGeneratedQuestions((prevQuestions) =>
                                          prevQuestions.filter(
                                            (q: any, qIndex: number) => qIndex !== index,
                                          ),
                                        );
                                      }}
                                    >
                                      {String(t("general.remove"))}
                                    </button>
                                  </div>
                                </div>
                              )}
                              {field.answers.length > 0 && (
                                <div className="col-span-1 xl:col-span-4">
                                  <div className="flex justify-center items-center">
                                    <ButtonIcon
                                      icon={field.collapsed ? "ChevronDownIcon" : "ChevronUpIcon"}
                                      classNameBtn={
                                        "w-10 h-10 bg-white rounded-full shadow-md border"
                                      }
                                      classNameIcon={"w-6 h-6"}
                                      onClick={() => {
                                        setGeneratedQuestions((prevQuestions) =>
                                          prevQuestions.map((q: any, qIndex: number) =>
                                            qIndex !== index
                                              ? q
                                              : {
                                                  ...q,
                                                  collapsed: !q.collapsed, // Toggle collapsed state
                                                },
                                          ),
                                        );
                                      }}
                                    />
                                  </div>
                                </div>
                              )}
                              <div className="col-span-1 xl:col-span-8">
                                <div className="flex justify-center items-center h-full w-full">
                                  {!field.loading ? (
                                    <button
                                      type="button"
                                      className="btn-secondary-fill text-black"
                                      onClick={() => {
                                        generateAnswers(watchGenerated(`question_${index}`), index);
                                      }}
                                    >
                                      Generate Answers
                                    </button>
                                  ) : (
                                    <Spinner relative={true} />
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        {field.answers.length > 0 && !field.collapsed && (
                          <div key={`answer_${index}`} className="w-full">
                            <div className="w-full border-[1.5px] rounded-xl border-gray-300 my-4">
                              {field.answers.map((answer: any) => (
                                <div
                                  className={
                                    `pt-4 mx-6 ` +
                                    (field.answers.length > 1 ? "pb-4" : "pb-7 xl:pb-4")
                                  }
                                >
                                  <div className="grid grid-cols-1 xl:grid-cols-12 items-center justify-center xl:justify-start">
                                    <div className="grid w-full col-span-11 justify-center items-center grid-cols-1 xl:grid-cols-3 xl:flex-row space-x-0 xl:space-x-5 space-y-2 xl:space-y-0">
                                      <div className="">
                                        <Input
                                          // registerFct={() =>
                                          //   registerGenerated(`answer_${answer.id}`, {
                                          //     required: true,
                                          //   })
                                          // }
                                          defaultValue={answer.value}
                                          type="text"
                                          label={t("pages.questions.addNewQuestion.answerLabel")}
                                          placeholder={`Potential answer for question`}
                                          onChange={(e) => {
                                            setGeneratedQuestions((prevQuestions) =>
                                              prevQuestions.map((q: any, qIndex: number) =>
                                                qIndex !== index
                                                  ? q
                                                  : {
                                                      ...q,
                                                      answers: q.answers.map((a: any) =>
                                                        a.id !== answer.id
                                                          ? a
                                                          : {
                                                              ...a,
                                                              value: e.target.value,
                                                            },
                                                      ),
                                                    },
                                              ),
                                            );
                                          }}
                                        />
                                      </div>
                                      <div className="">
                                        <DropdownWithNew
                                          key={`tagstovalidate_a${answer.id}_q${index}`} // Added the key here
                                          className=""
                                          label="Tags to validate:"
                                          name={`tagstovalidate_a${answer.id}_q${index}`}
                                          control={controlGenerated}
                                          options={allOptionsOfTagForAnswer as any[]}
                                          onNewOptionCreated={handleNewOptionCreated}
                                          onSelectChange={(newSelectedOptions: any) => {
                                            console.log("newSelectedOptions", newSelectedOptions);
                                            const selectedIds = newSelectedOptions.map(
                                              (option: any) => option.value,
                                            ); // This will give you an array of the selected IDs only.
                                            setGeneratedQuestions((prevQuestions) =>
                                              prevQuestions.map((q: any, qIndex: number) =>
                                                qIndex !== index
                                                  ? q
                                                  : {
                                                      ...q,
                                                      answers: q.answers.map((a: any) =>
                                                        a.id !== answer.id
                                                          ? a
                                                          : {
                                                              ...a,
                                                              tagstovalidate: selectedIds, // Use selectedIds here
                                                            },
                                                      ),
                                                    },
                                              ),
                                            );
                                          }}
                                        />
                                      </div>
                                      <div className="">
                                        <DropdownWithNew
                                          key={`tagstounvalidate_a${answer.id}_q${index}`} // Added the key here
                                          className=""
                                          label="Tags to unvalidate:"
                                          name={`tagstounvalidate_a${answer.id}_q${index}`}
                                          control={controlGenerated}
                                          options={allOptionsOfTagForAnswer as any[]}
                                          onNewOptionCreated={handleNewOptionCreated}
                                          onSelectChange={(newSelectedOptions: any) => {
                                            console.log("newSelectedOptions", newSelectedOptions);
                                            const selectedIds = newSelectedOptions.map(
                                              (option: any) => option.value,
                                            ); // This will give you an array of the selected IDs only.
                                            setGeneratedQuestions((prevQuestions) =>
                                              prevQuestions.map((q: any, qIndex: number) =>
                                                qIndex !== index
                                                  ? q
                                                  : {
                                                      ...q,
                                                      answers: q.answers.map((a: any) =>
                                                        a.id !== answer.id
                                                          ? a
                                                          : {
                                                              ...a,
                                                              tagstounvalidate: selectedIds, // Use selectedIds here
                                                            },
                                                      ),
                                                    },
                                              ),
                                            );
                                          }}
                                        />
                                      </div>
                                    </div>
                                    {field.answers.length > 1 && (
                                      <div className="col-span-1 justify-self-center mt-4 xl:ml-4 ml-0 xl:mb-2 mb-0">
                                        <ButtonIcon
                                          icon={"MinusSmallIcon"}
                                          classNameBtn={
                                            "w-10 h-10 bg-white rounded-full shadow-md border"
                                          }
                                          classNameIcon={"w-6 h-6"}
                                          onClick={() => {
                                            unregister(`answer_${answer.id}`);
                                            unregister(`tagstovalidate_a${answer.id}_q${index}`);
                                            unregister(`tagstounvalidate_a${answer.id}_q${index}`);

                                            setGeneratedQuestions((prevQuestions) =>
                                              prevQuestions.map((q: any, qIndex: number) =>
                                                qIndex !== index
                                                  ? q
                                                  : {
                                                      ...q,
                                                      answers: q.answers.filter(
                                                        (a: any) => a.id !== answer.id,
                                                      ),
                                                    },
                                              ),
                                            );
                                          }}
                                        />
                                      </div>
                                    )}
                                  </div>
                                </div>
                              ))}
                              <div className="py-4 mx-auto">
                                <div className="flex items-center justify-center">
                                  <ButtonIcon
                                    icon={"PlusSmallIcon"}
                                    classNameBtn={
                                      "w-10 h-10 bg-white rounded-full shadow-md border"
                                    }
                                    classNameIcon={"w-6 h-6"}
                                    onClick={() => {
                                      const maxId =
                                        generatedQuestions.flatMap((q) => q.answers).length > 0
                                          ? Math.max(
                                              ...generatedQuestions
                                                .flatMap((q) => q.answers)
                                                .map((a) => a.id),
                                            )
                                          : -1;

                                      setGeneratedQuestions((prevQuestions: any) =>
                                        prevQuestions.map((q: any, qIndex: number) =>
                                          qIndex !== index
                                            ? q
                                            : {
                                                ...q,
                                                answers: [
                                                  ...q.answers,
                                                  { id: maxId + 1, value: "" },
                                                ],
                                              },
                                        ),
                                      );

                                      setValue(`tagstovalidate_${maxId + 1}`, []);
                                      setValue(`tagstounvalidate_${maxId + 1}`, []);
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    ),
                  )}
                </div>
              </div>
            </div>
          </form>
        )}
      </div>
      {generatedQuestions.length > 0 && (
        <div className="flex w-full bg-white shadow-2xl border-t border-gray-300 sticky bottom-0 left-0 z-20 py-6 px-8">
          <div className="flex justify-end w-full items-center">
            <button
              type="button"
              className="btn-secondary-fill text-black"
              onClick={() => {
                saveAllQuestions();
              }}
            >
              Save all questions
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default AiGeneratedQuestions;
