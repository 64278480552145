import { Outlet } from "react-router-dom";
import Sidebar from "../localComponents/Sidebar/Sidebar";
import { PrivatePage } from "./PrivatePage";

const Layout = () => {
  return (
    <PrivatePage>
      <div className="fixed flex w-full min-h-screen bg-slate-100">
        <Sidebar />
        <main className="relative flex flex-col w-full h-screen overflow-y-auto">
          <Outlet />
        </main>
      </div>
    </PrivatePage>
  );
};

export default Layout;
