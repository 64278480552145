import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useAppDispatch } from "../../common/state/hooks";
import { IsLoading } from "../../common/localComponents/AppState/IsLoading";
import { RootState } from "../../common/state/store";
import {
  getCurrentSmListOfZoomMeetings,
  getSmListOfZoomMeetings,
} from "../../features/zoomMeetings/state/zoomMeetingsSlice";
import { meetingsColumns } from "../ZoomMeetings/meetings-columns";
import { DataGrid, GridColDef, GridRowsProp } from "@mui/x-data-grid";
import { DataGridComponent } from "ui";

export const DASHBOARD_MEETINGS_COMPONENT_ID = "dashboardMeetingsComponent";

const SmZoomMeetingsDataTable = () => {
  const dispatch = useAppDispatch();

  const [pageSize, setPageSize] = useState(10);

  const { smListOfMeetings } = useSelector((state: RootState) => {
    return {
      smListOfMeetings: getCurrentSmListOfZoomMeetings(state),
    };
  });

  useEffect(() => {
    console.log("smListOfMeetings: ", smListOfMeetings);
    if (!smListOfMeetings || smListOfMeetings?.length === 0) {
      dispatch(getSmListOfZoomMeetings({ componentId: DASHBOARD_MEETINGS_COMPONENT_ID }));
    }
  }, []);

  return (
    <>
      <IsLoading componentId={DASHBOARD_MEETINGS_COMPONENT_ID} showSuccess={false}>
        <div className="my-6 p-5 h-[30rem] w-full bg-white rounded-xl shadow-md">
          <div className="w-full h-full max-w-9xl">
            <DataGridComponent
              className="bg-white rounded-xl"
              rows={smListOfMeetings as GridRowsProp}
              columns={meetingsColumns as GridColDef[]}
            />
          </div>
        </div>
      </IsLoading>
    </>
  );
};

export default SmZoomMeetingsDataTable;
