import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import apiUrls from "../../../api";
import { IAppLoaderAction } from "../../../common/state/loaderHandleMiddleware";
import { RootState } from "../../../common/state/store";

export interface ITracks {
  fullListOfTracks?: any[];
  smListOfTracks?: any[];
  specificTrack: any;
  projectsThatAreNotInTheTrack: any[];
  groupsThatAreNotInTheTrack: any[];
}

export const getSmListOfTracks = createAsyncThunk(
  "tracks/getSmListOfTracks",
  async (componentId: IAppLoaderAction) => {
    let response;

    await axios
      .get(`${apiUrls.tracks.get}?_sort=id:DESC&_limit=100`, {})
      .then((res) => {
        response = res.data;
      })
      .catch((e) => {
        console.error(e);
        return e;
      });

    return response;
  },
);

export const getFullListOfTracks = createAsyncThunk(
  "tracks/getFullListOfTracks",
  async (componentId: IAppLoaderAction) => {
    let response;
    await axios
      .get(`${apiUrls.tracks.get}?_sort=id:DESC&_limit=-1`)
      .then((res) => {
        response = res.data;
        console.log("response", response);
        console.log("res.data", res.data);
      })
      .catch((e) => {
        console.error(e);
        return e;
      });

    return response;
  },
);

export const getSpecificTrack = createAsyncThunk(
  "tracks/getSpecificTrack",
  async ({ idOfTrack }: { idOfTrack: string } & IAppLoaderAction) => {
    let response;
    await axios
      .get(`${apiUrls.tracks.get}/${idOfTrack}`)
      .then((res) => {
        response = res.data;
      })
      .catch((e) => {
        console.error(e);
      });

    return response;
  },
);

export const updateSpecificTrack = createAsyncThunk(
  "tracks/updateSpecificTrack",
  async ({ idOfTrack, data }: { idOfTrack: string; data: any } & IAppLoaderAction) => {
    let response;
    console.log(data);
    await axios
      .put(`${apiUrls.tracks.get}/${idOfTrack}`, { ...data })
      .then((res) => {
        console.log(data);
        console.log(res.data);
        response = res.data;
      })
      .catch((e) => console.error(e));

    return response;
  },
);


export const generateAiScripts = createAsyncThunk(
  "tracks/generateAiScripts",
  async ({ data }: { data: any } & IAppLoaderAction) => {
    let response: any;

    console.log("data from generateAiScripts: ", data);

    if(data.language === false) {
      data.language = "english";
    } 
    else {
      data.language = "french";
    }

    console.log("edited data form generateAiScripts: ", data);

    await axios
      .post("https://aiapi-test.myctofriend.co/createTrackScripts/", data)
      .then((res) => {
        response = res.data;
      })
      .catch((e) => {
        console.error(e);
      });

      console.log("response from generateAiQuestions: ", response);
      return response;
  },
);

const initialState: ITracks = {
  fullListOfTracks: [],
  smListOfTracks: [],
  specificTrack: {},
  projectsThatAreNotInTheTrack: [],
  groupsThatAreNotInTheTrack: [],
};

export const tracksSlice = createSlice({
  name: "tracks",
  initialState,
  reducers: {
    clearAllTracks: (state: ITracks) => {
      state.fullListOfTracks = [];
    },
    updateFullListOfTracks: (state: ITracks, { payload }) => {
      if (state.fullListOfTracks?.findIndex((x) => x.id == payload.id) !== undefined) {
        state.fullListOfTracks[state.fullListOfTracks?.findIndex((x) => x.id == payload.id) as any] =
          payload;
      }
    },
    updateSmListOfTracks: (state: ITracks, { payload }) => {
      if (state.smListOfTracks?.findIndex((x) => x.id == payload.id) !== undefined) {
        state.smListOfTracks[state.smListOfTracks?.findIndex((x) => x.id == payload.id) as any] =
          payload;
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getSmListOfTracks.fulfilled, (state: ITracks, { payload }) => {
      state.smListOfTracks = payload;
    });
    builder.addCase(getFullListOfTracks.fulfilled, (state: ITracks, { payload }) => {
      state.fullListOfTracks = payload;
    });
    builder.addCase(getSpecificTrack.fulfilled, (state: ITracks, { payload }) => {
      state.specificTrack = payload;
    });
    builder.addCase(updateSpecificTrack.fulfilled, (state: ITracks, action) => {
      state.specificTrack = action.payload;
      tracksSlice.caseReducers.updateFullListOfTracks(state, action);
      tracksSlice.caseReducers.updateSmListOfTracks(state, action);
    });
  },
});

export const tracksReducer = tracksSlice.reducer;

export const getCurrentFullListOfTracks = (state: RootState) => state.tracks.fullListOfTracks;
export const getCurrentSmListOfTracks = (state: RootState) => state.tracks.smListOfTracks;
export const getCurrentSpecificTrack = (state: RootState) => state.tracks.specificTrack;

export const { clearAllTracks } = tracksSlice.actions;
