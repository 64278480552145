import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import apiUrls from "../../../api";
import { IAppLoaderAction } from "../../../common/state/loaderHandleMiddleware";
import { RootState } from "../../../common/state/store";

export interface IPools {
  fullListOfPools?: any[];
  smListOfPools?: any[];
  specificPool: any;
  projectsInTheRightFormat: any[];
  usersInTheRightFormat: any[];
}

export const getSmListOfPools = createAsyncThunk(
  "pools/getSmListOfPools",
  async (componentId: IAppLoaderAction) => {
    let response;

    await axios
      .get(`${apiUrls.pools.findAll}?_sort=id:DESC&_limit=100`, {})
      .then((res) => {
        response = res.data;
      })
      .catch((e) => {
        console.error(e);
        return e;
      });

    return response;
  },
);

export const getFullListOfPools = createAsyncThunk(
  "pools/getFullListOfPools",
  async (componentId: IAppLoaderAction) => {
    let response;
    await axios
      .post(`${apiUrls.pools.findAll}?_sort=id:DESC&_limit=-1`)
      .then((res) => {
        response = res.data;
        console.log("response", response);
        console.log("res.data", res.data);
      })
      .catch((e) => {
        console.error(e);
        return e;
      });

    return response;
  },
);

export const getSpecificPool = createAsyncThunk(
  "pools/getSpecificPool",
  async ({ idOfPool }: { idOfPool: string } & IAppLoaderAction) => {
    let response;
    await axios
      .get(`${apiUrls.pools.get}/${idOfPool}`)
      .then((res) => {
        response = res.data;
      })
      .catch((e) => {
        console.error(e);
      });

    return response;
  },
);

export const updateSpecificPool = createAsyncThunk(
  "pools/updateSpecificPool",
  async ({ idOfPool, data }: { idOfPool: string; data: any } & IAppLoaderAction) => {
    let response;
    console.log("data", data);
    await axios
      .put(`${apiUrls.pools.get}/${idOfPool}`, { ...data })
      .then((res) => {
        console.log(data);
        console.log(res.data);
        response = res.data;
      })
      .catch((e) => console.error(e));

    return response;
  },
);

export const getUsersInTheRightFormat = createAsyncThunk(
  "pools/getUsersInTheRightFormat",
  async ({ fullListOfUsers }: { fullListOfUsers: any[] } & IAppLoaderAction) => {
    console.log("fullListOfUsers", fullListOfUsers);

    const optionsNeeded = fullListOfUsers.map((x: any) => ({
      value: x.id,
      optionText: x.email !== null ? x.email : `ID: ${x.id}`,
    }));

    return optionsNeeded as any;
  },
);

export const getProjectsInTheRightFormat = createAsyncThunk(
  "pools/getProjectsInTheRightFormat",
  async ({ fullListOfProjects }: { fullListOfProjects: any[] } & IAppLoaderAction) => {
    console.log("fullListOfProjects", fullListOfProjects);

    const optionsNeeded = fullListOfProjects.map(
      (x: any) => (
        console.log("x", x),
        {
          value: x.id,
          optionText: x.name !== null ? x.name : `ID: ${x.id}`,
        }
      ),
    );

    return optionsNeeded as any;
  },
);

const initialState: IPools = {
  fullListOfPools: [],
  smListOfPools: [],
  specificPool: {},
  projectsInTheRightFormat: [],
  usersInTheRightFormat: [],
};

export const poolsSlice = createSlice({
  name: "pools",
  initialState,
  reducers: {
    clearAllPools: (state: IPools) => {
      state.fullListOfPools = [];
    },
    updateFullListOfPools: (state: IPools, { payload }) => {
      if (state.fullListOfPools?.findIndex((x) => x.id == payload.id) !== undefined) {
        state.fullListOfPools[state.fullListOfPools?.findIndex((x) => x.id == payload.id) as any] =
          payload;
      }
    },
    updateSmListOfPools: (state: IPools, { payload }) => {
      if (state.smListOfPools?.findIndex((x) => x.id == payload.id) !== undefined) {
        state.smListOfPools[state.smListOfPools?.findIndex((x) => x.id == payload.id) as any] =
          payload;
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getSmListOfPools.fulfilled, (state: IPools, { payload }) => {
      state.smListOfPools = payload;
    });
    builder.addCase(getFullListOfPools.fulfilled, (state: IPools, { payload }) => {
      state.fullListOfPools = payload;
    });
    builder.addCase(getSpecificPool.fulfilled, (state: IPools, { payload }) => {
      state.specificPool = payload;
    });
    builder.addCase(updateSpecificPool.fulfilled, (state: IPools, action) => {
      state.specificPool = action.payload;
      poolsSlice.caseReducers.updateFullListOfPools(state, action);
      poolsSlice.caseReducers.updateSmListOfPools(state, action);
    });
    builder.addCase(getUsersInTheRightFormat.fulfilled, (state: IPools, { payload }: any) => {
      state.usersInTheRightFormat = payload;
    });
    builder.addCase(getProjectsInTheRightFormat.fulfilled, (state: IPools, { payload }: any) => {
      state.projectsInTheRightFormat = payload;
    });
  },
});

export const poolsReducer = poolsSlice.reducer;

export const getCurrentFullListOfPools = (state: RootState) => state.pools.fullListOfPools;
export const getCurrentSmListOfPools = (state: RootState) => state.pools.smListOfPools;
export const getCurrentSpecificPool = (state: RootState) => state.pools.specificPool;
export const getCurrentProjectsInTheRightFormat = (state: RootState) =>
  state.pools.projectsInTheRightFormat;
export const getCurrentUsersInTheRightFormat = (state: RootState) =>
  state.pools.usersInTheRightFormat;

export const { clearAllPools } = poolsSlice.actions;
