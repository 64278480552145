import React, { useEffect, useState } from "react";
import { IsLoading } from "../../../common/localComponents/AppState/IsLoading";
import { Input, DropdownWithNew, ButtonIcon, ToggleSwitch, Spinner } from "ui";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { AppDispatch } from "../../../common/state/store";
import { useDispatch, useSelector } from "react-redux";
import { generateAiScripts } from "../../../features/tracks/state/tracksSlice";
import { RootState } from "../../../common/state/store";
import { closeStandardModal } from "../../../common/state/slice/modal/modalSlice";

// Type for AnswerSet
type QuestionSetType = {
  question: string;
  validatedtagtriggerlist: string[];
  unvalidatedtagtriggerlist: string[];
  answers: [{ id: number; value: string; tagstounvalidate: string[]; tagstovalidate: string[] }];
  collapsed: boolean;
  loading: boolean;
};

export const SAVE_ALL_COMPONENT_ID = "saveAllComponent";
export const CREATE_SCRIPTS_COMPONENT_ID = "createScriptsComponent";

const AiGeneratedScripts = () => {
  const { t } = useTranslation();
  const dispatch: AppDispatch = useDispatch();
  const [generatedQuestions, setGeneratedQuestions] = useState<QuestionSetType[]>([]);

  const {
    control,
    register,
    handleSubmit,
    unregister,
    setValue,
    watch,
    formState: { errors },
  } = useForm();

  const onSubmit = (data: any) => {
    console.log("data", data);

    dispatch(generateAiScripts({ componentId: CREATE_SCRIPTS_COMPONENT_ID, data: data }));
  };

  return (
    <div className="h-full flex flex-col flex-grow">
      <IsLoading componentId={CREATE_SCRIPTS_COMPONENT_ID} showSuccess={true} />
      <IsLoading componentId={SAVE_ALL_COMPONENT_ID} showSuccess={true} />
      <header className="flex w-full bg-white shadow sticky top-0 z-10">
        <div className="max-w-screen-2xl py-6 px-8 overflow-y-auto">
          <h1 className="text-3xl font-bold text-black">
            {t("pages.tracks.aiGeneratedScripts.title")}
          </h1>
        </div>
      </header>
      <div className="h-full w-full py-6 px-6 flex flex-col gap-y-6 overflow-y-auto">
        <form
          className="flex justify-center items-center align-middle w-full"
          onSubmit={handleSubmit(onSubmit)}
        >
          <div className="bg-white w-full rounded-xl shadow-md border border-gray-200 py-6 px-4 ">
            <div className="grid w-full justify-center items-center grid-cols-1 lg:grid-cols-3 gap-x-0 gap-y-2 lg:gap-y-0 lg:gap-x-5">
              <div className="col-span-2">
                <Input
                  registerFct={() => register("subject", { required: true })}
                  type="text"
                  label={t("pages.tracks.aiGeneratedScripts.subject")}
                  style={{
                    border: errors.subject ? "1.5px solid rgb(239 68 68)" : "",
                  }}
                  placeholder={t("pages.tracks.aiGeneratedScripts.subjectPlaceholder")}
                />
              </div>
              <Input
                registerFct={() =>
                  register("target", {
                    required: true,
                    value: "first time CEO of a tech startup without any technical background",
                  })
                }
                type="text"
                label={t("pages.tracks.aiGeneratedScripts.target")}
                style={{
                  border: errors.target ? "1.5px solid rgb(239 68 68)" : "",
                }}
                placeholder={t("pages.tracks.aiGeneratedScripts.targetPlaceholder")}
              />
            </div>
            {generatedQuestions.length === 0 && (
              <div className="mt-1">
                <Input
                  registerFct={() => register("moreDetails")}
                  type="text"
                  label={t("pages.tracks.aiGeneratedScripts.moreDetails")}
                  placeholder={t("pages.tracks.aiGeneratedScripts.moreDetailsPlaceholder")}
                />
              </div>
            )}
            <div className="mt-1 grid w-full justify-center items-center grid-cols-1 lg:grid-cols-3 gap-x-0 gap-y-2 lg:gap-y-0 lg:gap-x-5">
              <Input
                registerFct={() => register("number", { required: true })}
                type="number"
                label={t("pages.tracks.aiGeneratedScripts.number")}
                style={{
                  border: errors.subject ? "1.5px solid rgb(239 68 68)" : "",
                }}
                placeholder={t("pages.tracks.aiGeneratedScripts.numberPlaceholder")}
              />
              <ToggleSwitch
                id="language"
                label={t("pages.tracks.aiGeneratedScripts.language")}
                registerFct={() => register("language")}
                watchFct={() => watch("language")}
                dataYes="fr"
                dataNo="en"
              />
            </div>
            {generatedQuestions.length === 0 && (
              <div className="grid pt-3 justify-items-end">
                <button className="btn-secondary-fill text-black" type="submit">
                  Generate
                </button>
              </div>
            )}
          </div>
        </form>
      </div>
    </div>
  );
};

export default AiGeneratedScripts;
