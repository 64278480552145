import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import apiUrls from "../../../api";
import { IAppLoaderAction } from "../../../common/state/loaderHandleMiddleware";
import { RootState } from "../../../common/state/store";

export interface IProjects {
  fullListOfProjects?: any[];
  smListOfProjects?: any[];
  specificProject: any;
  usersThatAreNotInTheProject: any[];
  groupsThatAreNotInTheProject: any[];
}

export const getSmListOfProjects = createAsyncThunk(
  "projects/getSmListOfProjects",
  async (componentId: IAppLoaderAction) => {
    let response;

    await axios
      .get(`${apiUrls.projects.get}?_sort=id:DESC&_limit=100`, {})
      .then((res) => {
        response = res.data;
      })
      .catch((e) => {
        console.error(e);
        return e;
      });

    return response;
  },
);

export const getFullListOfProjects = createAsyncThunk(
  "projects/getFullListOfProjects",
  async (componentId: IAppLoaderAction) => {
    let response;
    await axios
      .get(`${apiUrls.projects.get}?_sort=id:DESC&_limit=-1`)
      .then((res) => {
        response = res.data;
      })
      .catch((e) => {
        console.error(e);
        return e;
      });

    return response;
  },
);

export const getSpecificProject = createAsyncThunk(
  "projects/getSpecificProject",
  async ({ idOfProject }: { idOfProject: string } & IAppLoaderAction) => {
    let response;

    await axios
      .get(`${apiUrls.projects.get}/${idOfProject}`)
      .then((res) => {
        response = res.data;
      })
      .catch((e) => {
        console.error(e);
      });

    return response;
  },
);

export const updateSpecificProject = createAsyncThunk(
  "projects/updateSpecificProject",
  async ({ idOfProject, data }: { idOfProject: string; data: any } & IAppLoaderAction) => {
    let response;
    console.log(data);
    await axios
      .put(`${apiUrls.projects.get}/${idOfProject}`, { ...data })
      .then((res) => {
        console.log(data);
        console.log(res.data);
        response = res.data;
      })
      .catch((e) => console.error(e));

    return response;
  },
);

export const getGroupsThatAreNotInTheProject = createAsyncThunk(
  "projects/getGroupsThatAreNotInTheProject",
  async ({
    specificProject,
    fullListOfGroups,
  }: { specificProject: any; fullListOfGroups: any[] } & IAppLoaderAction) => {
    console.log("specificProject", specificProject);
    console.log("fullListOfGroups", fullListOfGroups);

    if (specificProject?.groups?.length !== 0) {
      const arrOfExistedGroups = [];

      for (let i = 0; i < specificProject.groups.length; i++) {
        arrOfExistedGroups.push(specificProject.groups[i].id);
      }

      let objWithGroupsNotExisted: any = fullListOfGroups;

      arrOfExistedGroups.forEach((id) => {
        if (objWithGroupsNotExisted != undefined) {
          objWithGroupsNotExisted = objWithGroupsNotExisted.filter(
            (element: any) => element.id !== id,
          );
        }
      });

      const optionsNeeded = objWithGroupsNotExisted.map((x: any) => ({
        value: x.id,
        label: x.name !== null ? x.name : `ID: ${x.id}`,
      }));

      return optionsNeeded;
    } else {
      const optionsNeeded = fullListOfGroups.map((x: any) => ({
        value: x.id,
        label: x.name !== null ? x.name : `ID: ${x.id}`,
      }));

      return optionsNeeded as any;
    }
  },
);

const initialState: IProjects = {
  fullListOfProjects: [],
  smListOfProjects: [],
  specificProject: {},
  usersThatAreNotInTheProject: [{ value: "", optionText: "" }],
  groupsThatAreNotInTheProject: [],
};

export const projectsSlice = createSlice({
  name: "projects",
  initialState,
  reducers: {
    clearAllProjects: (state: IProjects) => {
      state.fullListOfProjects = [];
    },
    updateFullListOfProjects: (state: IProjects, { payload }) => {
      if (state.fullListOfProjects?.findIndex((x) => x.id == payload.id) !== undefined) {
        state.fullListOfProjects[
          state.fullListOfProjects?.findIndex((x) => x.id == payload.id) as any
        ] = payload;
      }
    },
    updateSmListOfProjects: (state: IProjects, { payload }) => {
      if (state.smListOfProjects?.findIndex((x) => x.id == payload.id) !== undefined) {
        state.smListOfProjects[
          state.smListOfProjects?.findIndex((x) => x.id == payload.id) as any
        ] = payload;
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getSmListOfProjects.fulfilled, (state: IProjects, { payload }) => {
      state.smListOfProjects = payload;
    });
    builder.addCase(getFullListOfProjects.fulfilled, (state: IProjects, { payload }) => {
      state.fullListOfProjects = payload;
    });
    builder.addCase(getSpecificProject.fulfilled, (state: IProjects, { payload }: any) => {
      state.specificProject = payload;
    });
    builder.addCase(updateSpecificProject.fulfilled, (state: IProjects, action) => {
      state.specificProject = action.payload;
      projectsSlice.caseReducers.updateFullListOfProjects(state, action);
      projectsSlice.caseReducers.updateSmListOfProjects(state, action);
    });
    builder.addCase(
      getGroupsThatAreNotInTheProject.fulfilled,
      (state: IProjects, { payload }: any) => {
        state.groupsThatAreNotInTheProject = payload;
      },
    );
  },
});

export const projectsReducer = projectsSlice.reducer;

export const getCurrentFullListOfProjects = (state: RootState) => state.projects.fullListOfProjects;
export const getCurrentSmListOfProjects = (state: RootState) => state.projects.smListOfProjects;
export const getCurrentSpecificProject = (state: RootState) => state.projects.specificProject;
export const getCurrentUsersThatAreNotInTheProject = (state: RootState) =>
  state.projects.usersThatAreNotInTheProject;
export const getCurrentGroupsThatAreNotInTheProject = (state: RootState) =>
  state.projects.groupsThatAreNotInTheProject;
export const { clearAllProjects } = projectsSlice.actions;
