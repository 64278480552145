import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../common/state/store";
import { DataGrid, GridRowsProp, GridColDef } from "@mui/x-data-grid";
import { IsLoading } from "../../common/localComponents/AppState/IsLoading";
import { tracksColumns } from "./tracks-columns";
import { Link } from "react-router-dom";
import {
  getCurrentFullListOfTracks,
  getFullListOfTracks,
} from "../../features/tracks/state/tracksSlice";
import { ButtonIcon, DataGridComponent } from "ui";
import { openStandardModal } from "../../common/state/slice/modal/modalSlice";
import ModalStandard from "../../common/localComponents/Modal/ModalStandard";
import AiGeneratedScripts from "./pageComponents/ai-generated-scripts";

export const USERS_COMPONENT_ID = "tracksComponent";

const Tracks = () => {
  const { t } = useTranslation();

  const dispatch: AppDispatch = useDispatch();

  const [pageSize, setPageSize] = useState(20);

  const [columnVisibilityModel, setColumnVisibilityModel] = useState({
    firstname: false,
    lastname: false,
  });

  const { fullListOfTracks } = useSelector((state: RootState) => {
    return {
      fullListOfTracks: getCurrentFullListOfTracks(state),
    };
  });

  useEffect(() => {
    if (!fullListOfTracks || fullListOfTracks?.length === 0) {
      dispatch(getFullListOfTracks({ componentId: USERS_COMPONENT_ID }));
    }
  }, []);

  const actionColumn = [
    {
      field: "action",
      headerName: "Action",
      flex: 1,
      sortable: false,
      filterable: false,
      renderCell: (params: any) => {
        return (
          <>
            <Link to={`/tracks/${params.id}`}>
              <button className="btn-primary-fill bg-blue mr-2">{String(t("general.open"))}</button>
            </Link>
          </>
        );
      },
    },
  ];

  return (
    <>
      <header className="flex w-full bg-white shadow  sticky top-0 z-10">
        <div className="max-w-screen-2xl py-6 px-8 overflow-y-auto">
          <h1 className="text-3xl font-bold text-black">{t("pages.tracks.title")}</h1>
        </div>
        <div className="flex justify-end align-middle ml-auto my-auto mr-8">
          <div className="mr-4">
            <ButtonIcon
              icon={"PlusSmallIcon"}
              classNameBtn={"w-14 h-14 bg-white rounded-full shadow-md border"}
              classNameIcon={"w-10 h-10"}
              onClick={() => {
                dispatch(openStandardModal("createScript"));
              }}
            />
          </div>
          <div>
            <ButtonIcon
              icon={"ArrowPathIcon"}
              classNameBtn={"w-14 h-14 bg-white rounded-full shadow-md border"}
              classNameIcon={"w-10 h-10"}
              onClick={() => {
                dispatch(getFullListOfTracks({ componentId: USERS_COMPONENT_ID }));
              }}
            />
          </div>
        </div>
      </header>
      <ModalStandard id="createScript" fullScreen={true}>
        <AiGeneratedScripts />
      </ModalStandard>
      <div className="w-full h-full pb-6 max-w-9xl mx-auto pt-6 px-6">
        <IsLoading componentId={USERS_COMPONENT_ID} showSuccess={false}>
          <DataGridComponent
            className="bg-white rounded-xl shadow-md"
            rows={fullListOfTracks as GridRowsProp}
            columns={tracksColumns.concat(actionColumn as any) as GridColDef[]}
            columnVisibilityModel={columnVisibilityModel}
          />
        </IsLoading>
      </div>
    </>
  );
};

export default Tracks;
