import { getGridStringOperators } from "@mui/x-data-grid";
import { Link } from "react-router-dom";

export const questionsColumns = [
  { field: "id", headerName: "ID", width: 75 },
  {
    field: "name",
    headerName: "Question",
    minWidth: 300,
    flex: 2,
    renderCell: (params: any) => {
      const onClickEdit = (e: any) => {
        e.stopPropagation(); // don't select this row after clicking
      };
      return (
        <Link to={`/questions/${params.id}`}>
          <p>{params.row.name}</p>
        </Link>
      );
    },
    valueGetter: (params: any) => {
      return params.row.name;
    },
  },
  {
    field: "answers",
    headerName: "Answers",
    type: "string",
    width: 90,
    flex: 0,
    renderCell: (params: any) => {
      const onClickEdit = (e: any) => {
        e.stopPropagation(); // don't select this row after clicking
      };

      if (!params.row.answers || params.row.answers === null || params.row.answers.length === 0) {
        return null;
      } else {
        const arr = Object.values(params.row.answers);

        const numOfAnswers = arr.length;

        const arrNew = [];
        for (let i = 0; i < numOfAnswers; i++) {
          arrNew.push(params.row.answers[i]?.name);
        }

        return (
          <div>
            <select
              style={{ width: `${70}px` }}
              defaultValue={numOfAnswers}
              placeholder={String(numOfAnswers)}
              onClick={onClickEdit}
            >
              <option disabled hidden>
                {numOfAnswers}
              </option>
              {arrNew.map((value, index) => (
                <option disabled key={index} value={value}>
                  {value}
                </option>
              ))}
            </select>
          </div>
        );
      }
    },
    valueGetter: (params: any) => {
      if (!params.row.answers || params.row.answers === null || params.row.answers.length === 0) {
        return null;
      } else if (params.row.answers.length === 1) {
        return params.row.answers[0]?.name;
      } else {
        const arr = Object.values(params.row.answers);

        const numOfAnswers = arr.length;

        let strNew = "";
        for (let i = 0; i < numOfAnswers; i++) {
          strNew += ` ${params.row.answers[i]?.name}`;
        }

        return strNew;
      }
    },
    sortComparator: (v1: any, v2: any, sort: any) => {
      if (v1 === null && v2 === null) return 0;
      else if (v1 === null) return 1;
      else if (v2 === null) return -1;
      else return `${v1}`.localeCompare(`${v2}`);
    },
    filterOperators: getGridStringOperators().filter(
      (operator) =>
        operator.value === "contains" ||
        operator.value === "isEmpty" ||
        operator.value === "isNotEmpty",
    ),
  },
  {
    field: "validatedtagtriggerlist",
    headerName: "Validated Tag Trigger List",
    type: "string",
    minWidth: 150,
    flex: 1,
    renderCell: (params: any) => {
      const onClickEdit = (e: any) => {
        e.stopPropagation(); // don't select this row after clicking
      };

      if (!params.row.validatedtagtriggerlist || params.row.validatedtagtriggerlist === null || params.row.validatedtagtriggerlist.length === 0) {
        return null;
      } else if (params.row.validatedtagtriggerlist.length === 1) {
        return params.row.validatedtagtriggerlist[0]?.hashtag;
      } else {
        const arr = Object.values(params.row.validatedtagtriggerlist);

        const numOfValidatedtags = arr.length;

        const arrNew = [];
        for (let i = 0; i < numOfValidatedtags; i++) {
          arrNew.push(params.row.validatedtagtriggerlist[i]?.hashtag);
        }

        return (
          <div>
            <select
              style={{ width: `${70}px` }}
              defaultValue={numOfValidatedtags}
              placeholder={String(numOfValidatedtags)}
              onClick={onClickEdit}
            >
              <option disabled hidden>
                {numOfValidatedtags}
              </option>
              {arrNew.map((value, index) => (
                <option disabled key={index} value={value}>
                  {value}
                </option>
              ))}
            </select>
          </div>
        );
      }
    },
    valueGetter: (params: any) => {
      if (!params.row.validatedtagtriggerlist || params.row.validatedtagtriggerlist === null || params.row.validatedtagtriggerlist.length === 0) {
        return null;
      } else if (params.row.validatedtagtriggerlist.length === 1) {
        return params.row.validatedtagtriggerlist[0]?.hashtag;
      } else {
        const arr = Object.values(params.row.validatedtagtriggerlist);

        const numOfValidatedtags = arr.length;

        let strNew = "";
        for (let i = 0; i < numOfValidatedtags; i++) {
          strNew += ` ${params.row.validatedtagtriggerlist[i]?.hashtag}`;
        }

        return strNew;
      }
    },
    sortComparator: (v1: any, v2: any, sort: any) => {
      if (v1 === null && v2 === null) return 0;
      else if (v1 === null) return 1;
      else if (v2 === null) return -1;
      else return `${v1}`.localeCompare(`${v2}`);
    },
    filterOperators: getGridStringOperators().filter(
      (operator) =>
        operator.value === "contains" ||
        operator.value === "isEmpty" ||
        operator.value === "isNotEmpty",
    ),
  },
  {
    field: "unvalidatedtagtriggerlist",
    headerName: "Unvalidated Tag Trigger List",
    type: "string",
    minWidth: 150,
    flex: 1,
    renderCell: (params: any) => {
      const onClickEdit = (e: any) => {
        e.stopPropagation(); // don't select this row after clicking
      };

      if (!params.row.unvalidatedtagtriggerlist || params.row.unvalidatedtagtriggerlist === null || params.row.unvalidatedtagtriggerlist.length === 0) {
        return null;
      } else if (params.row.unvalidatedtagtriggerlist.length === 1) {
        return params.row.unvalidatedtagtriggerlist[0]?.hashtag;
      } else {
        const arr = Object.values(params.row.unvalidatedtagtriggerlist);

        const numOfUnvalidatedtags = arr.length;

        const arrNew = [];
        for (let i = 0; i < numOfUnvalidatedtags; i++) {
          arrNew.push(params.row.unvalidatedtagtriggerlist[i]?.hashtag);
        }

        return (
          <div>
            <select
              style={{ width: `${70}px` }}
              defaultValue={numOfUnvalidatedtags}
              placeholder={String(numOfUnvalidatedtags)}
              onClick={onClickEdit}
            >
              <option disabled hidden>
                {numOfUnvalidatedtags}
              </option>
              {arrNew.map((value, index) => (
                <option disabled key={index} value={value}>
                  {value}
                </option>
              ))}
            </select>
          </div>
        );
      }
    },
    valueGetter: (params: any) => {
      if (!params.row.unvalidatedtagtriggerlist || params.row.unvalidatedtagtriggerlist === null || params.row.unvalidatedtagtriggerlist.length === 0) {
        return null;
      } else if (params.row.unvalidatedtagtriggerlist.length === 1) {
        return params.row.unvalidatedtagtriggerlist[0]?.hashtag;
      } else {
        const arr = Object.values(params.row.unvalidatedtagtriggerlist);

        const numOfUnvalidatedtags = arr.length;

        let strNew = "";
        for (let i = 0; i < numOfUnvalidatedtags; i++) {
          strNew += ` ${params.row.unvalidatedtagtriggerlist[i]?.hashtag}`;
        }

        return strNew;
      }
    },
    sortComparator: (v1: any, v2: any, sort: any) => {
      if (v1 === null && v2 === null) return 0;
      else if (v1 === null) return 1;
      else if (v2 === null) return -1;
      else return `${v1}`.localeCompare(`${v2}`);
    },
    filterOperators: getGridStringOperators().filter(
      (operator) =>
        operator.value === "contains" ||
        operator.value === "isEmpty" ||
        operator.value === "isNotEmpty",
    ),
  },
];
