import { useSelector } from "react-redux";
import { loadingSelector, LoadingState } from "../../state/slice/appStateSlice";
import { RootState } from "../../state/store";
import { Spinner } from "ui";
import { Success } from "./Success";

export const useLoading = (componentId: string) => {
  const { loading } = useSelector((state: RootState) => {
    return {
      loading: loadingSelector(state, componentId),
    };
  });

  return loading;
};

/**
 * Display a Loader if app if loading and a success message if asyncChunk succeded
 * @param componentId
 * @param children (optionnal): If there if a children, we display it only if appState is Success or Iddle
 */

export const IsLoading = ({
  componentId,
  children,
  showSuccess = true,
  spinnerPlaceholder = false,
  spinnerStyle = {},
}: {
  componentId: string;
  children?: JSX.Element | JSX.Element[];
  showSuccess?: boolean;
  spinnerPlaceholder?: boolean;
  spinnerStyle?: Record<string, any>;
}) => {
  const loading = useLoading(componentId);
  if (loading && loading === LoadingState.Success && showSuccess === true) {
    return <Success />;
  } else if (loading && loading === LoadingState.Loading) {
    return (
      <div className={spinnerPlaceholder ? "flex justify-center items-center w-full h-full" : ""}>
        {loading && loading === loading && (
          <Spinner relative={spinnerPlaceholder} style={spinnerStyle} />
        )}
      </div>
    );
  } else if (children) {
    return <>{children}</>;
  } else {
    return <></>;
  }
};
