import { DataGrid, GridColDef, GridRowsProp } from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { IsLoading } from "../../common/localComponents/AppState/IsLoading";
import ModalConfirmation from "../../common/localComponents/Modal/ModalConfirmation";
import { closeStandardModal, openStandardModal } from "../../common/state/slice/modal/modalSlice";
import { AppDispatch, RootState } from "../../common/state/store";
import {
  getCurrentFullListOfGroups,
  getFullListOfGroups,
} from "../../features/groups/state/groupsSlice";
import { groupsColumns } from "../Groups/groups-columns";
import {
  getCurrentFullListOfUsers,
  getFullListOfUsers,
  updateSpecificUser,
} from "../../features/users/state/usersSlice";
import {
  Dropdown,
  Input,
  ButtonIcon,
  CustomInputStyle,
  DropdownManyChoices,
  DynamicTextarea,
  DataGridComponent,
} from "ui";
import {
  getCurrentGroupsThatAreNotInTheProject,
  getCurrentSpecificProject,
  getGroupsThatAreNotInTheProject,
  getSpecificProject,
  updateSpecificProject,
} from "../../features/projects/state/projectsSlice";
import { poolsColumns } from "../Pools/pools-columns";
import { height } from "@mui/system";

export const GROUPS_COMPONENT_ID = "groupsComponent";
export const USERS_COMPONENT_ID = "usersComponent";
export const POOLS_COMPONENT_ID = "poolsComponent";
export const ZOOMMEETINGS_COMPONENT_ID = "ZoomMeetingsComponent";
export const GROUPS_NOT_IN_PROJECT_COMPONENT_ID = "groupsNotInProjectComponent";
export const USERS_NOT_IN_PROJECT_COMPONENT_ID = "usersNotInProjectComponent";

export const UPDATE_PROJECTS_IN_USER_COMPONENT_ID = "UpdateProjectsInUserComponent";
export const UPDATE_GROUPS_IN_PROJECT_COMPONENT_ID = "UpdateGroupsInProjectComponent";
export const REMOVE_THIS_POOL_FROM_PROJECT_COMPONENT_ID = "removeThisPoolFromTheProjectComponent";
export const REMOVE_THIS_GROUP_FROM_PROJECT_COMPONENT_ID = "removeThisGroupFromTheProjectComponent";
export const PROJECT_UPDATE_COMPONENT_ID = "projectUpdateComponent";
export const CHANGEPASSWORD_COMPONENT_ID = "changePasswordComponent";

type IProjectInfo = {
  name: string;
  description: string;
  owner: any;
  selectedProjects: any[];
  selectedGroups: any[];
};

const SpecificProject = () => {
  const { t } = useTranslation();

  const dispatch: AppDispatch = useDispatch();

  const { specificProject, fullListOfGroups, fullListOfUsers, groupsThatAreNotInTheProject } =
    useSelector((state: RootState) => {
      return {
        specificProject: getCurrentSpecificProject(state),
        fullListOfGroups: getCurrentFullListOfGroups(state),
        fullListOfUsers: getCurrentFullListOfUsers(state),
        groupsThatAreNotInTheProject: getCurrentGroupsThatAreNotInTheProject(state),
      };
    });

  const {
    register,
    control,
    handleSubmit,
    reset,
    watch,
    formState: { errors },
  } = useForm<IProjectInfo>({
    defaultValues: {
      name: specificProject?.name,
      description: specificProject?.description,
      owner: specificProject?.owner?.id,
    },
  });

  const [idOfChosenGroups, setIdOfChosenGroup] = useState<number>();
  const [idOfChosenPools, setIdOfChosenPool] = useState<number>();
  // const [idOfChosenUsers, setIdOfChosenUsers] = useState<number>();

  const [usersOption, setUsersOption] = useState([
    {
      value: " ",
      optionText: "loading...",
    },
  ]);

  const [notEditable, setNotEditable] = useState(true);

  const [groupsColumnVisibilityModel, setGroupsColumnVisibilityModel] = useState();

  const location = useLocation();

  const temp = location.pathname.split("/");
  const idOfProject = temp[2];

  useEffect(() => {
    dispatch(getFullListOfGroups({ componentId: GROUPS_COMPONENT_ID }));
    dispatch(getFullListOfUsers({ componentId: USERS_COMPONENT_ID }));
    dispatch(
      getSpecificProject({
        componentId: ZOOMMEETINGS_COMPONENT_ID,
        idOfProject: idOfProject,
      }),
    ).then((res: any) => {
      console.log(res.payload);
      reset({
        name: res.payload?.name,
        description: res.payload?.description,
        owner: res.payload?.owner?.id,
      });
    });
  }, []);

  useEffect(() => {
    dispatch(
      getGroupsThatAreNotInTheProject({
        componentId: GROUPS_NOT_IN_PROJECT_COMPONENT_ID,
        fullListOfGroups: fullListOfGroups as any,
        specificProject: specificProject,
      }),
    );
  }, [specificProject, fullListOfGroups]);

  useEffect(() => {
    if (fullListOfUsers) {
      setUsersOption(
        fullListOfUsers.map((x: any) => ({
          value: x.id,
          optionText: x.email !== null ? x.email : `ID: ${x.id}`,
        })),
      );
    }
  }, [specificProject, fullListOfUsers]);

  useEffect(() => {
    console.log("usersOption", usersOption);

    reset({
      name: specificProject?.name,
      description: specificProject?.description,
      owner: specificProject?.owner?.id,
    });
  }, [usersOption]);

  const selectedProjects: any = watch().selectedProjects;
  const selectedGroups: any = watch().selectedGroups;

  const actionGroupsColumn = [
    {
      field: "action",
      headerName: "Action",
      width: 200,
      sortable: false,
      filterable: false,
      renderCell: (params: any) => {
        return (
          <>
            <Link to={`/groups/${params.id}`}>
              <button className="btn-primary-fill bg-blue mr-2">{String(t("general.open"))}</button>
            </Link>
            <button
              type="button"
              className="btn-red-outline mr-4"
              onClick={() => {
                dispatch(openStandardModal("removeTheseGroupFromTheProject"));
                setIdOfChosenGroup(params.id);
              }}
            >
              {String(t("general.remove"))}
            </button>
          </>
        );
      },
    },
  ];

  const actionPoolsColumn = [
    {
      field: "action",
      headerName: "Action",
      width: 200,
      sortable: false,
      filterable: false,
      renderCell: (params: any) => {
        return (
          <>
            <Link to={`/pools/${params.id}`}>
              <button className="btn-primary-fill bg-blue mr-2">{String(t("general.open"))}</button>
            </Link>
            <button
              type="button"
              className="btn-red-outline mr-4"
              onClick={() => {
                dispatch(openStandardModal("removeThesePoolFromTheProject"));
                setIdOfChosenPool(params.id);
              }}
            >
              {String(t("general.remove"))}
            </button>
          </>
        );
      },
    },
  ];

  const [pageSize, setPageSize] = useState(10);

  const onSubmitProjectInfo = (data: any) => {
    const updatedProject = {
      name: data.name,
      description: data.description,
      owner: data?.owner?.id,
    };

    dispatch(
      updateSpecificProject({
        componentId: PROJECT_UPDATE_COMPONENT_ID,
        idOfProject: specificProject.id,
        data: updatedProject,
      }),
    )
      .then(() => {
        setNotEditable(true);
      })
      .catch((e: any) => {
        console.error("catch: ", e);
      });
  };

  const cancel = () => {
    reset({
      name: specificProject?.name,
      description: specificProject?.description,
      owner: specificProject?.owner?.id,
    });

    setNotEditable(true);
  };

  const onSubmitProjects = () => {
    let allGroupsThatWillBeInTheUser: any = [];

    if (specificProject.owner.length !== 0) {
      specificProject.owner.forEach((users: any) => {
        allGroupsThatWillBeInTheUser.push(users?.id);
      });
    }

    allGroupsThatWillBeInTheUser = [...allGroupsThatWillBeInTheUser, ...selectedProjects];

    const data = { owner: allGroupsThatWillBeInTheUser };

    dispatch(
      updateSpecificUser({
        componentId: UPDATE_PROJECTS_IN_USER_COMPONENT_ID,
        idOfUser: specificProject.id,
        data: data,
      }),
    );
  };

  const onSubmitGroups = () => {
    let allGroupsThatWillBeInTheProject: any = [];

    if (specificProject.groups.length !== 0) {
      specificProject.groups.forEach((groups: any) => {
        allGroupsThatWillBeInTheProject.push(groups?.id);
      });
    }

    allGroupsThatWillBeInTheProject = [...allGroupsThatWillBeInTheProject, ...selectedGroups];

    const data = { groups: allGroupsThatWillBeInTheProject };

    dispatch(
      updateSpecificProject({
        componentId: UPDATE_GROUPS_IN_PROJECT_COMPONENT_ID,
        idOfProject: specificProject.id,
        data: data,
      }),
    );
  };

  const handleDeleteGroup = (id: number) => {
    const temp = specificProject.groups.filter((item: any) => item.id !== id);

    console.log(temp);

    const arrayOfGroupsId = [];

    for (const [key, value] of Object.entries(temp) as any) {
      arrayOfGroupsId.push(value?.id);
    }

    const data = { groups: arrayOfGroupsId };

    dispatch(
      updateSpecificProject({
        componentId: REMOVE_THIS_GROUP_FROM_PROJECT_COMPONENT_ID,
        idOfProject: idOfProject,
        data: data,
      }),
    ).then(() => {
      dispatch(closeStandardModal("removeTheseGroupFromTheProject"));
    });
  };

  const handleDeletePool = (id: number) => {
    const temp = specificProject.pools.filter((item: any) => item.id !== id);

    const arrayOfPoolsId = [];

    for (const [key, value] of Object.entries(temp) as any) {
      arrayOfPoolsId.push(value.id);
    }

    const data = { pools: arrayOfPoolsId };

    dispatch(
      updateSpecificProject({
        componentId: REMOVE_THIS_POOL_FROM_PROJECT_COMPONENT_ID,
        idOfProject: idOfProject,
        data: data,
      }),
    ).then(() => {
      dispatch(closeStandardModal("removeThesePoolFromTheProject"));
    });
  };

  const openInNewTab = (url: string) => {
    window.open(url, "_blank", "noopener,noreferrer");
  };

  return (
    <>
      <ModalConfirmation
        id="removeThesePoolFromTheProject"
        onClick={() => {
          if (idOfChosenPools !== undefined) {
            handleDeletePool(idOfChosenPools);
          }
        }}
        title={t("pages.projects.specific.topicOfDeletePoolPopup")}
        text={t("pages.projects.specific.textOfDeletePoolPopup")}
        componentId={REMOVE_THIS_POOL_FROM_PROJECT_COMPONENT_ID}
      />
      <ModalConfirmation
        id="removeTheseGroupFromTheProject"
        onClick={() => {
          if (idOfChosenGroups !== undefined) {
            handleDeleteGroup(idOfChosenGroups);
          }
        }}
        title={t("pages.projects.specific.topicOfDeleteGroupsPopup")}
        text={t("pages.projects.specific.textOfDeleteGroupsPopup")}
        componentId={REMOVE_THIS_GROUP_FROM_PROJECT_COMPONENT_ID}
      />

      <header className="flex w-full bg-white shadow  sticky top-0 z-10">
        <div className="max-w-screen-2xl py-6 px-8 overflow-y-auto">
          <h1 className="text-3xl font-bold text-black">{`${t(
            "pages.projects.specific.pageTitle",
          )}${specificProject?.id ? specificProject?.id : ""}`}</h1>
        </div>
        <div className="flex justify-end align-middle ml-auto my-auto mr-8">
          <ButtonIcon
            icon={"ArrowPathIcon"}
            classNameBtn={"w-14 h-14 bg-white rounded-full shadow-md border"}
            classNameIcon={"w-10 h-10"}
            onClick={() => {
              dispatch(getFullListOfGroups({ componentId: GROUPS_COMPONENT_ID }));

              dispatch(
                getSpecificProject({
                  componentId: ZOOMMEETINGS_COMPONENT_ID,
                  idOfProject: idOfProject,
                }),
              ).then((res: any) => {
                console.log(res.payload);
                reset({
                  name: res.payload?.name,
                  description: res.payload?.description,
                  owner: res.payload?.owner?.id,
                });
              });
            }}
          />
        </div>
      </header>
      <IsLoading componentId={GROUPS_COMPONENT_ID} showSuccess={false}>
        <IsLoading componentId={USERS_COMPONENT_ID} showSuccess={false}>
          <IsLoading componentId={ZOOMMEETINGS_COMPONENT_ID} showSuccess={false}>
            <IsLoading componentId={GROUPS_NOT_IN_PROJECT_COMPONENT_ID} showSuccess={false}>
              <IsLoading componentId={USERS_NOT_IN_PROJECT_COMPONENT_ID} showSuccess={false}>
                <div className="w-full h-full pb-6 max-w-9xl mx-auto pt-6 px-6">
                  <form onSubmit={handleSubmit(onSubmitProjectInfo)}>
                    <div className="p-5 h-auto min-h-[200px] w-full bg-white rounded-xl shadow-md">
                      <div className="w-full h-full max-w-9xl">
                        <div className="relative grow w-full">
                          <div className="border-gray-300">
                            <div className="w-full flex flex-row mb-2">
                              <h2 className="pl-2 py-2 text-xl font-bold">
                                {t("pages.projects.specific.info")}
                              </h2>
                              <div className="flex grow justify-end h-[38px] my-auto">
                                {notEditable && (
                                  <button
                                    className="btn-primary-fill"
                                    type="button"
                                    onClick={() => {
                                      setNotEditable(false);
                                    }}
                                  >
                                    {t("general.edit")}
                                  </button>
                                )}
                              </div>
                            </div>
                            <div className="grid grid-cols-1 xl:grid-cols-2">
                              <div className="xl:order-1 xl:mr-2">
                                <CustomInputStyle title={t("pages.projects.specific.name")}>
                                  <Input
                                    type="text"
                                    readOnly={notEditable}
                                    registerFct={() => register("name")}
                                    className="read-only:cursor-default font-medium read-only:bg-white read-only:border-white read-only:shadow-none read-only:focus:ring-0 text-gray-600 text-center sm:text-left ${ ? }"
                                    placeholder={t("pages.projects.specific.namePlaceholder")}
                                  />
                                </CustomInputStyle>
                              </div>
                              <div className="xl:order-3 xl:mr-2">
                                <CustomInputStyle title={t("pages.projects.specific.description")}>
                                  <DynamicTextarea
                                    readOnly={notEditable}
                                    registerFct={() => register("description")}
                                    // style={{ resize: notEditable ? "none" : "vertical" }}
                                    style={{ resize: "none", height: notEditable ? "auto" : "" }}
                                    className=" min-h-[38px] read-only:cursor-default w-full font-medium read-only:bg-white read-only:border-white read-only:shadow-none read-only:focus:ring-0 text-gray-600 text-center sm:text-left ${ ? }"
                                    placeholder={t(
                                      "pages.projects.specific.descriptionPlaceholder",
                                    )}
                                  />
                                </CustomInputStyle>
                              </div>
                              <div className="xl:order-5 xl:mr-2">
                                <CustomInputStyle title={t("pages.projects.specific.owner")}>
                                  <div className="w-full">
                                    {usersOption && (
                                      <Dropdown
                                        options={usersOption as any[]}
                                        disabled={notEditable}
                                        className={
                                          "font-medium disabled:hide-arrow-select disabled:bg-white disabled:border-white disabled:shadow-none disabled:focus:ring-0 disabled:text-gray-900 text-gray-600  text-center sm:text-left ${ ? }"
                                        }
                                        style={{
                                          border: errors.owner ? "1.5px solid rgb(239 68 68)" : "",
                                        }}
                                        registerFct={() => register("owner")}
                                        asDefaultValue={true}
                                      />
                                    )}
                                  </div>
                                </CustomInputStyle>
                              </div>
                              <div className="xl:order-2 xl:ml-2">
                                <CustomInputStyle title="Googledirouid">
                                  <button
                                    className="btn-primary-fill h-[38px] ml-3 mt-1 sm:mr-auto md:my-auto"
                                    onClick={() =>
                                      openInNewTab(
                                        `https://drive.google.com/drive/folders/${specificProject.googledirouid}?usp=sharing`,
                                      )
                                    }
                                  >
                                    {String(t("general.open"))}
                                  </button>
                                </CustomInputStyle>
                              </div>
                              <div className="xl:order-4 xl:ml-2">
                                <CustomInputStyle title="Googledirouidtracks">
                                  <button
                                    className="btn-primary-fill h-[38px] ml-3 mt-1 sm:mr-auto md:my-auto"
                                    onClick={() =>
                                      openInNewTab(
                                        `https://drive.google.com/drive/folders/${specificProject.googledirouidtracks}?usp=sharing`,
                                      )
                                    }
                                  >
                                    {String(t("general.open"))}
                                  </button>
                                </CustomInputStyle>
                              </div>
                              <div className="xl:order-6 xl:ml-2">
                                <CustomInputStyle title="Googledirouidshared">
                                  <button
                                    className="btn-primary-fill h-[38px] ml-3 mt-1 sm:mr-auto md:my-auto"
                                    onClick={() =>
                                      openInNewTab(
                                        `https://drive.google.com/drive/folders/${specificProject.googledirouidshared}?usp=sharing`,
                                      )
                                    }
                                  >
                                    {String(t("general.open"))}
                                  </button>
                                </CustomInputStyle>
                              </div>
                            </div>
                            <div className="flex justify-end h-[38px]">
                              <IsLoading
                                componentId={PROJECT_UPDATE_COMPONENT_ID}
                                showSuccess={false}
                              >
                                <>
                                  {notEditable === false && (
                                    <>
                                      <button
                                        type="button"
                                        className="btn-primary-outline mr-4"
                                        onClick={() => cancel()}
                                      >
                                        {t("general.cancel")}
                                      </button>
                                      <button className="btn-primary-fill" type="submit">
                                        {t("general.save")}
                                      </button>
                                    </>
                                  )}
                                </>
                              </IsLoading>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                  <div className="grid grid-cols-1 xl:grid-cols-2 gap-4 md:pb-4 mb-16">
                    <h2 className="xl:order-1 text-2xl font-medium mt-10">
                      {t("pages.projects.specific.addGroupsToTheProject")}
                    </h2>
                    <form
                      className="xl:order-3 flex border-[1.5px] rounded-xl border-gray-300 md:flex-row flex-col justify-evenly place-content-center"
                      onSubmit={handleSubmit(onSubmitGroups)}
                    >
                      <div className="pt-4 pb-8 m-auto">
                        <div className="w-auto md:w-[320px] lg:w-[420px]">
                          <DropdownManyChoices
                            label="Groups:"
                            name="selectedGroups"
                            control={control}
                            options={groupsThatAreNotInTheProject as any[]}
                            style={{
                              border: errors.selectedGroups ? "1.5px solid rgb(239 68 68)" : "",
                            }}
                          />
                        </div>
                      </div>
                      <div className="max-w-xs m-auto md:pt-7 pb-4">
                        <button className="btn-primary-fill" type="submit">
                          {t("general.add")}
                        </button>
                      </div>
                    </form>
                    {specificProject?.groups && specificProject?.groups?.length !== 0 ? (
                      <div className="xl:order-5 p-5 h-full min-h-[200px] w-full bg-white rounded-xl shadow-md">
                        <div className="flex flex-col w-full h-full max-w-9xl">
                          <h2 className="pb-2 text-xl font-bold">
                            {t("pages.projects.specific.groups")}
                          </h2>
                          <DataGridComponent
                            autoHeight={true}
                            className="bg-white rounded-xl h-full"
                            rows={specificProject.groups as GridRowsProp}
                            columns={
                              groupsColumns.concat(actionGroupsColumn as any) as GridColDef[]
                            }
                            rowsPerPageOptions={[5, 10, 20]}
                            columnVisibilityModel={groupsColumnVisibilityModel}
                          />
                        </div>
                      </div>
                    ) : (
                      <IsLoading
                        componentId={UPDATE_GROUPS_IN_PROJECT_COMPONENT_ID}
                        showSuccess={false}
                      >
                        <div className="xl:order-5 p-5 h-auto min-h-[200px] w-full bg-white rounded-xl shadow-md">
                          <div className="relative flex flex-col w-full h-full max-w-9xl">
                            <h2 className="pb-2 text-xl font-bold">
                              {t("pages.projects.specific.groups")}
                            </h2>
                            <div className="mx-auto my-auto">
                              {t("pages.projects.specific.emptyGroupsTable")}
                            </div>
                          </div>
                        </div>
                      </IsLoading>
                    )}
                    <h2 className="xl:order-2 text-2xl font-medium mt-10">
                      {t("pages.projects.specific.poolsTitle")}
                    </h2>
                    {specificProject?.pools && specificProject?.pools?.length !== 0 ? (
                      <div className="xl:order-4 xl:row-span-2 p-5 h-full min-h-[200px] w-full bg-white rounded-xl shadow-md">
                        <div className="flex flex-col w-full h-full max-w-9xl">
                          <DataGridComponent
                            autoHeight={true}
                            className="bg-white rounded-xl h-full"
                            rows={specificProject.pools as GridRowsProp}
                            columns={poolsColumns.concat(actionPoolsColumn as any) as GridColDef[]}
                            rowsPerPageOptions={[5, 10, 20]}
                            columnVisibilityModel={groupsColumnVisibilityModel}
                          />
                        </div>
                      </div>
                    ) : (
                      <IsLoading componentId={POOLS_COMPONENT_ID} showSuccess={false}>
                        <div className="xl:order-4 xl:row-span-2 p-5 h-auto min-h-[200px] w-full bg-white rounded-xl shadow-md">
                          <div className="relative flex flex-col w-full h-full max-w-9xl">
                            <h2 className="pb-2 text-xl font-bold">
                              {t("pages.projects.specific.pools")}
                            </h2>

                            <div className="mx-auto my-auto">
                              {t("pages.projects.specific.emptyPoolsTable")}
                            </div>
                          </div>
                        </div>
                      </IsLoading>
                    )}
                  </div>
                </div>
              </IsLoading>
            </IsLoading>
          </IsLoading>
        </IsLoading>
      </IsLoading>
    </>
  );
};

export default SpecificProject;
