import { getGridStringOperators } from "@mui/x-data-grid";
import { Link } from "react-router-dom";

export const poolsColumns = [
  { field: "id", headerName: "ID", width: 95 },
  {
    field: "track.name",
    headerName: "Track name",
    minWidth: 200,
    flex: 1,
    renderCell: (params: any) => {
      if (!params.row.track || params.row.track === null) {
        return <Link to={`/pools/${params.id}`}></Link>;
      } else {
        return (
          <Link to={`/pools/${params.id}`}>
            <p>{params.row.track.name}</p>
          </Link>
        );
      }
    },
    valueGetter: (params: any) => {
      if (!params.row.track || params.row.track === null) {
        return null;
      } else {
        return params.row.track.name;
      }
    },
  },
  {
    field: "status.Name",
    headerName: "Status",
    minWidth: 100,
    flex: 1,
    renderCell: (params: any) => {
      if (!params.row.status || params.row.status === null) {
        return null;
      } else {
        return params.row.status.Name;
      }
    },
    valueGetter: (params: any) => {
      if (!params.row.status || params.row.status === null) {
        return null;
      } else {
        return params.row.status.Name;
      }
    },
  },
  {
    field: "project",
    headerName: "Project",
    type: "string",
    minWidth: 200,
    flex: 1,
    renderCell: (params: any) => {
      const onClickEdit = (e: any) => {
        e.stopPropagation(); // don't select this row after clicking
      };

      if (!params.row.project || params.row.project === null) {
        return null;
      } else {
        return params.row.project?.name;
      }
    },
    valueGetter: (params: any) => {
      if (!params.row.project || params.row.project === null) {
        return null;
      } else {
        return params.row.project?.name;
      }
    },
  },
  {
    field: "user.email",
    headerName: "User",
    minWidth: 200,
    flex: 1,
    renderCell: (params: any) => {
      if (!params.row.user || params.row.user === null) {
        return null;
      } else {
        return params.row.user.email;
      }
    },
    valueGetter: (params: any) => {
      if (!params.row.user || params.row.user === null) {
        return null;
      } else {
        return params.row.user.email;
      }
    },
  },
];
