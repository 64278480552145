import { getGridStringOperators } from "@mui/x-data-grid";
import { Link } from "react-router-dom";

export const projectsColumns = [
  { field: "id", headerName: "ID", width: 95 },
  {
    field: "name",
    headerName: "Project's Name",
    minWidth: 300,
    flex: 1,
    renderCell: (params: any) => {
      const onClickEdit = (e: any) => {
        e.stopPropagation(); // don't select this row after clicking
      };
      return (
        <Link to={`/projects/${params.id}`}>
          <p>{params.row.name}</p>
        </Link>
      );
    },
    valueGetter: (params: any) => {
      return params.row.name;
    },
  },
  {
    field: "groups",
    headerName: "Groups",
    type: "string",
    minWidth: 200,
    flex: 1,
    renderCell: (params: any) => {
      const onClickEdit = (e: any) => {
        e.stopPropagation(); // don't select this row after clicking
      };

      if (!params.row.groups || params.row.groups === null || params.row.groups.length === 0) {
        return null;
      } else if (params.row.groups.length === 1) {
        return params.row.groups[0]?.name;
      } else {
        const arr = Object.values(params.row.groups);

        const numOfGroups = arr.length;

        const arrNew = [];
        for (let i = 0; i < numOfGroups; i++) {
          arrNew.push(params.row.groups[i]?.name);
        }

        return (
          <div>
            <select
              style={{ width: `${70}px` }}
              defaultValue={numOfGroups}
              placeholder={String(numOfGroups)}
              onClick={onClickEdit}
            >
              <option disabled hidden>
                {numOfGroups}
              </option>
              {arrNew.map((value, index) => (
                <option disabled key={index} value={value}>
                  {value}
                </option>
              ))}
            </select>
          </div>
        );
      }
    },
    valueGetter: (params: any) => {
      if (!params.row.groups || params.row.groups === null || params.row.groups.length === 0) {
        return null;
      } else if (params.row.groups.length === 1) {
        return params.row.groups[0]?.name;
      } else {
        const arr = Object.values(params.row.groups);

        const numOfGroups = arr.length;

        let strNew = "";
        for (let i = 0; i < numOfGroups; i++) {
          strNew += ` ${params.row.groups[i]?.name}`;
        }

        return strNew;
      }
    },
    sortComparator: (v1: any, v2: any, sort: any) => {
      if (v1 === null && v2 === null) return 0;
      else if (v1 === null) return 1;
      else if (v2 === null) return -1;
      else return `${v1}`.localeCompare(`${v2}`);
    },
    filterOperators: getGridStringOperators().filter(
      (operator) =>
        operator.value === "contains" ||
        operator.value === "isEmpty" ||
        operator.value === "isNotEmpty",
    ),
  },
];
