import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import apiUrls from "../../../api";
import { IAppLoaderAction } from "../../../common/state/loaderHandleMiddleware";
import { RootState } from "../../../common/state/store";

export interface IGroups {
  fullListOfGroups?: any[];
  smListOfGroups?: any[];
  specificGroup: any;
  usersThatAreNotInTheGroup: any[];
}

export const getSmListOfGroups = createAsyncThunk(
  "groups/getSmListOfGroups",
  async (componentId: IAppLoaderAction) => {
    let response;

    await axios
      .get(`${apiUrls.groups.get}?_sort=id:DESC&_limit=100`, {})
      .then((res) => {
        response = res.data;
      })
      .catch((e) => {
        console.error(e);
        return e;
      });

    return response;
  },
);

export const getFullListOfGroups = createAsyncThunk(
  "groups/getFullListOfGroups",
  async (componentId: IAppLoaderAction) => {
    let response;
    await axios
      .get(`${apiUrls.groups.get}?_sort=id:DESC&_limit=-1`)
      .then((res) => {
        response = res.data;
      })
      .catch((e) => {
        console.error(e);
        return e;
      });

    return response;
  },
);

export const getSpecificGroup = createAsyncThunk(
  "groups/getSpecificGroup",
  async ({ idOfGroup }: { idOfGroup: string } & IAppLoaderAction) => {
    let response;

    await axios
      .get(`${apiUrls.groups.get}/${idOfGroup}`)
      .then((res) => {
        response = res.data;
      })
      .catch((e) => {
        console.error(e);
      });

    return response;
  },
);

export const getUsersThatAreNotInTheGroup = createAsyncThunk(
  "groups/getUsersThatAreNotInTheGroup",
  async ({
    specificGroup,
    fullListOfUsers,
  }: { specificGroup: any; fullListOfUsers: any[] } & IAppLoaderAction) => {
    if (specificGroup?.users?.length !== 0) {
      const arrOfExistedUsers = [];

      for (let i = 0; i < specificGroup.users.length; i++) {
        arrOfExistedUsers.push(specificGroup.users[i].id);
      }

      let objWithUsersNotExisted: any = fullListOfUsers;

      arrOfExistedUsers.forEach((id) => {
        if (objWithUsersNotExisted != undefined) {
          objWithUsersNotExisted = objWithUsersNotExisted.filter(
            (element: any) => element.id !== id,
          );
        }
      });

      const optionsNeeded = objWithUsersNotExisted.map((x: any) => ({
        value: x.id,
        label: x.email !== null ? x.email : `ID: ${x.id}`,
      }));

      return optionsNeeded;
    } else {
      const optionsNeeded = fullListOfUsers.map((x: any) => ({
        value: x.id,
        label: x.email !== null ? x.email : `ID: ${x.id}`,
      }));

      return optionsNeeded as any;
    }
  },
);

export const updateUsersInTheGroup = createAsyncThunk(
  "groups/updateUsersInTheGroup",
  async ({
    idOfGroup,
    arrayOfUsersId,
  }: { idOfGroup: string; arrayOfUsersId: any } & IAppLoaderAction) => {
    let response;
    await axios
      .put(`${apiUrls.groups.get}/${idOfGroup}`, { users: arrayOfUsersId })
      .then((res) => {
        response = res.data;
      })
      .catch((e) => console.error(e));

    return response;
  },
);

const initialState: IGroups = {
  fullListOfGroups: [],
  smListOfGroups: [],
  specificGroup: {},
  usersThatAreNotInTheGroup: [],
};

export const groupsSlice = createSlice({
  name: "groups",
  initialState,
  reducers: {
    clearAllGroups: (state: IGroups) => {
      state.fullListOfGroups = [];
    },
    updateFullListOfGroups: (state: IGroups, { payload }) => {
      if (state.fullListOfGroups?.findIndex((x) => x.id == payload.id) !== undefined) {
        state.fullListOfGroups[
          state.fullListOfGroups?.findIndex((x) => x.id == payload.id) as any
        ] = payload;
      }
    },
    updateSmListOfGroups: (state: IGroups, { payload }) => {
      if (state.smListOfGroups?.findIndex((x) => x.id == payload.id) !== undefined) {
        state.smListOfGroups[state.smListOfGroups?.findIndex((x) => x.id == payload.id) as any] =
          payload;
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getSmListOfGroups.fulfilled, (state: IGroups, { payload }) => {
      state.smListOfGroups = payload;
    });
    builder.addCase(getFullListOfGroups.fulfilled, (state: IGroups, { payload }) => {
      state.fullListOfGroups = payload;
    });
    builder.addCase(getSpecificGroup.fulfilled, (state: IGroups, { payload }: any) => {
      state.specificGroup = payload;
    });
    builder.addCase(getUsersThatAreNotInTheGroup.fulfilled, (state: IGroups, { payload }: any) => {
      state.usersThatAreNotInTheGroup = payload;
    });
    builder.addCase(updateUsersInTheGroup.fulfilled, (state: IGroups, action) => {
      state.specificGroup = action.payload;
      groupsSlice.caseReducers.updateFullListOfGroups(state, action);
      groupsSlice.caseReducers.updateSmListOfGroups(state, action);
    });
  },
});

export const groupsReducer = groupsSlice.reducer;

export const getCurrentFullListOfGroups = (state: RootState) => state.groups.fullListOfGroups;
export const getCurrentSmListOfGroups = (state: RootState) => state.groups.smListOfGroups;
export const getCurrentSpecificGroup = (state: RootState) => state.groups.specificGroup;
export const getCurrentUsersThatAreNotInTheGroup = (state: RootState) =>
  state.groups.usersThatAreNotInTheGroup;

export const { clearAllGroups } = groupsSlice.actions;
