export const baseUrl = process.env.REACT_APP_API_ENDPOINT;
/* We removed the ternary to resolve deployment issues.
You now need to pass the api endpoint manually as an environment variable.

Feel free to copy/paste the line below in your terminal :

REACT_APP_API_ENDPOINT="https://api-dev/myctofriend.co" npm run start

REACT_APP_API_ENDPOINT="https://api.myctofriend.co" npm run start
*/
const apiUrls = {
  base: baseUrl,
  auth: {
    signIn: `${baseUrl}/api/auth/local`,
  },
  zoomMeetings: {
    get: `${baseUrl}/api/meetings`,
  },
  groups: {
    get: `${baseUrl}/api/groups`,
  },
  questions: {
    get: `${baseUrl}/api/questions`,
    createEverythingAtOnce: `${baseUrl}/api/questions/createEverythingAtOnce`,
    createLotsOfQuestionAtOnce: `${baseUrl}/api/questions/createLotsOfQuestionAtOnce`,
    generateAiQuestions: `${baseUrl}/api/questions/generateAiQuestions`,
    generateAiAnswers: `${baseUrl}/api/questions/generateAiAnswers`,
    deleteQNA: `${baseUrl}/api/questions/deleteQNA`,
  },
  tags: {
    get: `${baseUrl}/api/tags`,
  },
  users: {
    get: `${baseUrl}/api/users`,
  },
  projects: {
    get: `${baseUrl}/api/projects`,
  },
  pools: {
    get: `${baseUrl}/api/pools`,
    findAll: `${baseUrl}/api/pools/findAll`,
  },
  tracks: {
    get: `${baseUrl}/api/tracks`,
  },
  profilings: {
    get: `${baseUrl}/api/profilings`,
    deactivateForUser: `${baseUrl}/api/profilings/deactivateForUser`,
  },
};

export default apiUrls;
