import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import apiUrls from "../../../api";
import { IAppLoaderAction } from "../../../common/state/loaderHandleMiddleware";
import { RootState } from "../../../common/state/store";

export interface IUsers {
  fullListOfUsers?: any[];
  smListOfUsers?: any[];
  specificUser: any;
  projectsThatAreNotInTheUser: any[];
  groupsThatAreNotInTheUser: any[];
}

export const getSmListOfUsers = createAsyncThunk(
  "users/getSmListOfUsers",
  async (componentId: IAppLoaderAction) => {
    let response;

    await axios
      .get(`${apiUrls.users.get}?_sort=id:DESC&_limit=100`, {})
      .then((res) => {
        response = res.data;
      })
      .catch((e) => {
        console.error(e);
        return e;
      });

    return response;
  },
);

export const getFullListOfUsers = createAsyncThunk(
  "users/getFullListOfUsers",
  async (componentId: IAppLoaderAction) => {
    let response;
    await axios
      .get(`${apiUrls.users.get}?_sort=id:DESC&_limit=-1`)
      .then((res) => {
        response = res.data;
      })
      .catch((e) => {
        console.error(e);
        return e;
      });

    return response;
  },
);

export const getSpecificUser = createAsyncThunk(
  "users/getSpecificUser",
  async ({ idOfUser }: { idOfUser: string } & IAppLoaderAction) => {
    let response;
    await axios
      .get(`${apiUrls.users.get}/${idOfUser}`)
      .then((res) => {
        response = res.data;
      })
      .catch((e) => {
        console.error(e);
      });

    return response;
  },
);

export const updateSpecificUser = createAsyncThunk(
  "users/updateSpecificUser",
  async ({ idOfUser, data }: { idOfUser: string; data: any } & IAppLoaderAction) => {
    let response;
    console.log(data);
    await axios
      .put(`${apiUrls.users.get}/${idOfUser}`, { ...data })
      .then((res) => {
        console.log("data", data);
        console.log("res", res);
        console.log("res.data", res.data);
        response = res.data;
      })
      .catch((e) => console.error(e));

    return response;
  },
);

export const getGroupsThatAreNotInTheUser = createAsyncThunk(
  "users/getGroupsThatAreNotInTheUser",
  async ({
    specificUser,
    fullListOfGroups,
  }: { specificUser: any; fullListOfGroups: any[] } & IAppLoaderAction) => {
    console.log("specificUser", specificUser);
    console.log("fullListOfGroups", fullListOfGroups);

    if (specificUser?.groups?.length !== 0) {
      const arrOfExistedGroups = [];

      for (let i = 0; i < specificUser.groups.length; i++) {
        arrOfExistedGroups.push(specificUser.groups[i].id);
      }

      let arrWithGroupsNotExisted: any = fullListOfGroups;

      console.log("arrWithGroupsNotExisted", arrWithGroupsNotExisted);
      console.log("fullListOfGroups", fullListOfGroups);

      arrOfExistedGroups.forEach((id) => {
        if (arrWithGroupsNotExisted != undefined) {
          arrWithGroupsNotExisted = arrWithGroupsNotExisted.filter(
            (element: any) => element.id !== id,
          );
        }
      });

      const optionsNeeded = arrWithGroupsNotExisted?.map((x: any) => ({
        value: x.id,
        label: x.name !== null ? x.name : `ID: ${x.id}`,
      }));

      return optionsNeeded;
    } else {
      const optionsNeeded = fullListOfGroups.map((x: any) => ({
        value: x.id,
        label: x.name !== null ? x.name : `ID: ${x.id}`,
      }));

      return optionsNeeded as any;
    }
  },
);

export const getProjectsThatAreNotInTheUser = createAsyncThunk(
  "users/getProjectsThatAreNotInTheUser",
  async ({
    specificUser,
    fullListOfProjects,
  }: { specificUser: any; fullListOfProjects: any[] } & IAppLoaderAction) => {
    console.log("specificUser", specificUser);
    console.log("fullListOfProjects", fullListOfProjects);

    if (specificUser?.projectsowner?.length !== 0) {
      const arrOfExistedProjects = [];

      for (let i = 0; i < specificUser.projectsowner.length; i++) {
        arrOfExistedProjects.push(specificUser.projectsowner[i].id);
      }

      let arrWithProjectsNotExisted: any = fullListOfProjects;

      arrOfExistedProjects.forEach((id) => {
        if (arrWithProjectsNotExisted != undefined) {
          arrWithProjectsNotExisted = arrWithProjectsNotExisted.filter(
            (element: any) => element.id !== id,
          );
        }
      });

      const optionsNeeded = arrWithProjectsNotExisted.map((x: any) => ({
        value: x.id,
        label: x.name !== null ? x.name : `ID: ${x.id}`,
      }));

      return optionsNeeded;
    } else {
      const optionsNeeded = fullListOfProjects.map((x: any) => ({
        value: x.id,
        label: x.name !== null ? x.name : `ID: ${x.id}`,
      }));

      return optionsNeeded as any;
    }
  },
);

const initialState: IUsers = {
  fullListOfUsers: [],
  smListOfUsers: [],
  specificUser: {},
  projectsThatAreNotInTheUser: [],
  groupsThatAreNotInTheUser: [],
};

export const usersSlice = createSlice({
  name: "users",
  initialState,
  reducers: {
    clearAllUsers: (state: IUsers) => {
      state.fullListOfUsers = [];
    },
    updateFullListOfUsers: (state: IUsers, { payload }) => {
      if (state.fullListOfUsers?.findIndex((x) => x.id == payload.id) !== undefined) {
        state.fullListOfUsers[state.fullListOfUsers?.findIndex((x) => x.id == payload.id) as any] =
          payload;
      }
    },
    updateSmListOfUsers: (state: IUsers, { payload }) => {
      if (state.smListOfUsers?.findIndex((x) => x.id == payload.id) !== undefined) {
        state.smListOfUsers[state.smListOfUsers?.findIndex((x) => x.id == payload.id) as any] =
          payload;
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getSmListOfUsers.fulfilled, (state: IUsers, { payload }) => {
      state.smListOfUsers = payload;
    });
    builder.addCase(getFullListOfUsers.fulfilled, (state: IUsers, { payload }) => {
      state.fullListOfUsers = payload;
    });
    builder.addCase(getSpecificUser.fulfilled, (state: IUsers, { payload }) => {
      state.specificUser = payload;
    });
    builder.addCase(updateSpecificUser.fulfilled, (state: IUsers, action) => {
      state.specificUser = action.payload;
      usersSlice.caseReducers.updateFullListOfUsers(state, action);
      usersSlice.caseReducers.updateSmListOfUsers(state, action);
    });
    builder.addCase(getGroupsThatAreNotInTheUser.fulfilled, (state: IUsers, { payload }: any) => {
      state.groupsThatAreNotInTheUser = payload;
    });
    builder.addCase(getProjectsThatAreNotInTheUser.fulfilled, (state: IUsers, { payload }: any) => {
      state.projectsThatAreNotInTheUser = payload;
    });
  },
});

export const usersReducer = usersSlice.reducer;

export const getCurrentFullListOfUsers = (state: RootState) => state.users.fullListOfUsers;
export const getCurrentSmListOfUsers = (state: RootState) => state.users.smListOfUsers;
export const getCurrentSpecificUser = (state: RootState) => state.users.specificUser;
export const getCurrentProjectsThatAreNotInTheUser = (state: RootState) =>
  state.users.projectsThatAreNotInTheUser;
export const getCurrentGroupsThatAreNotInTheUser = (state: RootState) =>
  state.users.groupsThatAreNotInTheUser;

export const { clearAllUsers } = usersSlice.actions;
